<template>
  <div
    v-if="showFile"
    :class="`modal ${
      !showFile && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="closeImport()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 mx-auto bg-white rounded shadow-lg modal-container max-h-128 max-w-8xl"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Tableau de cutoff à importer</p>
          <div class="z-50 cursor-pointer modal-close" @click="closeImport()">
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div
          class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
          v-show="showFileContent"
        >
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Nom du client
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Transporteur
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    CutOff 1
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    CutOff 2
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white">
                <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
                <tr v-for="(readCondi, index) in readFile" :key="index">
                  <td
                    class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                  >
                    {{ readCondi[0] }}
                  </td>

                  <td
                    class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                  >
                    {{ readCondi[1] }}
                  </td>

                  <td
                    class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                  >
                    {{ readCondi[2] }}
                  </td>

                  <td
                    class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                  >
                    {{ readCondi[3] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="flex justify-end mt-4 mb-2" v-show="showFileContent">
          <button
            class="p-2 bg-blue-500 text-white rounded"
            @click="importExcel()"
          >
            Importer
          </button>
        </div>
        <div v-show="importLoading">
          <div class="animate-pulse">Importation en cours...</div>
        </div>
        <div v-show="showFinished">
          <div>Importation réussie !</div>
          <div class="flex justify-end">
            <button
              class="p-2 bg-blue-500 text-white rounded"
              @click="closeImport()"
            >
              Fermer
            </button>
          </div>
        </div>

        <!-- End Body -->
      </div>
    </div>
  </div>
  <div>
    <!-- BODY -->
    <div id="body" class="bg-white p-2 rounded-md">
      <!-- <div class="mt-1 flex justify-center">
        <menuClient />
      </div> -->
      <div class="flex justify-between mt-8">
        <div>
          <searchInput
            v-if="tabState == 'actif' && cdtnClient == false"
            placeholder="Rechercher dans clients professionnels"
            @searchWord="search(1, 10, true, false, $event)"
            @catchFalse="searchCatchError = $event"
            :searchStarted="searchInProgress"
            :catchError="searchCatchError"
          />
          <searchInput
            v-if="tabState == 'archived' && cdtnClient == false"
            placeholder="Rechercher dans clients professionnels archivés"
            @searchWord="search(1, 10, true, true, $event)"
            @catchFalse="searchCatchError = $event"
            :searchStarted="searchInProgress"
            :catchError="searchCatchError"
          />
          <searchInput
            v-if="tabState == 'actif' && cdtnClient == true"
            placeholder="Rechercher dans clients particuliers"
            @searchWord="search(1, 10, false, false, $event)"
            @catchFalse="searchCatchError = $event"
            :searchStarted="searchInProgress"
            :catchError="searchCatchError"
          />
          <searchInput
            v-if="tabState == 'archived' && cdtnClient == true"
            placeholder="Rechercher dans clients particuliers archivés"
            @searchWord="search(1, 10, false, true, $event)"
            @catchFalse="searchCatchError = $event"
            :searchStarted="searchInProgress"
            :catchError="searchCatchError"
          />
        </div>
        <!-- <h2 class="my-auto font-medium text-4xl">Clients</h2> -->
        <titrePages :title="'Clients'" />
        <div class="flex">
          <!-- AJOUTER LES CUTOFF PAR EXCEL -->
          <!-- <label for="dropzone-file">
            <span class="material-icons-round text-blue-500 cursor-pointer">
              upload_file
            </span>
            <input
              id="dropzone-file"
              type="file"
              class="hidden"
              accept=".xlsx"
              @change="readExcel()"
            />
            <p></p>
          </label> -->
          <div>
            <button
              v-if="!loadingExport"
              class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
              @click="exportExcel()"
            >
              <span class="material-icons-outlined text-xl mr-2">
                download
              </span>
              <span>Exporter en Excel</span>
            </button>
            <button
              v-else
              class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
            >
              <PulseLoader color="#ffff" class="mr-2" />
              <span>Export en cours </span>
            </button>
          </div>

          <router-link to="/creerClient">
            <div>
              <button
                class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
              >
                <span class="material-icons-outlined text-xl mr-2">
                  add_circle
                </span>
                <span>Ajouter un client</span>
              </button>
            </div>
          </router-link>

          <router-link to="/favoriClient">
            <div>
              <button
                class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
              >
                <span class="material-icons-outlined text-xl mr-2"> star </span>
                <span>Gérer les favoris</span>
              </button>
            </div>
          </router-link>
        </div>
      </div>

      <div class="flex flex-col mt-4">
        <div class="mt-4 flex justify-between space-x-2">
          <div class="flex flex-row items-center space-x-2">
            <button
              class="w-24 bg-blue-300 border border-cyan-600 p-1 text-white text-sm rounded shadow"
              v-if="cdtnClient == true"
              @click="getClients(1, 10)"
            >
              Professionnel
            </button>
            <button
              v-else
              class="w-24 bg-cyan-600 p-1 text-white text-sm rounded shadow"
              @click="getClients(1, 10)"
            >
              Professionnel
            </button>
            <button
              class="w-24 bg-blue-300 border border-cyan-600 p-1 text-white text-sm rounded shadow"
              v-if="cdtnClient == false"
              @click="getClientsPar(1, 10)"
            >
              Particulier
            </button>
            <button
              v-else
              class="w-24 bg-cyan-600 p-1 text-white text-sm rounded shadow"
              @click="getClientsPar(1, 10)"
            >
              Particulier
            </button>
          </div>
          <div class="flex flex-row space-x-2 items-center">
            <button
              class="w-24 bg-cyan-600 p-1 text-white text-sm rounded shadow"
              @click="$router.push('/ReportsPage')"
            >
              Rapports
            </button>
            <button
              class="flex items-center p-2 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
              role="alert"
            >
              <svg
                class="flex-shrink-0 inline w-4 h-4 me-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
                />
              </svg>
              <span class="sr-only">Info</span>
              <div class="ml-2">
                <span class="font-medium">Attention!</span> Il existe des
                clients sans commercial attitré
              </div>
            </button>
          </div>
        </div>

        <div class="flex justify-end space-x-2 mt-2" v-if="cdtnClient == false">
          <button
            class="w-24 bg-cyan-600 p-1 text-white text-sm rounded shadow"
            v-if="tabState == 'actif'"
            @click="getClients(1, 10)"
          >
            Actif
          </button>
          <button
            class="w-24 bg-blue-300 border border-cyan-600 p-1 text-white text-sm rounded shadow"
            v-else
            @click="getClients(1, 10)"
          >
            Actif
          </button>
          <button
            class="w-24 bg-cyan-600 p-1 text-white text-sm rounded shadow"
            v-if="tabState == 'archived'"
            @click="getClientsArchived(1, 10)"
          >
            Archivé
          </button>
          <button
            class="w-24 bg-blue-300 border border-cyan-600 p-1 text-white text-sm rounded shadow"
            v-else
            @click="getClientsArchived(1, 10)"
          >
            Archivé
          </button>
        </div>

        <div class="flex justify-end space-x-2" v-if="cdtnClient == true">
          <button
            class="w-24 bg-cyan-600 p-1 text-white text-sm rounded shadow"
            v-if="tabState == 'actif'"
            @click="getClientsPar(1, 10)"
          >
            Actif
          </button>
          <button
            class="w-24 bg-blue-300 border border-cyan-600 p-1 text-white text-sm rounded shadow"
            v-else
            @click="getClientsPar(1, 10)"
          >
            Actif
          </button>
          <button
            class="w-24 bg-cyan-600 p-1 text-white text-sm rounded shadow"
            v-if="tabState == 'archived'"
            @click="getClientsParArchived(1, 10)"
          >
            Archivé
          </button>
          <button
            class="w-24 bg-blue-300 border border-cyan-600 p-1 text-white text-sm rounded shadow"
            v-else
            @click="getClientsParArchived(1, 10)"
          >
            Archivé
          </button>
        </div>

        <div class="flex flex-col mt-2">
          <div
            class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      <div class="flex">
                        <span>Numéro</span>
                        <span
                          class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                          >filter_alt</span
                        >
                      </div>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      <div class="flex">
                        <span>Nom du client</span>
                        <span
                          class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                          >filter_alt</span
                        >
                      </div>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      <div class="flex">
                        <span>Adresse</span>
                        <span
                          class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                          >filter_alt</span
                        >
                      </div>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      <div class="flex">
                        <span>Commercial</span>
                        <span
                          class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                          >filter_alt</span
                        >
                      </div>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      <div class="flex">
                        <span>Visite</span>
                        <span
                          class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                          >filter_alt</span
                        >
                      </div>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Modifier
                    </th>
                    <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <tr v-for="(u, index) in Clients" :key="index">
                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="flex items-center">
                        <!-- <div class="flex-shrink-0 w-10 h-10">
                      <img
                        class="w-10 h-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt
                      />
                    </div>-->

                        <div class="ml-4">
                          <div
                            class="text-sm font-medium leading-5 text-gray-900"
                          >
                            {{ u.NumeroClient }}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                      id="tabName"
                    >
                      <div class="text-sm leading-5 text-gray-900">
                        {{ u.Name }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      {{ u.Adresse }}, {{ u.PostalCode }} {{ u.City }}
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <p
                        v-if="u.Commercial.nom"
                        class="w-fit bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
                      >
                        {{ u.Commercial.nom }}
                      </p>
                      <p
                        v-else
                        class="w-fit bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
                      >
                        Commercial
                      </p>
                    </td>

                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    >
                      <span
                        class="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded border border-blue-400"
                      >
                        <svg
                          class="w-2.5 h-2.5 me-1.5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z"
                          />
                        </svg>
                        <span class="ml-2">il y a 3 jours</span>
                      </span>
                    </td>

                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    >
                      <button
                        @click="chooseClient(u), $router.push('/detailClient')"
                      >
                        <span
                          class="material-icons-outlined m-2 p-0.5 w-8 h-8 shadow hover:bg-green-500 hover:bg-opacity-25 border border-green-500 border-opacity-25 rounded-full text-green-500"
                        >
                          drive_file_rename_outline
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <pageNumber
                v-if="
                  tabState == 'actif' && cdtnClient == false && !searchActive
                "
                :pageStart="pageStart"
                :pageNext="pageNext"
                :tabList="clientsLength"
                :pageTab="pageTab"
                :pageTotal="Math.ceil(clientsLength / 10)"
                :clickFunction="getClients"
                :currentPage="currentPage"
                :typeList="'clients'"
                @update:start="pageStart = $event"
                @update:tab="pageTab = $event"
                @update:next="pageNext = $event"
              />

              <pageNumber
                v-if="
                  tabState == 'archived' && cdtnClient == false && !searchActive
                "
                :pageStart="pageStart"
                :pageNext="pageNext"
                :tabList="clientsArchivedLength"
                :pageTab="pageTab"
                :pageTotal="Math.ceil(clientsArchivedLength / 10)"
                :clickFunction="getClientsArchived"
                :currentPage="currentPage"
                :typeList="'clients'"
                @update:start="pageStart = $event"
                @update:tab="pageTab = $event"
                @update:next="pageNext = $event"
              />

              <pageNumber
                v-if="
                  tabState == 'actif' && cdtnClient == true && !searchActive
                "
                :pageStart="pageStart"
                :pageNext="pageNext"
                :tabList="clientsParLength"
                :pageTab="pageTab"
                :pageTotal="Math.ceil(clientsParLength / 10)"
                :clickFunction="getClientsPar"
                :currentPage="currentPage"
                :typeList="'clients'"
                @update:start="pageStart = $event"
                @update:tab="pageTab = $event"
                @update:next="pageNext = $event"
              />

              <pageNumber
                v-if="
                  tabState == 'archived' && cdtnClient == true && !searchActive
                "
                :pageStart="pageStart"
                :pageNext="pageNext"
                :tabList="clientsParArchivedLength"
                :pageTab="pageTab"
                :pageTotal="Math.ceil(clientsParArchivedLength / 10)"
                :clickFunction="getClientsParArchived"
                :currentPage="currentPage"
                :typeList="'clients'"
                @update:start="pageStart = $event"
                @update:tab="pageTab = $event"
                @update:next="pageNext = $event"
              />

              <!-- searchActive -->

              <pageNumber
                v-if="
                  tabState == 'actif' && cdtnClient == false && searchActive
                "
                :pageStart="pageStart"
                :pageNext="pageNext"
                :tabList="searchLength"
                :pageTab="pageTab"
                :pageTotal="Math.ceil(searchLength / 10)"
                :clickFunction="
                  (arg1, arg2) => search(arg1, arg2, true, false, searchTerm)
                "
                :currentPage="currentPage"
                :typeList="'clients pro actifs'"
                @update:start="pageStart = $event"
                @update:tab="pageTab = $event"
                @update:next="pageNext = $event"
              />

              <pageNumber
                v-if="
                  tabState == 'archived' && cdtnClient == false && searchActive
                "
                :pageStart="pageStart"
                :pageNext="pageNext"
                :tabList="searchLength"
                :pageTab="pageTab"
                :pageTotal="Math.ceil(searchLength / 10)"
                :clickFunction="
                  (arg1, arg2) => search(arg1, arg2, true, true, searchTerm)
                "
                :currentPage="currentPage"
                :typeList="'clients pro archivés'"
                @update:start="pageStart = $event"
                @update:tab="pageTab = $event"
                @update:next="pageNext = $event"
              />

              <pageNumber
                v-if="tabState == 'actif' && cdtnClient == true && searchActive"
                :pageStart="pageStart"
                :pageNext="pageNext"
                :tabList="searchLength"
                :pageTab="pageTab"
                :pageTotal="Math.ceil(searchLength / 10)"
                :clickFunction="
                  (arg1, arg2) => search(arg1, arg2, false, false, searchTerm)
                "
                :currentPage="currentPage"
                :typeList="'clients par actifs'"
                @update:start="pageStart = $event"
                @update:tab="pageTab = $event"
                @update:next="pageNext = $event"
              />

              <pageNumber
                v-if="
                  tabState == 'archived' && cdtnClient == true && searchActive
                "
                :pageStart="pageStart"
                :pageNext="pageNext"
                :tabList="searchLength"
                :pageTab="pageTab"
                :pageTotal="Math.ceil(searchLength / 10)"
                :clickFunction="
                  (arg1, arg2) => search(arg1, arg2, false, true, searchTerm)
                "
                :currentPage="currentPage"
                :typeList="'clients par archivés'"
                @update:start="pageStart = $event"
                @update:tab="pageTab = $event"
                @update:next="pageNext = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import * as XLSX from "xlsx";
import readXlsxFile from "read-excel-file";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useToast } from "vue-toastification";
import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyClient",
  data() {
    return {
      open: false,
      openModifier: false,
      showAjoutPro: false,
      showAjoutPar: false,
      showChooseClient: false,
      tabState: "",
      searchInClientList: "",

      allClients: [],
      Clients: [],
      ClientsPar: [],
      cdtnClient: false,

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      clientsLength: 0,
      clientsArchivedLength: 0,
      clientsParLength: 0,
      clientsParArchivedLength: 0,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,

      loadingExport: false,

      // Import
      loadingImport: false,
      showFile: false,
      showFileContent: true,
      importLoading: false,
      showFinished: false,
      file: null,
      readFile: null,
    };
  },
  components: {
    pageNumber,
    searchInput,
    PulseLoader,
    titrePages,
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
  },
  methods: {
    ...mapActions(["getclientchoosedtab"]),
    sortName() {
      Array.sort(this.Name);
    },

    async getAllClients() {
      let archive = false;
      if (this.tabState == "actif") {
        archive = false;
      } else {
        archive = true;
      }
      let cdtnPro = false;
      if (this.cdtnClient == false) {
        cdtnPro = true;
      } else {
        cdtnPro = false;
      }
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .post(`${process.env.VUE_APP_API}/client/getAllByPlateform`, {
          plateform: plateform_mere,
          pro: cdtnPro,
          archived: archive,
        })
        .then((response) => {
          this.allClients = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // PROFESSIONNEL
    async getClients(skipValue, limitValue) {
      this.tabState = "actif";
      this.searchActive = false;
      this.currentPage = skipValue;
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .post(`${process.env.VUE_APP_API}/client/getbyowner/getpage`, {
          plateform: plateform_mere,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          this.Clients = response.data;
          this.cdtnClient = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getClientsArchived(skipValue, limitValue) {
      this.tabState = "archived";
      this.currentPage = skipValue;
      this.searchActive = false;
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .post(`${process.env.VUE_APP_API}/client/getbyownerarchived/getpage`, {
          plateform: plateform_mere,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          console.log("pro archived");
          this.Clients = response.data;
          this.cdtnClient = false;
          this.getClientsArchivedLength();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getClientsLength() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .post(`${process.env.VUE_APP_API}/client/getbyowner/count`, {
          plateform: plateform_mere,
        })
        .then((response) => {
          this.clientsLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getClientsArchivedLength() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .post(`${process.env.VUE_APP_API}/client/getbyownerarchived/count`, {
          plateform: plateform_mere,
        })
        .then((response) => {
          this.clientsArchivedLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // PARTICULIER
    async getClientsPar(skipValue, limitValue) {
      this.tabState = "actif";
      this.currentPage = skipValue;
      this.searchActive = false;
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .post(
          `${process.env.VUE_APP_API}/client/getbyownerparticulier/getpage`,
          {
            plateform: plateform_mere,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          }
        )
        .then((response) => {
          this.ClientsPar = response.data;
          this.Clients = response.data;
          this.cdtnClient = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getClientsParArchived(skipValue, limitValue) {
      this.tabState = "archived";
      this.currentPage = skipValue;
      this.searchActive = false;
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .post(
          `${process.env.VUE_APP_API}/client/getbyownerparticulierarchived/getpage`,
          {
            plateform: plateform_mere,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          }
        )
        .then((response) => {
          this.Clients = response.data;
          this.cdtnClient = true;
          this.getClientsParArchivedLength();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getClientsParLength() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      axios
        .post(`${process.env.VUE_APP_API}/client/getbyownerparticulier/count`, {
          plateform: plateform_mere,
        })
        .then((response) => {
          this.clientsParLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getClientsParArchivedLength() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      axios
        .post(
          `${process.env.VUE_APP_API}/client/getbyownerparticulierarchived/count`,
          {
            plateform: plateform_mere,
          }
        )
        .then((response) => {
          this.clientsParArchivedLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    filterClients() {
      this.Clients = this.ClientsPar;
      this.cdtnClient = true;
    },
    filterClientsArchived() {
      this.Clients = this.ClientsPar;
      this.cdtnClient = true;
      this.tabState = "actif";
    },
    chooseClient(cl) {
      this.getclientchoosedtab(cl);
      // this.$router.push("/detailClient");
    },
    reloadPage() {
      window.location.reload();
    },
    async exportExcel() {
      this.loadingExport = true;
      await this.getAllClients();

      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(this.allClients);
      XLSX.utils.book_append_sheet(wb, ws, "Clients");

      XLSX.writeFile(wb, "Liste_Clients" + this.user.proprietaire + ".xlsx");
      this.loadingExport = false;

      this.success("Le fichier a été téléchargé");
    },
    async readExcel() {
      this.file = event.target.files ? event.target.files[0] : null;
      this.readFile = await readXlsxFile(this.file);
      this.showFile = true;
    },
    closeImport() {
      this.showFile = false;
      this.readFile = null;
      this.file = null;
      this.showFinished = false;
      this.showFileContent = true;
      this.getClients(1, 10);
    },
    importExcel() {
      this.importLoading = true;
      this.showFileContent = false;
      axios
        .post(`${process.env.VUE_APP_API}/client/importExcelCutOff`, {
          data: this.readFile,
          plateform: this.user.proprietaire,
        })
        .then(() => {
          this.importLoading = false;
          this.showFinished = true;
        });
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    async search(skipValue, limitValue, professionnel, archive, searchTerm) {
      if (searchTerm == "") {
        this.searchActive = false;
        if (archive == false && professionnel == true) {
          console.log("pro actif");
          this.getClients(skipValue, limitValue);
          this.getClientsLength();
        } else if (archive == true && professionnel == true) {
          console.log("pro archived");
          this.getClientsArchived(skipValue, limitValue);
          this.getClientsArchivedLength();
        } else if (archive == false && professionnel == false) {
          console.log("par actif");
          this.getClientsPar(skipValue, limitValue);
          this.getClientsParLength();
        } else if (archive == true && professionnel == false) {
          console.log("par archived");
          this.getClientsParArchived(skipValue, limitValue);
          this.getClientsParArchivedLength();
        }
        this.pageSwitch();
      } else {
        if (professionnel == true) {
          this.cdtnClient = false;
        } else {
          this.cdtnClient = true;
        }
        if (archive == false) {
          this.tabState = "actif";
        } else {
          this.tabState = "archived";
        }
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        await axios
          .post(`${process.env.VUE_APP_API}/client/searchInPlateform`, {
            plateform: plateform_mere,
            professionnel: professionnel,
            archive: archive,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.Clients = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
          });
      }
    },
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getClients(1, 10);
    this.getClientsLength();
    this.pageSwitch();
  },
};
</script>

<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
