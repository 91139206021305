<template>
  <div class="flex justify-between mb-6">
    <div id="retour" class="flex justify-start">
      <backButton :route="'/fournisseurs'" />
    </div>
    <titrePages
      class="flex flex-1 justify-center"
      :title="'Priorités fournisseur'"
    />
  </div>

  <div class="flex flex-col space-y-2">
    <div
      v-for="(fournisseur, indexFournisseur) in fournisseurList"
      :key="indexFournisseur"
      class="flex flex-col space-y-2"
    >
      <div
        class="flex flex-row items-center space-x-4 p-2 bg-white rounded-lg shadow-md"
        draggable="true"
        @dragstart="dragStart(indexFournisseur)"
        @drop="drop(indexFournisseur)"
        @dragenter.prevent
        @dragover.prevent
      >
        <div>{{ fournisseur.CompanyName }}</div>
        <div>{{ fournisseur.priority }}</div>
        <div class="flex flex-col space-y-1">
          <div>
            <span
              class="material-icons-round text-green-500 cursor-pointer"
              @click="
                fournisseur.priority++,
                  sortByPriority(),
                  saveChange(fournisseur._id, fournisseur.priority)
              "
            >
              add_circle
            </span>
          </div>
          <div>
            <span
              class="material-icons-round text-red-500 cursor-pointer"
              @click="
                fournisseur.priority--,
                  sortByPriority(),
                  saveChange(fournisseur._id, fournisseur.priority)
              "
            >
              remove_circle
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import backButton from "@/components/Button/back.vue";
import titrePages from "@/components/Text/grandeTitre.vue";
export default {
  data() {
    return {
      fournisseurList: [],
      priorityTab: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.fournisseurList = response.data;
          this.sortByPriority();
        });
    },
    saveChange(id, priority) {
      axios.put(
        `${process.env.VUE_APP_API}/fournisseur/modifyFournisseur/${id}`,
        {
          data: {
            priority: priority,
          },
        }
      );
    },
    sortByPriority() {
      this.fournisseurList.sort((a, b) => {
        return b.priority - a.priority;
      });
    },
    dragStart(index) {
      console.log("dragStart => ", index);
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
    },
    drop(index) {
      console.log("drop => ", index);
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.getFournisseur();
  },
  components: {
    backButton,
    titrePages,
  },
};
</script>
<style></style>
