<template>
  <div
    v-if="openMAN"
    :class="`modal ${
      !openMAN && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openMAN = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 80vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Articles non scannés</p>
          <div class="flex flex-row items-center space-x-2 text-xs">
            <div
              :class="`p-3 bg-bluevk text-white rounded shadow cursor-pointer font-bold ${
                inventaireProcessing.init_type === 'init' &&
                'bg-orangevk text-white'
              }`"
              @click="updateInit('init')"
            >
              Init à 0
            </div>
            <div
              :class="`p-3 bg-bluevk text-white rounded shadow cursor-pointer font-bold ${
                inventaireProcessing.init_type === 'noInit' &&
                'bg-orangevk text-white'
              }`"
              @click="updateInit('noInit')"
            >
              Ne pas initialiser à 0
            </div>
          </div>
          <div>
            <button
              v-if="inventaireProcessing.init_type === 'init' && !loadingExport"
              class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
              @click="exportExcelArticles()"
            >
              <span class="material-icons-outlined text-xl mr-2">
                download
              </span>
              <span>Exporter en Excel</span>
            </button>
          </div>
          <div class="z-50 cursor-pointer modal-close" @click="openMAN = false">
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div>
          <div class="w-full bg-white flex flex-col">
            <div class="max-h-60 overflow-y-auto">
              <div class="grid grid-cols-4 gap-2">
                <div
                  v-for="(art, index) in unScannedArticles"
                  :key="index"
                  class="flex items-center justify-between p-2 bg-gray-200 rounded shadow"
                >
                  <div class="flex flex-col">
                    <p class="text-sm">{{ art.Ref_fournisseur }}</p>
                    <p class="text-xs">{{ art.Code_marque }}</p>
                  </div>
                  <div class="flex flex-col">
                    <p class="text-sm text-gray-400">Stock</p>
                    <p class="text-xs text-center">
                      {{ art.stock }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full justify-end mb-2">
              <button
                class="p-2 bg-bluevk text-white font-semibold rounded shadow"
                @click="miseANiveau()"
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="tabInventaire">
    <!-- Back Button -->
    <div class="flex justify-between">
      <div id="retour" class="flex justify-start">
        <backButton :route="'/hubInventaireStock'" />
      </div>
      <div class="flex justify-end space-x-2">
        <div class="mr-14">
            <button
              :disabled="loadingExport"
              class="bg-green-600 text-white hover:bg-green-400 shadow-lg hover:text-white py-1 px-2 h-fit m-auto rounded-[8px] mr-1 flex justify-center items-center"
              @click="exportExcelArticles()"
            >
              <span class="material-icons-outlined text-[22px] mr-2">
                download
              </span>
              <span class="text-[14px]">Exporter en Excel<br>init à 0</span>
            </button>
          </div>
        <button
          v-if="!inventaireProcessing.stock_update"
          class="p-1 rounded-[8px] shadow-lg bg-green-600 hover:bg-green-400 text-white"
          @click="getNoScan()"
        >
          Mettre à niveaux les stocks
        </button>
        <button
          class="p-1 rounded-[8px] shadow-lg bg-bluevk hover:bg-blue-500 text-white flex justify-center items-center"
          @click="exportExcel()"
        >
          <span class="material-icons-outlined text-xl mr-2"> download </span>
          Exporter en Excel
        </button>
      </div>
    </div>
    <!-- Fin Back Button -->
    <div class="flex flex-row items-center justify-between mt-1">
      <span>Inventaire n° {{ inventaireProcessing.inventory_number }}</span>
    </div>
    <div
      class="rounded shadow uppercase w-fit mt-2 p-1"
      v-if="inventaireProcessing.address"
    >
      address: {{ inventaireProcessing.addresse }} Marque:
      <span
        v-for="(bd, indexBd) in inventaireProcessing.brand"
        :key="indexBd"
        >{{ bd }}</span
      >
    </div>
    <div>
      <div class="flex flex-col mt-2">
        <div
          class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Utilisateur
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Ref_fournisseur
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Adresse
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Quantité théorique
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Quantité scannée
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    <div class="flex flex-row items-center space-x-2">
                      <div>Mise à niveau du stock</div>
                      <div class="text-xs text-gray-500">
                        <span
                          v-if="inventaireProcessing.stock_update"
                          class="p-1 bg-green-500 text-white rounded shadow"
                        >
                          Faite
                        </span>
                        <span
                          v-else
                          class="p-1 bg-red-500 text-white rounded shadow"
                        >
                          Non
                        </span>
                      </div>
                    </div>
                  </th>

                  <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                </tr>
              </thead>

              <tbody class="bg-white">
                <tr v-for="(u, index) in tabInventaire.ligne" :key="index">
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div
                      v-if="u.scanned_by"
                      class="text-sm leading-5 text-gray-900"
                    >
                      {{ u.scanned_by }}
                    </div>
                    <div v-else class="text-sm leading-5 text-gray-900">
                      pas d'utilisateur répertorié
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    {{ u.Ref_fournisseur }}
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div v-if="inventaireProcessing.address">
                      {{ inventaireProcessing.address }}
                    </div>
                  </td>

                  <td
                    class="bg-red-100 px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    v-if="u.quantity_theo != u.quantity_inventory"
                  >
                    {{ u.quantity_theo }}
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    v-else
                  >
                    {{ u.quantity_theo }}
                  </td>
                  <td
                    v-if="u.quantity_theo != u.quantity_inventory"
                    class="bg-red-100 px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap"
                  >
                    {{ u.quantity_inventory }}
                  </td>
                  <td
                    v-else
                    class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap"
                  >
                    {{ u.quantity_inventory }}
                  </td>
                  <td
                    class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div v-if="u.quantity_theo != u.quantity_inventory">
                      <span
                        v-if="u.quantity_theo > u.quantity_inventory"
                        class="text-red-500"
                      >
                        {{ u.quantity_inventory - u.quantity_theo }}</span
                      >
                      <span v-else class="text-green-500">
                        + {{ u.quantity_inventory - u.quantity_theo }}</span
                      >
                    </div>
                    <div v-else>Ok</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import * as XLSX from "xlsx";
import { stockMiseANiveau } from "@/hooks/stock/stock.ts";
import readXlsxFile from "read-excel-file";
import {
  getInventoryLines,
  updateInventoryStock,
  getNoScannedArticles,
  updateInventory,
} from "@/hooks/stock/inventaire.ts";
import backButton from "@/components/Button/back.vue";
export default {
  data() {
    return {
      articleSearchEAN: "",
      stateArticle: "none",
      scannedArticle: "",
      scanStarted: false,
      rescanned: false,
      inputQuantity: 0,
      resultUpdatedInventory: 0,
      tabInventaire: {},
      openMAN: false,
      unScannedArticles: [],
      loadingExport: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    backButton,
  },
  computed: {
    ...mapGetters(["user", "artInfosInventory", "inventaireProcessing"]),
  },
  methods: {
    ...mapActions([
      "setartinventory",
      "removeartinventory",
      "setinventoryprocessing",
      "removeinventoryprocessing",
    ]),
    async getInventaire() {
      const Lines = await getInventoryLines(
        this.inventaireProcessing._id,
        0,
        1000,
        "_id",
        1,
        this.user._id
      );
      console.log(Lines);
      this.tabInventaire["ligne"] = Lines.data;
    },
    backToHub() {
      this.removeinventoryprocessing();
      this.$router.push("/hubInventaireStock");
    },
    success(message) {
      this.toast.success(message, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning(message) {
      this.toast.warning(message, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.error(message, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    exportExcel() {
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(this.tabInventaire.ligne);
      XLSX.utils.book_append_sheet(wb, ws, "Inventaire");

      XLSX.writeFile(
        wb,
        "Inventaire_" +
          this.inventaireProcessing.inventory_number +
          "_" +
          this.user.proprietaire +
          ".xlsx"
      );

      this.success("Le fichier a été téléchargé");
    },
    async getNoScan() {
      this.openMAN = true;
      const res = await getNoScannedArticles(
        this.inventaireProcessing,
        this.user._id
      );
      this.unScannedArticles = res.data;
    },
    async miseANiveau() {
      this.loadingMAN = true;
      const res = await updateInventoryStock(
        this.inventaireProcessing,
        this.user._id
      );
      this.setinventoryprocessing(res.data);
      this.openMAN = false;
      this.success("Mise à niveau effectuée");
      // this.backToHub();
    },
    async updateInit(value) {
      if (value == "noInit") {
        this.unScannedArticles = [];
      } else {
        await this.getNoScan();
      }

      const res = await updateInventory(
        this.inventaireProcessing._id,
        { init_type: value },
        this.user._id
      );
      this.setinventoryprocessing(res.data);
    },
    async exportExcelArticles() {
  try {
    this.loadingExport = true;

    const res = await getNoScannedArticles(
      this.inventaireProcessing,
      this.user._id
    );
    this.unScannedArticles = res.data;

    const filteredData = this.unScannedArticles.map(article => ({
      Ref_fournisseur: article.Ref_fournisseur || "",
      stock: article.stock || "",
      address: article.address || "",
      Prefixe_tarif: article.Prefixe_tarif || "",
      Code_marque: article.Code_marque || ""
    }));

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(wb, ws, "Articles non scannés");

    XLSX.writeFile(
      wb,
      "Articles_non_scannes_" + (this.user.proprietaire || "unknown") + ".xlsx"
    );

    this.success("Le fichier a été téléchargé");
  } catch (error) {
    console.error("Erreur lors de l'exportation du fichier Excel:", error);
    this.error("Une erreur est survenue lors de l'exportation.");
  } finally {
    this.loadingExport = false;
  }
},

  },

  mounted() {
    this.getInventaire();
  },
};
</script>
<style></style>
