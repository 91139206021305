<template>
  <div>
    <!-- BODY -->
    <div id="body" class="bg-white p-2 rounded-md">
      <titrePages
        class="flex flex-1 justify-center"
        :title="'Liste des fournisseurs'"
      />
      <div class="mt-1 flex justify-center mt-8">
        <menuFournisseur />
      </div>
      <div class="flex justify-between mt-8">
        <searchInput
          placeholder="Rechercher dans fournisseurs"
          @searchWord="search(1, 10, $event)"
          @catchFalse="searchCatchError = $event"
          :searchStarted="searchInProgress"
          :catchError="searchCatchError"
        />
        <router-link to="/creerFournisseur">
          <div>
            <button
              class="px-6 py-2 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            >
              Ajouter
            </button>
          </div>
        </router-link>
      </div>

      <div class="flex flex-col mt-4">
        <div
          class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full border shadow">
              <thead class="bg-gray-100 text-gray-900">
                <tr>
                  <th
                    class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
                  >
                    <div class="flex">
                      <span>type</span>
                      <span
                        class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                        >filter_alt</span
                      >
                    </div>
                  </th>

                  <th
                    class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
                  >
                    <div class="flex">
                      <span>Priorité</span>
                      <span
                        class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                        >filter_alt</span
                      >
                    </div>
                  </th>

                  <th
                    class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
                  >
                    <div class="flex">
                      <span>Préfixe Tarif</span>
                      <span
                        class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                        >filter_alt</span
                      >
                    </div>
                  </th>

                  <th
                    class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
                  >
                    <div class="flex">
                      <span>Nom</span>
                      <span
                        class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                        >filter_alt</span
                      >
                    </div>
                  </th>

                  <th
                    class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
                  >
                    <div class="flex">
                      <span>Type</span>
                      <span
                        class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                        >filter_alt</span
                      >
                    </div>
                  </th>

                  <th
                    class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
                  >
                    <div class="flex">
                      <span>MAJ Tarifs</span>
                      <span
                        class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                        >filter_alt</span
                      >
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white">
                <tr
                  v-for="(f, index) in fournisseurList"
                  :key="index"
                  class="tab-list cursor-pointer odd:bg-white even:bg-slate-50"
                  @click="chooseFournisseur(f)"
                >
                  <td
                    class="uppercase px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div class="flex items-center space-x-2">
                      <img
                        v-if="f.typeArticle == 'pneu'"
                        src="@/assets/icon/pneu.png"
                        class="h-8 w-8"
                      />
                      <img
                        v-if="f.typeArticle == 'pieces'"
                        src="@/assets/icon/pieceauto.png"
                        class="h-8 w-8"
                      />
                      <div
                        v-if="f.typeArticle == 'all'"
                        class="flex flex-row items-center space-x-1"
                      >
                        <img src="@/assets/icon/pneu.png" class="h-8 w-8" />
                        <img
                          src="@/assets/icon/pieceauto.png"
                          class="h-8 w-8"
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    class="uppercase px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div class="flex items-center space-x-2">
                      <span
                        class="px-1 bg-blue-500 text-white rounded shadow"
                        >{{ f.priority }}</span
                      >
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    {{ f.Prefixe_tarif }}
                  </td>

                  <td
                    class="uppercase px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div class="flex items-center space-x-2">
                      <div class="text-sm leading-5 text-gray-900">
                        {{ f.CompanyName }}
                      </div>
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div class="flex space-x-2">
                      <span>{{ f.typeCondition }}</span>
                      <span
                        class="material-icons-round text-red-500"
                        v-if="!f.typeCondition"
                      >
                        warning
                      </span>
                    </div>
                  </td>

                  <td
                    class="uppercase px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div class="flex flex-row items-center space-x-2">
                      <div>
                        <span
                          class="p-1 bg-orange-500 text-white text-xs rounded shadow"
                          v-if="f.golda"
                          >golda</span
                        >
                        <span
                          v-else
                          class="p-1 bg-blue-500 text-white text-xs rounded shadow"
                        >
                          {{ user.proprietaire }}
                        </span>
                      </div>
                      <div class="flex space-x-1">
                        <div v-if="!f.golda">
                          <span
                            class="material-icons-round text-red-500"
                            v-if="!f.info_maj.updated"
                          >
                            warning
                          </span>
                          <img
                            v-if="f.info_maj.updated"
                            class="w-6 h-6"
                            src="../assets/ValidIcon.png"
                          />
                          <div class="text-xs m-auto" v-if="f.info_maj.updated">
                            {{ f.info_maj.date }}
                          </div>
                        </div>
                        <div v-else>
                          <updateTarifState :Prefixe_tarif="f.Prefixe_tarif" />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <pageNumber
              v-if="!searchActive"
              :pageStart="pageStart"
              :pageNext="pageNext"
              :tabList="fournisseursLength"
              :pageTab="pageTab"
              :pageTotal="Math.ceil(fournisseursLength / 10)"
              :clickFunction="getFournisseur"
              :currentPage="currentPage"
              :typeList="'fournisseurs'"
              @update:start="pageStart = $event"
              @update:tab="pageTab = $event"
              @update:next="pageNext = $event"
            />
            <pageNumber
              v-if="searchActive"
              :pageStart="pageStart"
              :pageNext="pageNext"
              :tabList="searchLength"
              :pageTab="pageTab"
              :pageTotal="Math.ceil(searchLength / 10)"
              :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
              :currentPage="currentPage"
              :typeList="'fournisseurs'"
              @update:start="pageStart = $event"
              @update:tab="pageTab = $event"
              @update:next="pageNext = $event"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import { mapGetters, mapActions } from "vuex";
import menuFournisseur from "@/components/Fournisseur/menuFournisseur.vue";
import updateTarifState from "@/components/Fournisseur/updateTarif/updateTarifState.vue";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyFournisseurs",
  data() {
    return {
      fournisseurList: [],
      fournissChosed: [],
      paginateArray: [],
      showDetailFournisseur: false,
      showAjouterFournisseur: false,
      searchInFournisseurList: "",

      Fournisseurs: [],
      index: [],

      modifConditionActive: false,

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      fournisseursLength: 0,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,
    };
  },
  components: {
    menuFournisseur,
    updateTarifState,
    pageNumber,
    searchInput,
    titrePages,
  },
  computed: {
    ...mapGetters(["user"]),
    // filteredFournisseur() {
    //   if (this.searchInFournisseurList) {
    //     console.log("liste des fournisseurs");
    //     return this.fournisseurList.filter((item) => {
    //       return item.CompanyName.toUpperCase().startsWith(
    //         this.searchInFournisseurList.toUpperCase()
    //       );
    //     });
    //   } else {
    //     return this.fournisseurList;
    //   }
    // },
  },
  methods: {
    ...mapActions(["getfournisseurID"]),
    getFournisseur(skipValue, limitValue) {
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall/getpage`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          console.log(response.data);
          this.fournisseurList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFournisseurLength() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall/count`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.fournisseursLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    chooseFournisseur(fo) {
      this.getfournisseurID(fo._id);
      this.$router.push("/detailFournisseur");
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getFournisseur(skipValue, limitValue);
        this.getFournisseurLength();
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/fournisseur/searchInPlateform`, {
            plateform: this.user.proprietaire,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.fournisseurList = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.getFournisseur(1, 10);
    this.getFournisseurLength();
    this.pageSwitch();
  },
};
</script>

<style>
.tab-list:hover:nth-child(odd) {
  background-color: rgb(229 231 235);
  /* opacity: 0.6; */
}

.tab-list:hover:nth-child(even) {
  background-color: rgb(229 231 235);
  /* opacity: 0.6; */
}

input ~ .dot {
  transform: translateX(0%);
  background-color: #ebd368;
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #75eb68;
}
</style>
