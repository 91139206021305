<template>
  <!-- Confirmation Modal -->
  <div
    v-if="showConfirmationModal"
    class="z-50 fixed w-screen h-screen top-0 left-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
  >
    <!-- Modal Overlay -->
    <div
      @click="cancelGroupChange"
      class="absolute w-screen h-screen bg-transparent modal-overlay"
    ></div>

    <!-- Modal Container -->
    <div
      class="relative bg-gray-50 border-2 border-sky-200 rounded-md shadow-md p-6 w-[20rem] z-40"
    >
      <!-- Close Button -->
      <div
        class="absolute top-2 right-2 flex flex-col items-center text-sm text-gray-600 cursor-pointer"
        @click="cancelGroupChange"
      >
        <svg
          class="fill-current text-gray-600 hover:text-gray-800"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
      </div>

      <!-- Modal Content -->
      <div class="text-center">
        <p class="text-lg font-bold text-gray-800">
          Etes-vous sûr de vouloir continuer ?
        </p>
        <div class="flex justify-center space-x-4 mt-4">
          <!-- Confirm Button -->
          <button
            @click="saveModifierGroupClient()"
            class="bg-green-600 hover:bg-green-400 shadow-lg text-white px-4 py-1 rounded-lg"
          >
            Oui
          </button>
          <!-- Cancel Button -->
          <button
            @click="cancelGroupChange"
            class="bg-red-600 hover:bg-red-400 shadow-lg text-white px-4 py-1 rounded-lg"
          >
            Non
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL SUPPRIMER CLIENT PARTICULIER -->
  <div
    v-if="showDeleteClientPar"
    :class="`modal ${
      !showDeleteClientPar && 'opacity-0 pointer-events-none'
    } z-50 fixed w-screen h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showDeleteClientPar = false"
      class="absolute w-screen h-screen bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-40 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-40 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        @click="showDeleteClientPar = false"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left h-full modal-content">
        <!--Title-->
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold"></p> -->
          <div
            class="z-40 cursor-pointer modal-close"
            @click="showDeleteClientPar = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div class="flex flex-col space-y-4">
          <p class="font-medium text-xl">Supprimer définitivement ?</p>
          <div class="flex space-x-4 justify-center">
            <button
              class="shadow-lg w-fit px-2 py-1 h-fit rounded-md text-white bg-green-600 hover:bg-green-400"
              @click="confirmDelete()"
            >
              Oui
            </button>
            <button
              class="shadow-lg w-fit px-2 py-1 h-fit text-white rounded-md bg-red-600 hover:bg-red-400"
              @click="showDeleteClientPar = false"
            >
              Non
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="openSave"
    :class="`modal ${
      !openSave && 'opacity-0 pointer-events-none'
    } z-40 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openSave = false), $emit('closeModalEncaissement', false)"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-40 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 72rem; max-height: 52rem"
    >
      <div
        class="absolute top-0 right-0 z-40 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span
          @click="(openSave = false), $emit('closeModalEncaissement', false)"
          class="text-sm"
          >(Esc)</span
        >
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Encaissement</p>
          <div
            class="z-40 cursor-pointer modal-close"
            @click="(openSave = false), $emit('closeModalEncaissement', false)"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="flex space-x-4">
          <p class="text-[16px] font-bold text-black">Créée par:</p>
          <p class="text-[14px] font-semibold text-black">
            {{ createdBy }}
          </p>
        </div>

        <!--Body-->
        <div class="px-5 py-6 text-gray-700 bg-gray-50 shadow-md">
          <div class="relative">
            <div
              id="client_info_display"
              class="block px-2.5 pb-2.5 pt-4 w-[32rem] h-auto text-gray-900 bg-white rounded-lg border-2 border-sky-200 appearance-none shadow-md focus:outline-none focus:ring-0 focus:border-sky-300 resize-none"
            >
              <p class="text-lg font-semibold text-gray-900">
                {{ clientChoosed.Name }}
              </p>
              <p class="text-sm text-gray-800">
                {{
                  clientChoosed.NumeroClient
                    ? clientChoosed.NumeroClient + " - "
                    : ""
                }}
                {{ clientChoosed.Adresse ? clientChoosed.Adresse : "" }}
                {{
                  clientChoosed.Complement
                    ? ", " + clientChoosed.Complement
                    : ""
                }}
                {{
                  clientChoosed.PostalCode
                    ? ", " + clientChoosed.PostalCode
                    : ""
                }}
                <span class="text-base font-medium text-gray-900">
                  {{ clientChoosed.City ? ", " + clientChoosed.City : "" }}
                </span>
              </p>
            </div>
            <label
              for="client_info_display"
              class="ml-1 absolute text-base font-bold text-gray-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-50 px-2 peer-focus:px-2 peer-focus:text-blue-600"
              >Client</label
            >
          </div>
        </div>
        <div class="relative">
          <div class="flex flex-col">
            <div class="flex flex-row space-x-2">
              <input
                @input="handleInput"
                @focus="inputClient = ''"
                @keydown.enter="searchClient()"
                v-model="inputClient"
                placeholder="Modifier la session"
                @click="(showClientList = !showClientList), getFavori(false)"
                class="my-auto mt-1 border-2 px-1 py-0.5 border-gray-500 w-full text-black rounded-md focus:placeholder-gray-600 focus:text-gray-700 focus:outline-2 outline-sky-300"
              />
              <button
                @click="newClient()"
                class="bg-bluevk hover:bg-opacity-60 text-white px-4 py-2 shadow rounded-md"
              >
                Nouveau
              </button>
            </div>
          </div>
          <div
            v-if="showClientList"
            style="height: 53vh"
            class="absolute z-20 border-2 border-sky-200 shadow-lg rounded-md mt-1 bg-gray-50 text-gray-800 p-1 w-full flex flex-col overflow-y-auto"
          >
            <button
              class="right-0 material-icons-outlined flex justify-end text-red-500 my-2 mr-2"
              @click="showClientList = false"
            >
              cancel
            </button>
            <div
              class="cursor-pointer rounded-sm hover:bg-gray-200 flex items-cent  er justify-between p-2"
              @click="
                (clientChoosed = cl),
                  (Clients = []),
                  updateSessionClient(clientChoosed),
                  (showClientList = false),
                  (inputClient = '')
              "
              v-for="(cl, indexCL) in Clients"
              :key="indexCL"
            >
              <div>
                <p class="font-bold">{{ cl.Name }}</p>
                <p class="">
                  {{ cl.NumeroClient ? cl.NumeroClient + " - " : "" }}
                  {{ cl.Adresse ? cl.Adresse : "" }}
                  {{ cl.Complement ? ", " + cl.Complement : "" }}
                  {{ cl.PostalCode ? ", " + cl.PostalCode : "" }}
                  {{ cl.City ? ", " + cl.City : "" }}
                </p>
              </div>

              <!-- right Select, Edit ve Delete -->
              <div class="flex space-x-4">
                <div>
                  <!-- Select Dropdown -->
                  <select
                    @click.stop="saveGroupBeforeEdit(cl, indexCond)"
                    v-model="cl.Group.Name"
                    @change="confirmGroupChange(cl, indexCond)"
                    class="w-full focus:outline-2 outline-sky-300 text-black p-2 border-2 border-gray-100 rounded-md cursor-pointer hover:border-2 hover:border-sky-500"
                  >
                    <option
                      :value="cl.Name"
                      v-for="(cl, indexCond) in configurationConditionList"
                      :key="indexCond"
                    >
                      {{ cl.Name }}
                    </option>
                  </select>
                </div>

                <!-- Delete Button -->
                <div class="relative mt-2">
                  <button
                    class="text-red-600 hover:text-red-400 transition delay-150 duration-400 ease-in-out hover:-translate-y-1 hover:scale-110"
                    @click.stop="suppClient(cl, indexCond)"
                  >
                    <span class="material-icons-outlined">delete</span>
                  </button>
                </div>

                <!-- Edit Button -->
                <button
                  class="text-blue-600 hover:text-blue-400 transition delay-150 duration-400 ease-in-out hover:-translate-y-1 hover:scale-110"
                  @click.stop="editClient(cl)"
                >
                  <span class="material-icons-outlined">edit</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="px-5 py-6 text-gray-700 bg-gray-50 shadow-md">
          <label class="text-lg font-bold">Mode de règlement</label>
          <div class="relative">
            <label class="text-[14px] text-black mt-6 font-medium"
              >Montant à payer : {{ financial(setTotalTTC) }} €</label
            >
          </div>

          <div class="relative flex justify-center space-x-8 p-1">
            <div class="" v-for="(moyen, ind) in moyenPaiement" :key="ind">
              <div class="flex flex-col">
                <img
                  class="w-[5rem] border-2 border-sky-300 rounded-md m-auto cursor-pointer hover:shadow hover:bg-gray-200 focus:outline-2 outline-sky-300 shadow-lg"
                  :src="moyen.image"
                  @click="setReglement(moyen.abbrev)"
                />
                <span style="font-size: 15px" class="m-auto mt-1 font-medium">{{
                  moyen.type
                }}</span>
              </div>
            </div>
          </div>

          <div
            class="relative mt-2 rounded-md shadow-sm"
            v-for="(reglement, index) in reglementTab"
            :key="index"
          >
            <span
              class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
            >
              <img class="w-9 h-9 rounded-md m-auto" :src="reglement.img" />
            </span>

            <input
              type="number"
              :placeholder="reglement.placeholder"
              :disabled="reglementTab[index].disabled"
              v-model="reglementTab[index].model"
              class="w-2/5 focus:outline-2 outline-sky-300 pl-16 pr-2 py-2 border-transparent rounded-md appearance-none"
            />
            <input
              @change="errorEncaissement = false"
              type="date"
              :disabled="reglementTab[index].disabled"
              v-model="reglementTab[index].date"
              :min="todayDate"
              class="ml-2 focus:outline-2 outline-sky-300 w-2/5 px-5 py-2 border-transparent rounded-md appearance-none"
            />
            <span
              v-if="!reglementTab[index].disabled"
              class="w-1/5 px-5 py-2 border-transparent rounded-md appearance-none"
            >
              <span
                class="mt-2 material-icons-outlined text-red-600 cursor-pointer"
                @click="removeReglement(index)"
              >
                clear
              </span>
            </span>
          </div>

          <div class="relative mt-3" v-show="errorPaymentMethod">
            <label class="text-xs font-medium text-red-500"
              >Cette méthode de paiement n'existe pas</label
            >
          </div>
          <div class="relative mt-3" v-show="errorEncaissement">
            <label class="text-xs font-medium text-red-500"
              >Veuillez encaisser la facture</label
            >
          </div>
          <div class="relative mt-3 mb-12">
            <label class="text-l font-medium"
              >Reste dû : {{ financial(setRestant) }} €</label
            >
            <label class="text-xs"> - </label>
            <label class="text-l font-medium"
              >A rendre : {{ financial(setRendu) }} €</label
            >
          </div>
          <div class="relative rounded-md">
            <span
              class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
            >
              <span class="material-icons-round"> subtitles </span>
            </span>

            <input
              type="text"
              class="block px-2.5 pb-2.5 pt-4 pl-10 w-[42rem] text-sm text-gray-900 bg-white rounded-lg border-2 border-sky-200 appearance-none focus:outline-none focus:ring-0 focus:border-sky-300 peer cursor-pointer shadow-lg"
              v-model="commentaireEncaissement"
            /><label
              class="ml-1 absolute text-base font-bold text-gray-600 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-50 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >Commentaire interne</label
            >
          </div>
        </div>
        <!--Footer-->
        <div class="flex justify-end pt-2 mt-2">
          <button
            @click="(openSave = false), $emit('closeModalEncaissement', false)"
            class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-gray-100 rounded-lg hover:bg-red-500 hover:text-white focus:outline-none shadow-md"
          >
            Fermer
          </button>
          <button
            @click="emitEncaissement()"
            class="cursor-pointer px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-400 focus:outline-none shadow-md"
            :disabled="loading"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { ORDER_KEYS } from "@/util/OrderSchemaConstants.js";

export default {
  name: "MyEncaissement",
  props: [
    "showModal",
    "infoClient",
    "setTotalTTC",
    "reglement",
    "infoCreatedBy",
  ],
  data() {
    return {
      ORDER_KEYS,

      openSave: false,
      commentaireEncaissement: "",
      reglementTab: [],
      moyenPaiement: [
        {
          type: "Espèce",
          abbrev: "es",
          image: require("@/assets/icons_payments/ESP.png"),
        },
        {
          type: "Carte Bancaire",
          abbrev: "cb",
          image: require("@/assets/icons_payments/CB.png"),
        },
        {
          type: "Chèque Comptant",
          abbrev: "cc",
          image: require("@/assets/icons_payments/CHQ.png"),
        },
        {
          type: "LCR",
          abbrev: "lc",
          image: require("@/assets/icons_payments/LCR.png"),
        },
        {
          type: "Virement",
          abbrev: "vi",
          image: require("@/assets/icons_payments/Virement.png"),
        },
      ],
      errorPaymentMethod: false,
      loading: false,
      createdBy: "",
      clientChoosed: {},
      inputClient: "",
      showClientList: false,
      loadingSelection: false,

      showConfirmationModal: false,
      selectedClient: null,
      selectedClientTMP: null,
      originalGroupState: null,
      clickedIndexes: [],
      configurationConditionList: [],

      showDeleteClientPar: false,
      showEditClientPar: false,
    };
  },
  computed: {
    ...mapGetters(["user", "plateform", "cmdPreparation"]),
    setRestant() {
      var restant = 0;
      var paye = 0;
      for (const element of this.reglementTab) {
        paye = paye + parseFloat(element.model);
      }
      restant = parseFloat(this.setTotalTTC) - parseFloat(paye);
      if (restant < 0) {
        restant = 0;
      }
      return parseFloat(restant).toFixed(2);
    },
    setRendu() {
      var rendu = 0;
      var paye = 0;
      for (const element of this.reglementTab) {
        paye = paye + parseFloat(element.model);
      }
      if (paye > this.setTotalTTC) {
        rendu = Math.abs(paye - this.setTotalTTC);
      }
      return rendu;
    },
  },
  methods: {
    setReglement(method) {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      switch (method) {
        case "es":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/ESP.png"),
            icon: "payments",
            placeholder: "Espèce",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cb":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/CB.png"),
            icon: "credit_card",
            placeholder: "Carte Bancaire",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cc":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/CHQ.png"),
            icon: "money",
            placeholder: "Chèque Comptant",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "vi":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/Virement.png"),
            icon: "account_balance",
            placeholder: "Virement",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "lc":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/LCR.png"),
            icon: "account_balance",
            placeholder: "LCR",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        default:
          this.errorPaymentMethod = true;
          break;
      }
    },
    removeReglement(index) {
      this.reglementTab.splice(index, 1);
    },
    async emitEncaissement() {
      this.loading = true;
      this.reglementTab.push({
        icon: "money",
        placeholder: "Reste dû",
        model: parseFloat(this.setRestant).toFixed(2),
        date: new Date(),
        disabled: true,
        user: this.user.username,
      });

      this.$emit("emitEncaissement", this.reglementTab, this.clientChoosed);
      this.$emit("closeModalEncaissement", false);
      this.loading = false;
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
    handleOuiClick() {
      this.isProcessing = true;
      this.$emit("close");
      this.$emit("closeModalSelectClientSession", "valider");
      this.isProcessing = false;
      this.showPaymentModal = false;
    },
    handleInput() {
      if (this.inputClient === "") {
        this.showClientList = true;
        this.getFavori(false);
      } else {
        this.searchClientFav(this.inputClient, false);
      }
    },
    async searchClient() {
      if (this.Clients.length > 0) {
        this.inputClient = this.Clients[0].Name;
      } else {
        await this.newClient();
      }
    },
    async newClient() {
      this.showClientPar = true;
    },
    suppClient(client) {
      this.clientSelectedToModify = client;
      this.showDeleteClientPar = true;
    },
    confirmDelete() {
      this.deleteClient();
      this.showDeleteClientPar = false;
    },
    deleteClient() {
      axios
        .put(
          `${process.env.VUE_APP_API}/client/removeClient/${this.clientSelectedToModify._id}`,
          this.clientSelectedToModify
        )
        .then(() => {
          if (this.inputClient && this.inputClient.trim() !== "") {
            this.searchClientFav(this.inputClient, false);
          } else {
            this.getFavori(false);
          }

          this.toast.success("Client supprimé !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    editClient(client) {
      this.clientSelectedToModify = client;
      this.showEditClientPar = true;
    },
    getFavori(cdtnCl) {
      axios
        .post(`${process.env.VUE_APP_API}/client/favoriClient/get`, {
          plateform: this.user.proprietaire,
          pro: cdtnCl,
        })
        .then((res) => {
          this.Clients = res.data;
        });
    },
    searchClientFav: function (searchTerm, pro) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        if (searchTerm) {
          axios
            .post(`${process.env.VUE_APP_API}/client/searchClientFav`, {
              plateform: this.user.proprietaire,
              searchTerm: searchTerm,
              pro: pro,
              archived: false,
            })
            .then((res) => {
              this.Clients = res.data;
              this.clickedIndexes = [];
              this.originalGroupState = null;
            });
        } else {
          this.getFavori(this.cdtnClient);
        }
      }, 300); // 300ms
    },
    async updateSessionClient(client) {
      if (!client._id.startsWith("inconnu")) {
        let clientChoosed = client;
        this.loadingSelection = true;
        let response = await axios.post(
          `${process.env.VUE_APP_API}/client/getByID`,
          {
            _id: clientChoosed._id,
          }
        );

        if (response.data) {
          this.clientChoosed = response.data;
        }
        this.loadingSelection = false;
      } else {
        this.toast.warning("Aucun client sélectionné !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async getConfigurationCondition() {
      const condition = await axios.get(
        `${process.env.VUE_APP_API}/configurationCondition/${this.user.proprietaire}`
      );
      this.configurationConditionList = condition.data;
    },
    confirmGroupChange(client, index) {
      this.selectedClient = client;
      this.showConfirmationModal = true;
    },
    async saveModifierGroupClient() {
      const updatedData = {
        Group: {
          Name: this.selectedClient.Group.Name,
        },
        updated_by: this.user.username,
      };
      axios
        .put(
          `${process.env.VUE_APP_API}/client/modifyClient/${this.selectedClient._id}`,
          {
            data: updatedData,
          }
        )
        .then(() => {
          this.toast.success("Modifié !", {
            position: "bottom-right",
            timeout: 1000,
          });
          this.clickedIndexes = [];
          this.originalGroupState = null;
          this.showConfirmationModal = false;
        })
        .catch((error) => {
          this.toast.error("Erreur lors de la modification !", {
            position: "bottom-right",
          });
          console.error("erreur :", error);
          this.showConfirmationModal = false;
        });
    },
    cancelGroupChange() {
      this.showConfirmationModal = false;
      this.Clients = this.originalGroupState;
      this.clickedIndexes = [];
      this.originalGroupState = null;
      this.selectedClient = null;
    },
    saveGroupBeforeEdit(client, index) {
      if (!this.clickedIndexes.some((item) => item.index === index)) {
        this.originalGroupState = JSON.parse(JSON.stringify(this.Clients));
        this.clickedIndexes.push({
          index: index,
          Group: client.Group.Name,
        });
      }
    },
  },
  beforeMount() {
    this.clientChoosed = this.infoClient;
    this.createdBy = this.infoCreatedBy;
    this.openSave = this.showModal;
    this.reglementTab = this.reglement
      .filter((item) => item.placeholder !== "Reste dû")
      .map((item) => {
        // Reformater la date
        const dateObj = new Date(item.date);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Mois commence à 0
        const day = dateObj.getDate().toString().padStart(2, "0");

        return { ...item, date: `${year}-${month}-${day}`, disabled: true }; // Ajouter disabled: true à chaque élément
      });
    this.getConfigurationCondition();
  },
};
</script>
