<template>
  <div class="flex justify-between mb-10">
    <div id="retour" class="flex justify-start">
      <backButton :route="'/configurationCatalogueHub'" />
    </div>
    <titrePages
      class="flex flex-1 justify-center"
      :title="'Configuration du catalogue de pneus'"
    />
  </div>
  <div class="flex justify-center"><menuConfiguration /></div>
  <div class="mt-2 space-y-4">
    <input
      type="text"
      v-model="searchMarque"
      placeholder="Search"
      class="focus:outline-2 outline-sky-300 border rounded-md shadow mb-1 px-1"
    />
    <div>
      <span
        class="p-1 bg-blue-500 text-white rounded shadow cursor-pointer"
        @click="initBrandPneu()"
        >Rechercher des marques</span
      >
    </div>
    <div class="grid grid-cols-7 gap-5" v-if="brandPneuList.length">
      <div
        v-for="(MA, index) in filteredMarque"
        :key="index"
        class="bg-white rounded flex flex-row items-center justify-between p-1"
      >
        <span class="ml-1 cursor-pointer">
          {{ MA }}
        </span>
        <span
          class="material-icons text-green-500 cursor-pointer"
          v-if="checkedMarques.includes(MA)"
          @click="suppBrandTocheckedMarques(MA)"
        >
          check_circle
        </span>
        <span
          class="material-icons text-red-500 cursor-pointer"
          @click="addBrandTocheckedMarques(MA)"
          v-else
        >
          check_circle
        </span>
        <span
          class="material-icons-round p-1 bg-red-500 text-white rounded-full cursor-pointer"
          @click="suppBrandToHide(MA)"
          v-if="brandPneuListToHide.includes(MA)"
        >
          search
        </span>
        <span
          class="material-icons-round p-1 bg-green-500 text-white rounded-full cursor-pointer"
          @click="addBrandToHide(MA)"
          v-else
        >
          search
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";
import backButton from "@/components/Button/back.vue";
import menuConfiguration from "@/components/Menu/menuConfiguration.vue";
import titrePages from "@/components/Text/grandeTitre.vue";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      brandPneuList: [],
      brandPneuListToHide: [],
      checkedMarques: [],
      searchMarque: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),
    filteredMarque() {
      if (this.searchMarque) {
        return this.brandPneuList.filter((item) => {
          return item.toUpperCase().startsWith(this.searchMarque.toUpperCase());
        });
      } else {
        return this.brandPneuList;
      }
    },
  },
  methods: {
    getBrandPneuList() {
      axios
        .get(
          `${process.env.VUE_APP_API}/configurationBrandPneu/${this.user.proprietaire}`
        )
        .then((response) => {
          this.brandPneuList = response.data.brandList;
          this.brandPneuListToHide = response.data.brandPneuListToHide;
          this.checkedMarques = response.data.checkedMarques;
        });
    },
    initBrandPneu() {
      axios
        .post(
          `${process.env.VUE_APP_API}/configurationBrandPneu/initBrandPneu`,
          {
            plateform: this.user.proprietaire,
          }
        )
        .then((response) => {
          this.getBrandPneuList();
        });
    },
    saveBrandList() {
      axios.post(
        `${process.env.VUE_APP_API}/configurationBrandPneu/saveBrandPneu`,
        {
          plateform: this.user.proprietaire,
          brandPneuListToHide: this.brandPneuListToHide,
          checkedMarques: this.checkedMarques,
        }
      );
    },
    addBrandToHide(brand) {
      this.brandPneuListToHide.push(brand);
      this.saveBrandList();
    },
    addBrandTocheckedMarques(brand) {
      this.checkedMarques.push(brand);
      this.saveBrandList();
    },
    suppBrandToHide(brand) {
      this.brandPneuListToHide.splice(
        this.brandPneuListToHide.indexOf(brand),
        1
      );
      this.saveBrandList();
    },
    suppBrandTocheckedMarques(brand) {
      this.checkedMarques.splice(this.checkedMarques.indexOf(brand), 1);
      this.saveBrandList();
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.getBrandPneuList();
  },
  components: {
    backButton,
    menuConfiguration,
    titrePages,
  },
};
</script>
<style></style>
