<template>
  <div>
    <div
      v-if="showFile"
      :class="`modal ${
        !showFile && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="closeImport()"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 mx-auto bg-white rounded shadow-lg modal-container max-h-128 max-w-8xl"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Tableau de condition importé</p>
            <div class="z-50 cursor-pointer modal-close" @click="closeImport()">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div
            class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
            v-show="showFileContent"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Marque
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Designation
                    </th>
                    <!-- <th
                  class="px-2 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Code Remise
                </th> -->
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Famille
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Remise
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Pro 1
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Pro 2
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Pro 3
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Part 1
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Part 2
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Part 3
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Status
                    </th>
                    <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
                  <tr v-for="(readCondi, index) in readFile" :key="index">
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[0] }}
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap text-xs"
                    >
                      <!-- <span class="w-1/4">{{ readCondi[1] }}</span> -->
                      <input
                        type="text"
                        class="w-56"
                        v-model="readFile[index][1]"
                      />
                    </td>

                    <!-- <td
                  class="px-2 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                >
                  {{ condi.code }}
                </td> -->

                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[2] }}
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      <div class="w-24">type : {{ readCondi[3] }}</div>
                      <div class="w-24">{{ readCondi[4] }} %</div>
                    </td>
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      <div class="w-24">{{ readCondi[5] }} %</div>
                    </td>
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      <div class="w-24">{{ readCondi[6] }} %</div>
                    </td>
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      <div class="w-24">{{ readCondi[7] }} %</div>
                    </td>
                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                      style="width: 8%"
                    >
                      <div class="w-24">{{ readCondi[8] }} %</div>
                    </td>
                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                      style="width: 8%"
                    >
                      <div class="w-24">{{ readCondi[9] }} %</div>
                    </td>
                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                      style="width: 8%"
                    >
                      <div class="w-24">{{ readCondi[10] }} %</div>
                    </td>
                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                      style="width: 8%"
                    >
                      <span
                        class="p-1 bg-green-500 text-white text-base rounded"
                        >Actif</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex justify-end mt-4 mb-2" v-show="showFileContent">
            <button
              class="p-2 bg-blue-500 text-white rounded"
              @click="importExcel()"
            >
              Importer
            </button>
          </div>
          <div v-show="importLoading">
            <div class="animate-pulse">Importation en cours...</div>
          </div>
          <div v-show="showFinished">
            <div>Importation réussie !</div>
            <div class="flex justify-end">
              <button
                class="p-2 bg-blue-500 text-white rounded"
                @click="closeImport()"
              >
                Fermer
              </button>
            </div>
          </div>

          <!-- End Body -->
        </div>
      </div>
    </div>
    <div class="flex">
      <div id="retour" class="flex justify-start">
        <backButton />
      </div>
      <titrePages
        class="flex flex-1 justify-center"
        :title="'Conditions fournisseurs'"
      />
      <div>
        <button
          v-if="!loadingExport"
          class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="exportExcel()"
        >
          <span class="material-icons-outlined text-xl mr-2"> download </span>
          <span>Exporter en Excel</span>
        </button>
        <button
          v-else
          class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
        >
          <PulseLoader color="#ffff" class="mr-2" />
          <span>Export en cours </span>
        </button>
      </div>
    </div>
    <div class="flex justify-between mt-8">
      <div id="Recherche" class="w-96">
        <label
          for="default-search"
          class="mb-2 text-sm font-medium text-gray-900 sr-only"
          >Search</label
        >
        <div class="relative">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            id="default-search"
            list="defaultSearch"
            v-model="searchInFournisseurList"
            class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-sky-300 outline-2"
            placeholder="Rechercher dans la liste"
            required
          />
          <datalist id="defaultSearch">
            <option
              v-for="(c, index) in fournisseurList"
              :key="index"
              :value="c.CompanyName"
            ></option>
          </datalist>
          <button
            @click="searchOneFournisseur()"
            class="text-white absolute right-2.5 bottom-2.5 bg-sky-700 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
          >
            Rechercher
          </button>
        </div>
      </div>
      <div class="flex flex-row justify-end space-x-2">
        <span
          class="material-icons-round text-blue-500 cursor-pointer"
          @click="getCondition()"
        >
          refresh
        </span>
        <span
          class="material-icons-round text-blue-500 cursor-pointer"
          @click="showManualAdd = !showManualAdd"
        >
          add_circle
        </span>
        <label for="dropzone-file">
          <span class="material-icons-round text-blue-500 cursor-pointer">
            upload_file
          </span>
          <input
            id="dropzone-file"
            type="file"
            class="hidden"
            accept=".xlsx"
            @change="readExcel()"
          />
        </label>
      </div>
    </div>
    <div
      class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Marque
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Designation
              </th>
              <!-- <th
                  class="px-2 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Code Remise
                </th> -->
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Code
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Famille
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Remise
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Pro 1
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Pro 2
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Pro 3
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Part 1
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Part 2
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Part 3
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Status
              </th>
              <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
            </tr>
          </thead>

          <tbody class="bg-white">
            <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
            <tr v-for="(condi, index) in conditionList" :key="index">
              <td
                class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <input
                  list="brow2"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].fournisseur"
                  class="w-28 block w-full focus:outline-2 outline-sky-300 px-2 py-2 text-sm text-gray-700 placeholder-gray-400 bg-white rounded-l rounded-r appearance-none focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none hover:bg-gray-300"
                />
                <datalist id="brow2">
                  <option
                    v-for="(c, index) in fournisseurList"
                    :key="index"
                    :value="c.CompanyName"
                  ></option>
                </datalist>
              </td>

              <td
                class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap text-xs"
              >
                <input
                  type="text"
                  class="w-56"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].designation"
                />
              </td>

              <!-- <td
                  class="px-2 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                >
                  {{ condi.code }}
                </td> -->

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div
                  class="flex flex-col"
                  v-if="
                    !conditionList[index].familleFournisseur &&
                    !conditionList[index].famille
                  "
                >
                  <span class="material-icons-round text-xs text-red-500">
                    warning
                  </span>
                </div>
                <div>
                  <input
                    type="text"
                    class="w-5/6"
                    @change="updateCondtion(condi._id, condi)"
                    v-model="conditionList[index].familleFournisseur"
                  />
                </div>
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div
                  class="flex flex-col"
                  v-if="
                    !conditionList[index].familleFournisseur &&
                    !conditionList[index].famille
                  "
                >
                  <span class="material-icons-round text-xs text-red-500">
                    warning
                  </span>
                </div>
                <div>
                  <input
                    list="fami"
                    v-model="conditionList[index].famille"
                    @change="updateCondtion(condi._id, condi)"
                    class="w-28 block text-xs w-full focus:outline-2 outline-sky-300 px-2 py-2 text-sm text-gray-700 placeholder-gray-400 bg-white rounded-l rounded-r appearance-none focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none hover:bg-gray-300"
                  />
                  <datalist id="fami">
                    <option
                      v-for="(f, index) in familleList"
                      :key="index"
                      :value="f.Name"
                    ></option>
                  </datalist>
                </div>
              </td>

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].remiseFournisseur"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].T1"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].T2"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].T3"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].P1"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].P2"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].P3"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <div class="flex flex-row space-x-1">
                  <div>
                    <span
                      v-if="conditionList[index].actif"
                      @click="
                        updateCondtionActif(condi._id, condi, index, false)
                      "
                      class="p-1 bg-green-500 text-white text-xs rounded cursor-pointer"
                      >Actif</span
                    ><span
                      v-else
                      @click="
                        updateCondtionActif(condi._id, condi, index, true)
                      "
                      class="p-1 bg-red-500 text-white text-xs rounded cursor-pointer"
                      >Inactif</span
                    >
                  </div>

                  <div>
                    <span
                      class="p-1 bg-blue-500 text-white rounded shadow text-xs cursor-pointer"
                      @click="
                        updateCondtionType(condi._id, condi, index, condi.type)
                      "
                      >{{ conditionList[index].type }}</span
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody class="bg-white" v-show="showManualAdd">
            <tr>
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <input
                  list="brow3"
                  v-model="addFournisseur"
                  placeholder="Fournisseur"
                  class="w-28 block w-full focus:outline-2 outline-sky-300 px-2 py-2 text-sm text-gray-700 placeholder-gray-400 bg-white rounded-l rounded-r appearance-none focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none hover:bg-gray-300"
                />
                <datalist id="brow3">
                  <option
                    v-for="(c, index) in fournisseurList"
                    :key="index"
                    :value="c.CompanyName"
                  ></option>
                </datalist>
              </td>

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 18%"
              >
                <input
                  type="text"
                  placeholder="Désignation"
                  v-model="addDesignation"
                />
              </td>
              <!-- 
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style="width: 12%"
                >
                  <input
                    type="text"
                    placeholder="Code remise"
                    v-model="addCode"
                  />
                </td> -->

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  type="text"
                  placeholder="Famille Fournisseur"
                  v-model="addFamilleFournisseur"
                />
              </td>

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addRemise"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addT1"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addT2"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addT3"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addP1"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addP2"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addP3"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <span>Actif</span>
                <span>Inactif</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="flex justify-end" v-show="showManualAdd">
    <button
      class="px-6 py-3 mt-4 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
      @click="postCondition()"
    >
      ajouter
    </button>
  </div>
  <div class="flex flex-col mt-2" v-if="amountLines > 10">
    <div class="mx-auto">
      <span class="text-sm"
        >{{ activePage }} sur {{ parseInt(pageAmount) }} pour
        {{ amountLines }} Lignes</span
      >
    </div>
    <div class="inline-flex mt-2 xs:mt-0 mx-auto">
      <button
        class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-l hover:bg-gray-400"
        @click="pageDown()"
      >
        Précédent
      </button>
      <div
        v-for="(page, index) in paginateArray"
        :key="index"
        @click="getCondition(page.pageNumber, 10)"
      >
        <button
          v-if="page.pageNumber <= pageAmount"
          class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
        >
          <span v-if="page.pageNumber == activePage" class="text-blue-500">{{
            page.pageNumber
          }}</span>
          <span v-else>{{ page.pageNumber }}</span>
        </button>
      </div>
      <button
        class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-r hover:bg-gray-400"
        @click="pageUp()"
      >
        Suivant
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import readXlsxFile from "read-excel-file";
import backButton from "@/components/Button/back.vue";
import * as XLSX from "xlsx";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  name: "MyFournisseurs",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      lock: true,
      showManualAdd: false,
      showEdit: true,
      showFile: false,
      showFileContent: true,
      importLoading: false,
      showFinished: false,
      conditionList: [],
      fournisseurList: [],
      familleList: [],
      addFournisseur: "",
      addDesignation: "",
      addFamilleFournisseur: "",
      addCode: "",
      addRemise: 0,
      addT1: 0,
      addT2: 0,
      addT3: 0,
      addP1: 0,
      addP2: 0,
      addP3: 0,
      addActif: true,
      file: null,
      readFile: null,
      searchInFournisseurList: "",
      activePage: 1,
      pageAmount: 0,
      amountLines: 0,
      paginateArray: [],
      paginateNextStep: 11,
      startPaginate: 1,
      pageStart: 1,
      pageNumber: 0,
      pageNext: 11,

      allConditionList: [],
      loadingExport: false,
    };
  },
  components: {
    backButton,
    PulseLoader,
    titrePages,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.fournisseurList = response.data;
        });
    },
    getFamilleArticle() {
      axios
        .get(`${process.env.VUE_APP_API}/sousfamillearticle`)
        .then((response) => {
          this.familleList = response.data;
        });
    },
    async getAllCondition() {
      await axios
        .post(
          `${process.env.VUE_APP_API}/conditionFournisseur/getAllCondition`,
          {
            plateform: this.user.proprietaire,
          }
        )
        .then((response) => {
          console.log(response);
          this.allConditionList = response.data;
          // this.initPaginateArray();
        });
    },
    getCondition(skipValue) {
      axios
        .post(`${process.env.VUE_APP_API}/conditionFournisseur/getAll`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
        })
        .then((response) => {
          console.log(response);
          this.conditionList = response.data.data;
          this.pageAmount = response.data.page;
          this.amountLines = response.data.count;
          // this.initPaginateArray();
        });
    },
    pageUp() {
      if (this.pageNext <= this.pageAmount) {
        this.pageStart = this.pageNext;
        this.paginateArray.forEach((element, index) => {
          this.paginateArray[index] = { pageNumber: this.pageNext };
          this.pageNext = this.pageNext + 1;
        });
      }
    },
    pageDown() {
      if (this.pageNext != 11) {
        this.pageNext = this.pageNext - 20;
        this.pageStart = this.pageNext;
        this.paginateArray.forEach((element, index) => {
          this.paginateArray[index] = { pageNumber: this.pageNext };
          this.pageNext = this.pageNext + 1;
        });
      }
    },
    initPaginateArray() {
      this.paginateArray = new Array(10);
      this.paginateArray = Array.from(this.paginateArray);
      var i = 1;
      this.paginateArray.forEach((element, index) => {
        this.paginateArray[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    postCondition() {
      // if (this.addFournisseur && this.addDesignation && this.addCode) {
      if (this.addFournisseur && this.addDesignation) {
        axios
          .post(`${process.env.VUE_APP_API}/conditionFournisseur`, {
            fournisseur: this.addFournisseur,
            plateform: this.user.proprietaire,
            // code: this.addCode,
            designation: this.addDesignation,
            familleFournisseur: this.addFamilleFournisseur,
            remiseFournisseur: this.addRemise,
            T1: this.addT1,
            T2: this.addT2,
            T3: this.addT3,
            P1: this.addP1,
            P2: this.addP2,
            P3: this.addP3,
            actif: this.addActif,
          })
          .then(() => {
            this.getCondition();
            this.showManualAdd = false;
            this.success("La ligne a bien été ajouté");
          })
          .catch((err) => {
            this.error(err);
          });
      } else {
        this.error("Veuillez remplir les champs");
      }
    },
    updateCondtion(id, data) {
      axios.put(`${process.env.VUE_APP_API}/conditionFournisseur/${id}`, data);
    },
    updateCondtionActif(id, data, index, state) {
      this.conditionList[index].actif = state;
      axios.put(`${process.env.VUE_APP_API}/conditionFournisseur/${id}`, data);
    },
    updateCondtionType(id, data, index, type) {
      if (type == "remise") {
        this.conditionList[index].type = "prixnet";
      } else {
        this.conditionList[index].type = "remise";
      }
      axios.put(`${process.env.VUE_APP_API}/conditionFournisseur/${id}`, data);
    },
    async readExcel() {
      this.file = event.target.files ? event.target.files[0] : null;
      this.readFile = await readXlsxFile(this.file);
      this.readFile.splice(0, 1);
      this.showFile = true;
    },
    closeImport() {
      this.showFile = false;
      this.readFile = null;
      this.file = null;
      this.showFinished = false;
      this.showFileContent = true;
      this.getCondition();
    },
    importExcel() {
      this.importLoading = true;
      this.showFileContent = false;
      axios
        .post(`${process.env.VUE_APP_API}/conditionFournisseur/importExcel`, {
          plateform: this.user.proprietaire,
          data: this.readFile,
        })
        .then(() => {
          this.importLoading = false;
          this.showFinished = true;
        });
    },
    searchOneFournisseur() {
      if (this.searchInFournisseurList != "") {
        axios
          .post(`${process.env.VUE_APP_API}/conditionFournisseur/getOne`, {
            plateform: this.user.proprietaire,
            fournisseur: this.searchInFournisseurList,
          })
          .then((response) => {
            this.conditionList = response.data.data;
          });
      } else {
        // this.getCondition();
        this.error("Veuillez choisir un fournisseur");
      }
    },
    async exportExcel() {
      this.loadingExport = true;
      await this.getAllCondition();

      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(this.allConditionList);
      XLSX.utils.book_append_sheet(wb, ws, "Conditions");

      XLSX.writeFile(
        wb,
        "Liste_Conditions_" + this.user.proprietaire + ".xlsx"
      );
      this.loadingExport = false;

      this.success("Le fichier a été téléchargé");
    },
    success(message) {
      this.toast.success(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.warning(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.getCondition(1, 10);
    this.initPaginateArray();
    this.getFournisseur();
    this.getFamilleArticle();
  },
};
</script>
<style scoped>
.bg-vk {
  background-color: #2262b3;
}
</style>
