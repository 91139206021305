<template>
  <div class="flex text-gray-700 flex-row mx-auto mb-8">
    <div
      class="flex text-gray-700 text-xl md:text-3xl md:font-medium font-bold flex-row my-2 mx-auto sm: text-bluevk"
    >
      <div class="p-1">Statistiques</div>
    </div>
  </div>
  <div>
    <div class="grid grid-cols-2 gap-4">
      <div class="flex flex-col space-y-2 bg-white rounded shadow p-2">
        <div class="flex justify-between">
          <h2>Bon de livraison en cours</h2>
          <router-link to="/StatsBl">
            <span
              class="material-icons-outlined ml-1 text-blue-500"
              title="Voir détails Bl"
            >
              info
            </span>
          </router-link>
        </div>
        <div v-if="bdl && bdl.data">Total : {{ bdl.data.global }} €</div>
        <div id="chartbdl">
          <apexchart
            type="pie"
            :options="chartOptionsbdl"
            :series="seriesbdl"
          ></apexchart>
        </div>
      </div>
      <div class="flex flex-col space-y-2 bg-white rounded shadow p-2">
        <div class="flex justify-between">
          <h2>Factures</h2>
          <router-link to="/StatsFactures">
            <span
              class="material-icons-outlined ml-1 text-blue-500"
              title="Voir détails factures"
            >
              info
            </span>
          </router-link>
        </div>
        <div v-if="factureAnnuelle && factureAnnuelle.data">
          Total : {{ factureAnnuelle.data.global }} €
        </div>
        <div id="chartFacture">
          <apexchart
            type="pie"
            :options="chartOptionsFacture"
            :series="seriesFacture"
          ></apexchart>
        </div>
      </div>
      <div class="flex flex-col space-y-2 bg-white rounded shadow p-2">
        <div class="flex justify-between">
          <h2>Top 10 Articles</h2>
          <router-link to="/StatsTopArticles">
            <span
              class="material-icons-outlined ml-1 text-blue-500"
              title="Voir détails Top Articles"
            >
              info
            </span>
          </router-link>
        </div>
        <div v-if="!loadingTopArticle" id="chartVArticle">
          <apexchart
            type="bar"
            :options="chartOptionsVArticle"
            :series="seriesVArticle"
          ></apexchart>
        </div>
        <div v-else>Chargement...</div>
      </div>
      <div class="flex flex-col space-y-2 bg-white rounded shadow p-2">
        <div class="flex justify-between">
          <h2>Recherches</h2>
          <router-link to="/StatsRecherches">
            <span
              class="material-icons-outlined ml-1 text-blue-500"
              title="Voir détails Stock"
            >
              info
            </span>
          </router-link>
        </div>
        <div class="flex flex-col space-y-1">
          <div
            class="flex flex-row items-center space-x-2"
            v-for="(ls, indexLS) in lastSearch"
            :key="indexLS"
          >
            <div>
              <span v-if="ls.type == 'search'">Recherche</span>
              <span v-else-if="ls.type == 'catalogue_piece_F'"
                >Catalogue Famille</span
              >
              <span v-else-if="ls.type == 'catalogue_piece_SF'"
                >Catalogue Sous Famille</span
              >
            </div>
            <span>{{ ls.Search }}</span>
            <span>{{ ls.Client }}</span>
            <span>{{ ls.User }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import { getLastFive } from "@/hooks/statistiques/savedSearch.ts";
export default {
  name: "MyStatistique",
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      bdl: [],
      factureAnnuelle: [],
      loadingTopArticle: true,
      seriesbdl: [],
      chartOptionsbdl: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      seriesFacture: [],
      chartOptionsFacture: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      seriesVArticle: [
        {
          data: [],
        },
      ],
      chartOptionsVArticle: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      lastSearch: [],
    };
  },
  components: {
    apexchart: VueApexCharts,
  },
  methods: {
    getBdls() {
      this.seriesbdl = [];
      this.chartOptionsbdl.labels = [];
      axios
        .post(`${process.env.VUE_APP_API}/venteStat/getBdls`, {
          plateform: this.user.proprietaire,
        })
        .then((res) => {
          this.bdl = res.data;
          for (const cltBdl of res.data.data.parClient) {
            this.seriesbdl.push(cltBdl.total);
            this.chartOptionsbdl.labels.push(
              cltBdl.Client + " : " + parseFloat(cltBdl.total).toFixed(2) + " €"
            );
          }
        });
    },
    getFactures() {
      this.seriesFacture = [];
      this.chartOptionsFacture.labels = [];
      axios
        .post(`${process.env.VUE_APP_API}/venteStat/getFactures`, {
          plateform: this.user.proprietaire,
        })
        .then((res) => {
          this.factureAnnuelle = res.data;
          for (const cltFacture of res.data.data.parClient) {
            console.log(cltFacture.Client);
            this.seriesFacture.push(cltFacture.total);
            this.chartOptionsFacture.labels.push(
              cltFacture.Client +
                " : " +
                parseFloat(cltFacture.total).toFixed(2) +
                " €"
            );
          }
        });
    },
    async getTopArticleFactures() {
      this.loadingTopArticle = true;
      await axios
        .post(`${process.env.VUE_APP_API}/venteStat/getTopArticleFactures`, {
          plateform: this.user.proprietaire,
        })
        .then((res) => {
          console.log(res.data);
          this.seriesVArticle[0].data = [];
          this.chartOptionsVArticle.xaxis.categories = [];
          for (const art of res.data) {
            this.seriesVArticle[0].data.push(art.total);
            this.chartOptionsVArticle.xaxis.categories.push(
              art.Ref_fournisseur
            );
          }
          this.loadingTopArticle = false;
        });
    },
    async getLastSearch() {
      this.lastSearch = await getLastFive(this.user.proprietaire);
    },
  },
  beforeMount() {
    if (this.user.accountType != "Administrateur") {
      this.$router.push("/statistics");
    }
    this.getBdls();
    this.getFactures();
    this.getTopArticleFactures();
    this.getLastSearch();
  },
};
</script>
