import { String } from "core-js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import store from "../../store";
import { getFrDate } from "@/hooks/tools/date.ts";

export async function printGroupedBT(objet) {
  var today = new Date();
  var da = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  var hour = today.getHours();
  var minutes = today.getMinutes();

  // date d'édition
  var edition = [
    {
      text:
        "Edité le " + da + "/" + mm + "/" + yyyy + " à " + hour + ":" + minutes,
      style: "documentHeaderRight",
    },
  ];

  var poidstotal = 0;
  var co = [];
  objet.forEach(async (objetElement, index) => {
    // date de création
    var datepdf = "Le " + getFrDate(objet.Date) + ",";

    var tablesBT = [];
    var produits = [];
    var footerBody = {};

    objetElement.groupedTab.forEach((element1) => {
      produits.push([
        {
          text: "Qté",
          style: ["itemsHeader", "center"],
          border: [true, true, false, false],
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
          fillColor: "#EEEEEE",
        },
        {
          text: "Produit",
          style: "itemsHeader",
          border: [false, true, false, false],
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
          fillColor: "#EEEEEE",
        },
        {
          text:
            "Servi par : " +
            element1.Name +
            "            " +
            "réf. cl. : " +
            element1.Note,
          style: "itemsHeader",
          border: [false, true, false, false],
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
          fillColor: "#EEEEEE",
        },
        {
          text: "Poids",
          style: "itemsHeader",
          border: [false, true, false, false],
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
          fillColor: "#EEEEEE",
        },
      ]);
      element1.pdfData.forEach((element, index) => {
        if (parseInt(element1.Articles[index].Poids)) {
          poidstotal = poidstotal + parseInt(element1.Articles[index].Poids);
        }
        produits.push([
          {
            text: element.quantite,
            style: "itemNumber",
            border: [true, false, true, false],
            borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
            fillColor: "#FAFAFA",
          },
          {
            text:
              element.ref +
              "\n" +
              (objet.Articles[index]?.Code_marque?.charAt(0).toUpperCase() +
                objet.Articles[index]?.Code_marque?.slice(1).toLowerCase() ||
                ""),
            style: "itemTitle",
            border: [true, false, false, false],
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
            fillColor: "#F7F7F7",
          },
          {
            text:
              element.commentaire != ""
                ? element.description +
                  "\n \n" +
                  "Note : " +
                  element.commentaire
                : element.description,
            style: "itemTitle",
            border: [false, false, true, false],
            borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
            fillColor: "#F7F7F7",
          },
          {
            text:
              element1.Articles[index].Poids +
              " " +
              element1.Articles[index].Udm_poids,
            style: "itemTitle",
            border: [true, false, true, false],
            borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
            fillColor: "#FAFAFA",
          },
        ]);
      });
      tablesBT.push(
        {
          // text: "Servi par : " + element1.Name + "  RC : " + element1.Note,
          text: "BT n°" + element1.Numero + "  du " + getFrDate(element1.Date),
          style: "tableTitle",
        },
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            margin: [0, 2],
            widths: [30, 140, "*", 50],
            text: objetElement.clientInfo[0].nomcommercial,
            body: produits,
          },
        }
      );
      produits = [];
    });

    footerBody = {
      headerRows: 0,
      widths: [30, 140, "*", 50],

      body: [
        [
          {
            text: "",
            style: "itemsFooterSubTitle",
          },
          {
            text: "",
            style: "itemsFooterSubTitle",
          },
          {
            text: "Poids total: ",
            style: "itemsFooterSubTitle",
          },
          {
            text: poidstotal + " g",
            style: "itemsFooterSubValue",
          },
        ],
      ],
    };

    var nextPage1 = {
      table: footerBody,
      layout: "lightHorizontalLines",
    };

    var nextPage2 = { text: "" };

    if (index + 1 != objet.length) {
      nextPage2 = {
        text: "",
        pageBreak: "after",
      };
    }

    let infoCompClient = "";
    if (
      objetElement.clientInfo[0].professionnel == true &&
      objetElement.clientInfo[0].siret
    ) {
      infoCompClient =
        objetElement.clientInfo[0].siret +
        " - " +
        objetElement.clientInfo[0].numerotva +
        " - " +
        objetElement.clientInfo[0].nafape;
    }

    co.push([
      // Header
      {
        columns: [
          {
            image: "imgg",
            width: 100,
            height: 100,
          },
          // margin: [left, top, right, bottom]
          [
            {
              columns: [
                {
                  text: "Bon de transport clôturé",
                  style: "invoiceTitle",
                  margin: [0, 0, 0, 0],
                  width: 390,
                },
              ],
            },
            {
              columns: [
                {
                  text: "n°" + "100000" + objetElement.Numero,
                  style: "invoiceTitleDetail",
                  margin: [0, 0, 0, 0],
                  width: 390,
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: "",
                      style: "invoiceSubValue",
                      width: 180,
                    },
                    {
                      text: datepdf,
                      style: "invoiceSubValue",
                      margin: [0, 40, 0, 0],
                      width: 190,
                    },
                  ],
                },
              ],
            },
          ],
        ],
      },
      // Billing Headers
      {
        columns: [
          {
            text: store.state.plateform.Titre1 + "\n",
            style: "invoiceBillingTitle",
            width: 245,
            margin: [0, 5, 0, 0],
          },
          {
            text: objetElement.Client + "\n",
            style: "invoiceBillingTitle1",
            width: 245,
            margin: [25, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: store.state.plateform.Titre2 + "\n",
            style: "invoiceBillingSubTitle",
            width: 245,
            margin: [40, 0, 0, 0],
          },
          {
            text: objetElement.clientInfo[0].Adresse
              ? objetElement.clientInfo[0].Adresse
              : "",
            style: "invoiceBillingDetails",
            width: 210,
            margin: [24, -5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: store.state.plateform.Adresse,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 10, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 10, 0, 0],
          },
          {
            text: objetElement.clientInfo[0].Complement
              ? objetElement.clientInfo[0].Complement
              : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [-1, -9, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text:
              store.state.plateform.Code_postale +
              " " +
              store.state.plateform.Ville,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
          {
            text:
              objetElement.clientInfo[0].PostalCode &&
              objetElement.clientInfo[0].City
                ? objetElement.clientInfo[0].PostalCode +
                  " " +
                  objetElement.clientInfo[0].City
                : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, -20, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: store.state.plateform.Pays,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
          {
            text: objetElement.clientInfo[0].Country
              ? objetElement.clientInfo[0].Country
              : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, -20, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: store.state.plateform.Telephone,
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, 5, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingContactCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: objetElement.clientInfo[0].telephone
              ? objetElement.clientInfo[0].telephone
              : "",
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, -5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: store.state.plateform.Email,
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: objetElement.clientInfo[0].email
              ? objetElement.clientInfo[0].email
              : "",
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, -10, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text:
              store.state.plateform.Siret +
              " - " +
              store.state.plateform.Tva +
              " - " +
              store.state.plateform.Naf,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, 2, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: infoCompClient,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, -8, 0, 0],
          },
        ],
      },
      // Line breaks
      "\n",
      // Items
      tablesBT,
      "\n\n",
      nextPage1,
      nextPage2,
    ]);
  });

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  var dd = {
    header: {
      columns: edition,
    },
    footer: function (currentPage, pageCount) {
      return [
        {
          columns: [
            {
              text: `${store.state.plateform.Forme_sociale} ${store.state.plateform.Titre1} au capital de ${store.state.plateform.Capital} € dont le siège social est situé à ${store.state.plateform.Adresse} ${store.state.plateform.Code_postale} ${store.state.plateform.Ville}. \n En cas de retard de paiement, taux de penalité appliqué égal à 3 fois le taux d'intérêt en vigueur. Pas d'escompte en cas de paiement anticipé. Echange ou retour sous 15 jours.`,
              style: "documentFooterCenter",
            },
          ],
        },
        {
          columns: [
            {
              text: `Page ${currentPage} / ${pageCount}`,
              style: "documentFooterCenter1",
            },
          ],
        },
      ];
    },
    content: [co],
    images: {
      // imgg: 'http://vekteurbucket.s3.amazonaws.com/Logo/Gap.png',
      imgg: {
        url: `${store.state.plateform.lien_logo}`,
        headers: {
          "Cache-Control": "no-cache",
        },
      },
    },
    styles: {
      // Document Header
      documentHeaderRight: {
        fontSize: 10,
        margin: [0, 10, 15, 5],
        alignment: "right",
      },
      documentHeaderCenter: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "center",
      },
      // Document Footer
      documentFooterLeft: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "left",
      },
      documentFooterCenter: {
        fontSize: 6,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
      documentFooterCenter1: {
        fontSize: 9,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
      documentFooterRight: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
      },
      // Invoice Title
      invoiceTitle: {
        fontSize: 22,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      invoiceTitleDetail: {
        fontSize: 10,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      // Invoice Details
      invoiceSubValue: {
        fontSize: 9,
        alignment: "right",
      },
      // Billing Headers
      invoiceBillingTitle: {
        fontSize: 14,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingTitle1: {
        fontSize: 10,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle1: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      // Billing Details
      invoiceBillingDetails: {
        margin: [0, 0, 0, 0],
        fontSize: 11,
        alignment: "left",
      },
      invoiceBillingContactCompany: {
        fontSize: 8,
      },
      invoiceBillingDetailsCompany: {
        fontSize: 7,
      },
      // Items Header
      itemsHeader: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        bold: true,
        fillColor: "#CECECE",
      },
      // Item Title
      itemTitle: {
        fontSize: 8,
      },
      itemNumber: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        alignment: "center",
      },
      itemTotal: {
        margin: [0, 2, 0, 2],
        fontSize: 10,
        bold: true,
        alignment: "center",
      },
      tableTitle: {
        margin: [0, 2, 0, 0],
        fontSize: 8,
        alignment: "left",
      },
      // Items Footer (Subtotal, Total, Tax, etc)
      itemsFooterSubTitle: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "right",
      },
      itemsFooterSubValue: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "center",
      },
      itemsFooterTotalTitle: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterTotalValue: {
        margin: [-30, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterTotalValue1: {
        margin: [-10, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "right",
      },
      itemsFooterResteTitle: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
        bold: true,
        fillColor: "#CECECE",
      },
      itemsFooterReste: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
      },
      //signaturePlaceholder: {
      //    margin: [0,70,0,0],
      //},
      //signatureName: {
      //    bold: true,
      //    alignment:'center',
      //},
      //signatureJobTitle: {
      //    italics: true,
      //    fontSize: 10,
      //    alignment:'center',
      //},
      notesTitle: {
        fontSize: 10,
        bold: true,
        margin: [0, 50, 0, 3],
      },
      notesText: {
        fontSize: 10,
      },
      center: {
        alignment: "center",
      },
    },
    defaultStyle: {
      columnGap: 20,
    },
  };
  pdfMake.createPdf(dd).print();
}

export async function createGroupedBT(objet, duplicata) {
  var today = new Date();
  var da = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  var hour = today.getHours();
  var minutes = today.getMinutes();
  var edition =
    "Edité le " + da + "/" + mm + "/" + yyyy + " à " + hour + ":" + minutes;
  var datepdf = "Le " + getFrDate(objet.Date) + ",";

  let colDuplicata;
  if (duplicata) {
    colDuplicata = {
      columns: [
        {
          text: "Duplicata",
          style: "invoiceTitleDetail",
          margin: [0, 0, 0, 0],
          width: 390,
        },
      ],
    };
  }

  var poidstotal = 0;
  var co = [];

  var tablesBT = [];
  var produits = [];
  var footerBody = {};

  objet.groupedTab.forEach((element1) => {
    produits.push([
      {
        text: "Qté",
        style: ["itemsHeader", "center"],
        border: [true, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text: "Produit",
        style: "itemsHeader",
        border: [false, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text:
          "Servi par : " +
          element1.Name +
          "            " +
          "réf. cl. : " +
          element1.Note,
        style: "itemsHeader",
        border: [false, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text: "Poids",
        style: "itemsHeader",
        border: [false, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
    ]);
    element1.pdfData.forEach((element, index) => {
      if (parseInt(element1.Articles[index].Poids)) {
        poidstotal = poidstotal + parseInt(element1.Articles[index].Poids);
      }
      produits.push([
        {
          text: element.quantite,
          style: "itemNumber",
          border: [true, false, true, false],
          borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
          fillColor: "#FAFAFA",
        },
        {
          text:
            element.ref +
            "\n" +
            (element1.Articles[index]?.Code_marque?.charAt(0).toUpperCase() +
              element1.Articles[index]?.Code_marque?.slice(1).toLowerCase() ||
              ""),
          style: "itemTitle",
          border: [true, false, false, false],
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
          fillColor: "#F7F7F7",
        },
        {
          text:
            element.commentaire != ""
              ? element.description + "\n \n" + "Note : " + element.commentaire
              : element.description,
          style: "itemTitle",
          border: [false, false, true, false],
          borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
          fillColor: "#F7F7F7",
        },
        {
          text:
            element1.Articles[index].Poids +
            " " +
            element1.Articles[index].Udm_poids,
          style: "itemTitle",
          border: [true, false, true, false],
          borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
          fillColor: "#FAFAFA",
        },
      ]);
    });
    tablesBT.push(
      {
        text: "BT n°" + element1.Numero + "  du " + getFrDate(element1.Date),
        style: "tableTitle",
      },
      {
        table: {
          headerRows: 1,
          margin: [0, 2],
          widths: [30, 140, "*", 50],
          text: objet.clientInfo[0].nomcommercial,
          body: produits,
        },
      }
    );
    produits = [];
  });

  footerBody = {
    headerRows: 0,
    widths: [30, 140, "*", 50],

    body: [
      [
        {
          text: "",
          style: "itemsFooterSubTitle",
        },
        {
          text: "",
          style: "itemsFooterSubTitle",
        },
        {
          text: "Poids total: ",
          style: "itemsFooterSubTitle",
        },
        {
          text: poidstotal + " g",
          style: "itemsFooterSubValue",
        },
      ],
    ],
  };

  let infoCompClient = "";
  if (objet.clientInfo[0].professionnel == true && objet.clientInfo[0].siret) {
    infoCompClient =
      objet.clientInfo[0].siret +
      " - " +
      objet.clientInfo[0].numerotva +
      " - " +
      objet.clientInfo[0].nafape;
  }
  let moyendepaiement = "";
  if (objet.clientInfo[0].professionnel == true) {
    moyendepaiement = objet.clientInfo[0]?.moyendepaiement
      ? "Moyen de paiement : " + objet.clientInfo[0].moyendepaiement
      : "";

    moyendepaiement += objet.clientInfo[0]?.NumeroClient
      ? "\nN° Client : " + objet.clientInfo[0].NumeroClient
      : "";
  }

  co.push([
    // Items
    tablesBT,
    "\n\n",
    {
      table: footerBody,
      layout: "lightHorizontalLines",
    },
  ]);

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  var dd = {
    header: [
      {
        columns: [
          {
            text: edition,
            style: "documentHeaderRight",
          },
        ],
      },
      // Header
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            image: "imgg",
            width: 100,
            height: 100,
          },
          // margin: [left, top, right, bottom]
          [
            {
              columns: [
                {
                  text: "Bon de transport clôturé",
                  style: "invoiceTitle",
                  margin: [0, 0, 0, 0],
                  width: 390,
                },
              ],
            },
            {
              columns: [
                {
                  text: "n°" + "100000" + objet.Numero,
                  style: "invoiceTitleDetail",
                  margin: [0, 0, 0, 0],
                  width: 390,
                },
              ],
            },
            colDuplicata,
            {
              stack: [
                {
                  columns: [
                    {
                      text: "",
                      style: "invoiceSubValue",
                      width: 180,
                    },
                    {
                      text: datepdf,
                      style: "invoiceSubValue",
                      margin: [0, 40, 0, 0],
                      width: 190,
                    },
                  ],
                },
              ],
            },
          ],
        ],
      },
      // Billing Headers
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Titre1 + "\n",
            style: "invoiceBillingTitle",
            width: 245,
            margin: [0, 5, 0, 0],
          },
          {
            text: objet.Client + "\n",
            style: "invoiceBillingTitle1",
            width: 245,
            margin: [25, 0, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Titre2 + "\n",
            style: "invoiceBillingSubTitle",
            width: 245,
            margin: [40, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].Adresse,
            style: "invoiceBillingDetails",
            width: 210,
            margin: [24, -5, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Adresse,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 10, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 10, 0, 0],
          },
          {
            text: objet.clientInfo[0].Complement
              ? objet.clientInfo[0].Complement
              : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [-1, -9, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text:
              store.state.plateform.Code_postale +
              " " +
              store.state.plateform.Ville,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
          {
            text:
              objet.clientInfo[0].PostalCode + " " + objet.clientInfo[0].City,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, -20, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Pays,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].Country
              ? objet.clientInfo[0].Country
              : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, -20, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Telephone,
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, 5, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingContactCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].telephone,
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, -5, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Email,
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].email,
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, -10, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text:
              store.state.plateform.Siret +
              " - " +
              store.state.plateform.Tva +
              " - " +
              store.state.plateform.Naf,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: infoCompClient,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, -10, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, 2, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: moyendepaiement,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, -8, 0, 0],
          },
        ],
      },
    ],
    footer: function (currentPage, pageCount) {
      return [
        {
          columns: [
            {
              text: `${store.state.plateform.Forme_sociale} ${store.state.plateform.Titre1} au capital de ${store.state.plateform.Capital} € dont le siège social est situé à ${store.state.plateform.Adresse} ${store.state.plateform.Code_postale} ${store.state.plateform.Ville}. \n En cas de retard de paiement, taux de penalité appliqué égal à 3 fois le taux d'intérêt en vigueur. Pas d'escompte en cas de paiement anticipé. Echange ou retour sous 15 jours.`,
              style: "documentFooterCenter",
            },
          ],
        },
        {
          columns: [
            {
              text: `Page ${currentPage} / ${pageCount}`,
              style: "documentFooterCenter1",
            },
          ],
        },
      ];
    },
    content: [co],
    images: {
      imgg: {
        url: `${store.state.plateform.lien_logo}`,
        headers: {
          "Cache-Control": "no-cache",
        },
      },
    },
    styles: {
      // Document Header
      documentHeaderRight: {
        fontSize: 10,
        margin: [0, 10, 15, 5],
        alignment: "right",
      },
      documentHeaderCenter: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "center",
      },
      // Document Footer
      documentFooterLeft: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "left",
      },
      documentFooterCenter: {
        fontSize: 6,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
      documentFooterCenter1: {
        fontSize: 9,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
      documentFooterRight: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
      },
      // Invoice Title
      invoiceTitle: {
        fontSize: 22,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      invoiceTitleDetail: {
        fontSize: 10,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      // Invoice Details
      invoiceSubValue: {
        fontSize: 9,
        alignment: "right",
      },
      // Billing Headers
      invoiceBillingTitle: {
        fontSize: 14,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingTitle1: {
        fontSize: 10,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle1: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      // Billing Details
      invoiceBillingDetails: {
        margin: [0, 0, 0, 0],
        fontSize: 11,
        alignment: "left",
      },
      invoiceBillingContactCompany: {
        fontSize: 8,
      },
      invoiceBillingDetailsCompany: {
        fontSize: 7,
      },
      // Items Header
      itemsHeader: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        bold: true,
        fillColor: "#CECECE",
      },
      // Item Title
      itemTitle: {
        fontSize: 8,
      },
      itemNumber: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        alignment: "center",
      },
      itemTotal: {
        margin: [0, 2, 0, 2],
        fontSize: 10,
        bold: true,
        alignment: "center",
      },
      tableTitle: {
        margin: [0, 2, 0, 0],
        fontSize: 8,
        alignment: "left",
      },
      // Items Footer (Subtotal, Total, Tax, etc)
      itemsFooterSubTitle: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "right",
      },
      itemsFooterSubValue: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "center",
      },
      itemsFooterTotalTitle: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterTotalValue: {
        margin: [-30, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterTotalValue1: {
        margin: [-10, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "right",
      },
      itemsFooterResteTitle: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
        bold: true,
        fillColor: "#CECECE",
      },
      itemsFooterReste: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
      },
      //signaturePlaceholder: {
      //    margin: [0,70,0,0],
      //},
      //signatureName: {
      //    bold: true,
      //    alignment:'center',
      //},
      //signatureJobTitle: {
      //    italics: true,
      //    fontSize: 10,
      //    alignment:'center',
      //},
      notesTitle: {
        fontSize: 10,
        bold: true,
        margin: [0, 50, 0, 3],
      },
      notesText: {
        fontSize: 10,
      },
      center: {
        alignment: "center",
      },
    },
    pageMargins: [40, 260, 40, 40],
    defaultStyle: {
      columnGap: 20,
    },
  };
  pdfMake.createPdf(dd).open();
}
