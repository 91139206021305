<template>
  <div>
    <div class="flex justify-start">
      <backButton :route="'configurationCatalogueUniversel'" />
    </div>
    <div class="flex flex-row mt-4">
      <div
        class="scroll-container w-1/4 bg-white flex flex-col rounded shadow overflow-y-auto max-h-[75vh]"
      >
        <div
          v-if="imageObjectList.length === 0"
          class="p-2 italic text-orange-500 text-sm"
        >
          Aucune promo configuré
        </div>
        <div v-for="(prom, indexProm) in imageObjectList" :key="indexProm">
          <div
            @click="showClick[indexProm] = !showClick[indexProm]"
            class="p-2 border-b border-gray-200 w-full flex justify-between cursor-pointer"
          >
            <div class="flex flex-row space-x-2 items-center">
              <div>Promo {{ prom.position + 1 }} :</div>
              <div
                v-if="prom.sousCategoryUniverselle"
                class="italic text-blue-500 text-sm"
              >
                {{ prom.sousCategoryUniverselle.name }}
              </div>
              <div v-else class="italic text-orange-500 text-sm">
                à configurer
              </div>
            </div>
            <div>
              <span
                v-if="showClick[indexProm]"
                class="material-symbols-rounded"
              >
                arrow_drop_down
              </span>
              <span v-else class="material-symbols-rounded"> arrow_right </span>
            </div>
          </div>
          <div v-if="showClick[indexProm]" class="flex flex-col bg-gray-50">
            <div
              v-for="(conf, indexC) in configuration"
              :key="indexC"
              class="ml-2"
            >
              <div
                @click="conf.showPromo = !conf.showPromo"
                class="p-2 w-full flex justify-between cursor-pointer"
              >
                <div>{{ conf.name }}</div>
                <div>
                  <span v-if="conf.showPromo" class="material-symbols-rounded">
                    arrow_drop_down
                  </span>
                  <span v-else class="material-symbols-rounded">
                    arrow_right
                  </span>
                </div>
              </div>
              <div
                v-for="(fam, indexF) in conf.famille"
                v-show="conf.showPromo"
                :key="indexF"
                class="ml-4"
              >
                <div
                  @click="fam.showPromo = !fam.showPromo"
                  class="p-2 w-full flex justify-between cursor-pointer"
                >
                  <div>{{ fam.name }}</div>
                  <div>
                    <span v-if="fam.showPromo" class="material-symbols-rounded">
                      arrow_drop_down
                    </span>
                    <span v-else class="material-symbols-rounded">
                      arrow_right
                    </span>
                  </div>
                </div>
                <div
                  v-for="(sfam, indexSF) in fam.sousFamille"
                  v-show="fam.showPromo"
                  :key="indexSF"
                  class="ml-4 p-1 hover:underline hover:text-blue-500 cursor-pointer"
                  @click="setSousCategoryforPromo(sfam, prom._id)"
                >
                  {{ sfam.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-4 w-4/5">
        <div class="grid grid-cols-2 gap-4">
          <div class="h-[40vh]">
            <div class="h-full w-full" v-if="promoUniverselImage0">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage0.url" class="h-full mx-auto" />
                  <span
                    class="material-icons-round absolute top-0 right-0 text-red-500 cursor-pointer"
                    @click="deleteImg(0)"
                  >
                    delete_forever
                  </span>
                </div>
              </div>
            </div>
            <label
              v-else
              for="dropzone-file0"
              class="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span class="font-semibold">Cliquer pour importer</span>
                  ou glisser et déposer
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG ou GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                id="dropzone-file0"
                @change="readImg($event, 0)"
                type="file"
                class="hidden"
              />
            </label>
          </div>
          <div class="h-[40vh]">
            <div class="h-full w-full" v-if="promoUniverselImage1">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage1.url" class="h-full mx-auto" />
                  <span
                    class="material-icons-round absolute top-0 right-0 text-red-500 cursor-pointer"
                    @click="deleteImg(1)"
                  >
                    delete_forever
                  </span>
                </div>
              </div>
            </div>
            <label
              v-else
              for="dropzone-file1"
              class="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span class="font-semibold">Cliquer pour importer</span>
                  ou glisser et déposer
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG ou GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                id="dropzone-file1"
                @change="readImg($event, 1)"
                type="file"
                class="hidden"
              />
            </label>
          </div>
        </div>
        <div
          class="bg-white mt-4 rounded shadow w-full h-[3vh] flex flex-row itemse-center text-sm"
        >
          Barre de message
        </div>
        <div class="mt-4 grid grid-cols-5 gap-4">
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage2">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage2.url" class="h-full mx-auto" />
                  <span
                    class="material-icons-round absolute top-0 right-0 text-red-500 cursor-pointer"
                    @click="deleteImg(2)"
                  >
                    delete_forever
                  </span>
                </div>
              </div>
            </div>
            <label
              v-else
              for="dropzone-file2"
              class="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span class="font-semibold">Cliquer pour importer</span>
                  ou glisser et déposer
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG ou GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                id="dropzone-file2"
                @change="readImg($event, 2)"
                type="file"
                class="hidden"
              />
            </label>
          </div>
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage3">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage3.url" class="h-full mx-auto" />
                  <span
                    class="material-icons-round absolute top-0 right-0 text-red-500 cursor-pointer"
                    @click="deleteImg(3)"
                  >
                    delete_forever
                  </span>
                </div>
              </div>
            </div>
            <label
              v-else
              for="dropzone-file3"
              class="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span class="font-semibold">Cliquer pour importer</span>
                  ou glisser et déposer
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG ou GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                id="dropzone-file3"
                @change="readImg($event, 3)"
                type="file"
                class="hidden"
              />
            </label>
          </div>
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage4">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage4.url" class="h-full mx-auto" />
                  <span
                    class="material-icons-round absolute top-0 right-0 text-red-500 cursor-pointer"
                    @click="deleteImg(4)"
                  >
                    delete_forever
                  </span>
                </div>
              </div>
            </div>
            <label
              v-else
              for="dropzone-file4"
              class="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span class="font-semibold">Cliquer pour importer</span>
                  ou glisser et déposer
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG ou GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                id="dropzone-file4"
                @change="readImg($event, 4)"
                type="file"
                class="hidden"
              />
            </label>
          </div>
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage5">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage5.url" class="h-full mx-auto" />
                  <span
                    class="material-icons-round absolute top-0 right-0 text-red-500 cursor-pointer"
                    @click="deleteImg(5)"
                  >
                    delete_forever
                  </span>
                </div>
              </div>
            </div>
            <label
              v-else
              for="dropzone-file5"
              class="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span class="font-semibold">Cliquer pour importer</span>
                  ou glisser et déposer
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG ou GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                id="dropzone-file5"
                @change="readImg($event, 5)"
                type="file"
                class="hidden"
              />
            </label>
          </div>
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage6">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage6.url" class="h-full mx-auto" />
                  <span
                    class="material-icons-round absolute top-0 right-0 text-red-500 cursor-pointer"
                    @click="deleteImg(6)"
                  >
                    delete_forever
                  </span>
                </div>
              </div>
            </div>
            <label
              v-else
              for="dropzone-file6"
              class="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span class="font-semibold">Cliquer pour importer</span>
                  ou glisser et déposer
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG ou GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                id="dropzone-file6"
                @change="readImg($event, 6)"
                type="file"
                class="hidden"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import backButton from "@/components/Button/back.vue";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      promoUniverselImage0: null,
      promoUniverselImage1: null,
      promoUniverselImage2: null,
      promoUniverselImage3: null,
      promoUniverselImage4: null,
      promoUniverselImage5: null,
      promoUniverselImage6: null,

      configuration: [],
      imageObjectList: [],

      showClick: [],
    };
  },
  components: {
    backButton,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getConfiguration() {
      const response = await axios.get(
        `${process.env.VUE_APP_API}/configurationUniversel/getConfiguration/${this.user.proprietaire}`
      );
      this.configuration = response.data;
    },
    async readImg(e, position) {
      const file = e.target.files[0];
      const imagefile = file;

      var fd = new FormData();

      fd.append("image", imagefile);
      fd.append("plateform", this.user.proprietaire);
      fd.append("position", position);

      const createImage = await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/promoUniverselImage`,
        fd
      );
      this.getPromoUniverselImage();
      //   await this.getImage();
    },
    async getPromoUniverselImage() {
      const getPromoUniverselImage = await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/getPromoUniverselImage`,
        {
          plateform: this.user.proprietaire,
        }
      );
      this.imageObjectList = getPromoUniverselImage.data;
      this.promoUniverselImage0 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 0
      );
      this.promoUniverselImage1 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 1
      );
      this.promoUniverselImage2 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 2
      );
      this.promoUniverselImage3 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 3
      );
      this.promoUniverselImage4 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 4
      );
      this.promoUniverselImage5 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 5
      );
      this.promoUniverselImage6 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 6
      );
    },
    async deleteImg(position) {
      const deleteImg = await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/deletePromoUniverselImage`,
        {
          plateform: this.user.proprietaire,
          position: position,
        }
      );
      this.getPromoUniverselImage();
    },
    async setSousCategoryforPromo(sCat, promo) {
      console.log(promo);
      //setSousCategoryforPromo

      const setSousCategoryforPromo = await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/setSousCategoryforPromo`,
        {
          _id: promo,
          sousCategoryUniverselle: sCat,
        }
      );

      this.getPromoUniverselImage();
      this.getConfiguration();
    },
  },
  beforeMount() {
    this.getPromoUniverselImage();
    this.getConfiguration();
  },
};
</script>
<style scoped>
.scroll-container::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Optional: just to hide the scrollbar */
}
</style>
