<template>
  <div
  class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
  @click="$emit('close')"
>
  <div
    class="bg-white rounded-lg shadow-lg w-1/2"
    @click.stop
  >
    <div class="p-4 border-b flex justify-between items-center">
      <h3 class="text-xl font-semibold">Modifier la promotion</h3>
      <button
        @click="$emit('close')"
        class="text-gray-500 hover:text-gray-900"
      >
      <span class="material-icons">close</span>
      </button>
    </div>
      <div v-if="formData" class="p-4">
        <div class="mb-4">
          <label class="block text-gray-700">Titre</label>
          <input
            v-model="formData.titre"
            type="text"
            class="w-full border rounded px-3 py-2"
            required
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700">Quantité</label>
          <input
            v-model.number="formData.Quantite"
            type="number"
            class="w-full border rounded px-3 py-2"
            required
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700">Désignation</label>
          <input
            v-model="formData.Designation"
            type="text"
            class="w-full border rounded px-3 py-2"
            required
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700">Remise</label>
          <input
            v-model.number="formData.Remise"
            type="number"
            class="w-full border rounded px-3 py-2"
            required
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700">Prix net HT</label>
          <input
            v-model.number="formData.PrixNet"
            type="number"
            step="0.01"
            class="w-full border rounded px-3 py-2"
            required
          />
        </div>
        <div class="text-gray-700">
          <div v-if="formData.url" class="relative mt-2 rounded-md shadow-md">
            <img :src="formData.url" class="h-36 w-76" alt="Promo Image" />
            <button
              class="mt-2 text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
              @click="deleteImage()"
            >
              Supprimer l'image
            </button>
          </div>
        </div>
        <div class="text-gray-700">
          <label class="text-sm"
            >Image <span class="text-gray-500">(optionnel)</span></label
          >
          <div class="relative mt-2 rounded-md shadow-md">
            <input
              v-show="imageState"
              type="file"
              ref="file"
              accept="image/png, image/jpeg, image/jpg"
              class="w-full focus:outline-2 outline-sky-300 pl-3 bg-white pr-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              @change="onFileChange($event)"
            />
          </div>
          <img
            class="h-32 w-36"
            v-if="imagePromo"
            :src="imagePromo"
            v-show="!imageState"
          />
          <button
            class="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-1.5 mt-2 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
            v-if="imagePromo"
            @click="onImageDelete"
          >
            supprimer l'image
          </button>
        </div>

        <div class="flex justify-end mt-2">
          <button
            type="button"
            class="mr-4 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            @click="$emit('close')"
          >
            Annuler
          </button>
          <button
            @click="updatePromo()"
            class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  name: "modifierPromo",
  setup() {
    const toast = useToast();
    return { toast };
  },
  props: {
    promoData: {
      type: Object,
      required: false,
      default: () => ({
        titre: "",
        Quantite: 0,
        Designation: "",
        Remise: 0,
        PrixNet: 0,
        url: "",
      }),
    },
  },
  data() {
    return {
      formData: null,
      fileImage: null,
      imagePromo: null,
      imageState: true,
    };
  },
  methods: {
    updatePromo() {
      if (
        this.formData.titre &&
        this.formData.Quantite > 0 &&
        this.formData.Designation &&
        this.formData.Remise >= 0 &&
        this.formData.PrixNet >= 0
      ) {
        axios
          .put(`${process.env.VUE_APP_API}/promo/${this.formData._id}`, {
            titre: this.formData.titre,
            Quantite: this.formData.Quantite,
            Designation: this.formData.Designation,
            Remise: this.formData.Remise,
            PrixNet: this.formData.PrixNet,
            url: this.formData.url
          })
          .then(() => {
            this.$emit("close");
            this.success("Enregisitré !");
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.error("Veuillez remplir tous les champs obligatoires.");
      }
    },
    success(message) {
      this.toast.success(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.warning(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.fileImage = file;
      this.imagePromo = URL.createObjectURL(file);
      console.log(this.imagePromo);
      this.imageState = !this.imageState;
    },
    onImageDelete() {
      this.fileImage = null;
      this.imagePromo = null;
      this.imageState = !this.imageState;
      this.$refs.file.value = "";
    },
    deleteImage() {
      this.formData.url = "";
    },
  },
  mounted() {
    this.formData = { ...this.promoData };
  },
};
</script>
