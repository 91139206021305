<template>
  <div class="flex justify-between my-1">
    <div id="retour" class="flex justify-start">
      <backButton :route="'/ReportsPage'" />
    </div>
    <div class="flex justify-end space-x-2">
      <div>
        <button
          @click="save()"
          class="px-6 py-3 font-medium text-white bg-bluevk rounded-md hover:bg-blue-500 focus:outline-none"
        >
          Valider
        </button>
      </div>
      <!-- <div>
        <button
          class="px-6 py-3 text-bluevk bg-transparent rounded-md hover:bg-gray-200 hover:text-blue-400 focus:outline-none"
        >
          Annuler
        </button>
      </div> -->
    </div>
  </div>

  <div
    v-if="tabDetailAppointment"
    class="bg-white rounded-lg shadow-md p-6 space-y-4"
  >
    <h2 class="text-xl font-semibold text-gray-800">Détails du rendez-vous</h2>
    <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
      <!-- Date et Heure -->
      <div class="flex items-center space-x-2">
        <i class="pi pi-calendar text-blue-500 text-xl"></i>
        <div>
          <p class="text-sm font-medium text-gray-500">Date et Heure</p>
          <p class="text-gray-800 font-semibold">
            {{ getFRDate(tabDetailAppointment.date) }}
          </p>
        </div>
      </div>
      <!-- Statut -->
      <div class="flex items-center space-x-2">
        <i class="pi pi-info-circle text-green-500 text-xl"></i>
        <div>
          <p class="text-sm font-medium text-gray-500">Statut</p>
          <select
            v-model="tabDetailAppointment.status"
            class="px-3 py-1 bg-green-100 text-green-800 text-sm font-medium rounded-full"
          >
            <option value="En attente">En attente</option>
            <option value="Terminé">Terminé</option>
            <option value="Annulé">Annulé</option>
            <option value="Reporté">Reporté</option>
          </select>
        </div>
      </div>
      <!-- Client -->
      <div class="flex items-center space-x-2">
        <i class="pi pi-user text-yellow-500 text-xl"></i>
        <div>
          <p class="text-sm font-medium text-gray-500">Client</p>
          <div class="flex flex-col">
            <p class="text-gray-800 font-semibold">
              {{ tabDetailAppointment.client }}
            </p>
          </div>
        </div>
      </div>
      <!-- Contact -->
      <div
        v-if="tabDetailAppointment.contact"
        class="flex items-center space-x-2"
      >
        <i class="pi pi-user text-yellow-500 text-xl"></i>
        <div>
          <p class="text-sm font-medium text-gray-500">Contact</p>
          <div class="flex flex-col">
            <p class="text-gray-800 font-semibold">
              {{ tabDetailAppointment.contact.name }}
            </p>
            <p>{{ tabDetailAppointment.contact.position }}</p>
            <p>{{ tabDetailAppointment.contact.email }}</p>
            <p>{{ tabDetailAppointment.contact.phone }}</p>
          </div>
        </div>
      </div>
      <!-- Lieu -->
      <div class="flex items-center space-x-2">
        <i class="pi pi-map-marker text-red-500 text-xl"></i>
        <div>
          <p class="text-sm font-medium text-gray-500">Lieu</p>
          <p class="text-gray-800 font-semibold">
            {{ tabDetailAppointment.address }},
            {{ tabDetailAppointment.postalCode }}
            {{ tabDetailAppointment.city }}
          </p>
        </div>
      </div>
      <!-- Emplacement -->
      <div class="flex items-center space-x-2">
        <i class="pi pi-info-circle text-green-500 text-xl"></i>
        <div>
          <p class="text-sm font-medium text-gray-500">Emplacement</p>
          <googleMap
            v-if="tabDetailAppointment.location"
            :latitude="tabDetailAppointment.location.latitude"
            :longitude="tabDetailAppointment.location.longitude"
            style="height: 200px"
          />
          <a
            v-if="tabDetailAppointment.location"
            :href="`https://www.google.com/maps?q=${tabDetailAppointment.location.latitude},${tabDetailAppointment.location.longitude}`"
            target="_blank"
            class="text-blue-500 hover:underline"
          >
            Ouvrir dans Google Maps
          </a>
        </div>
      </div>
      <!-- Score -->
      <div class="mb-auto flex items-center space-x-2">
        <i class="pi pi-info-circle text-green-500 text-xl"></i>
        <div>
          <p class="text-sm font-medium text-gray-500">Score</p>
          <EmojiRating v-model:rating="tabDetailAppointment.rating" />
          <!-- <p>Note actuelle : {{ tabDetailAppointment.rating }}</p> -->
        </div>
      </div>
    </div>
  </div>

  <div v-if="tabDetailAppointment" class="flex mt-4">
    <div class="flex flex-col w-full">
      <div class="flex flex-col space-y-1">
        <h1 class="text-xl font-bold">Sujets à aborder :</h1>
        <div
          class="flex justify-start space-x-2 w-full max-w-full overflow-y-auto"
        >
          <div
            v-for="(topic, indexTopic) in tabDetailAppointment.topics"
            :key="indexTopic"
          >
            <button
              @click="selectedTopicIndex = indexTopic"
              :class="{
                'bg-bluevk text-white': selectedTopicIndex === indexTopic,
                'bg-gray-200 text-black': selectedTopicIndex !== indexTopic,
              }"
              class="px-8 py-1 shadow-lg border rounded-lg hover:bg-blue-500"
            >
              {{ topic.topic }}
            </button>
          </div>
          <div>
            <input
              v-model="creationTopic"
              placeholder="Ecrire..."
              @keyup.enter="addNewTopic"
              class="px-8 py-1 text-white shadow-lg border rounded-lg bg-gray-700 hover:bg-gray-500"
            />
          </div>
        </div>
      </div>

      <div class="flex space-x-12 mt-8">
        <div class="relative w-4/6 flex">
          <!-- Description -->
          <textarea
            v-if="selectedTopicIndex !== null"
            v-model="tabDetailAppointment.topics[selectedTopicIndex].notes"
            placeholder="Notes"
            class="w-full border border-gray-300 rounded-lg p-2 h-36 focus:outline-none focus:border-blue-500"
          ></textarea>
        </div>

        <!-- A demander -->
        <div class="overflow-x-hidden w-1/6 flex flex-col space-y-1">
          <h1 class="text-xl font-bold">A demander :</h1>
          <div class="h-36 max-h-36">
            <div>
              <input
                v-model="creationDemand"
                placeholder="Ecrire..."
                @keyup.enter="addNewDemand"
                class="px-8 py-1 text-white shadow-lg border rounded-lg bg-gray-700 hover:bg-gray-500"
              />
            </div>
            <div
              v-for="(demand, indexDemand) in tabDetailAppointment.demands"
              :key="indexDemand"
            >
              <div
                class="flex items-center py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              >
                <input
                  type="checkbox"
                  :id="'demandCheckbox' + indexDemand"
                  v-model="demand.done"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <label
                  :for="'demandCheckbox' + indexDemand"
                  class="pl-2 flex-grow"
                  :class="{ 'text-gray-600': demand.done }"
                >
                  {{ demand.demand }}
                </label>

                <button
                  @click="suppDemand(indexDemand)"
                  class="ml-2 text-red-500 hover:text-red-700"
                >
                <span
                      style="font-size: 15px"
                      class="material-icons-outlined text-red-600 cursor-pointer items-center rounded-full  p-1 w-6 h-6"
                    >
                      cancel
                    </span>
                  <!-- supprimer button -->
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Actions -->
        <div class="overflow-x-hidden w-1/6 flex flex-col space-y-1">
          <h1 class="text-xl font-bold">Actions à faire</h1>
          <div class="h-36 max-h-36">
            <div>
              <input
                v-model="creationAction"
                placeholder="Ecrire..."
                @keyup.enter="addNewAction"
                class="px-8 py-1 text-white shadow-lg border rounded-lg bg-gray-700 hover:bg-gray-500"
              />
            </div>
            <div
              v-for="(action, indexAction) in tabDetailAppointment.actions"
              :key="indexAction"
            >
              <div
                class="flex items-center py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              >
                <input
                  type="checkbox"
                  :id="'actionCheckbox' + indexAction"
                  v-model="action.done"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <label
                  :for="'actionCheckbox' + indexAction"
                  class="pl-2 flex-grow"
                >
                  {{ action.action }}
                </label>
                <!-- supprimer button -->
                <button
                  @click="suppAction(indexAction)"
                  class="ml-2 text-red-500 hover:text-red-700"
                >
                <span
                      style="font-size: 15px"
                      class="material-icons-outlined text-red-600 cursor-pointer items-center rounded-full  p-1 w-6 h-6"
                    >
                      cancel
                    </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Statistiques -->
      <div class="flex flex-col space-y-1 mt-8">
        <div class="text-xl font-bold">Statistiques :</div>
        <!-- <p>Total BDLS : {{ totalBDL }}</p>
        <p>Total Factures : {{ totalFacture }}</p> -->
        <chiffreMoisClient
          v-if="tabDetailAppointment?.clientID"
          :clientID="tabDetailAppointment.clientID"
        />
        <!-- <graphStats
          :numberOfClients="[100, 200, 150, 300, 250]"
          :labels="['Janvier', 'Février', 'Mars', 'Avril', 'Mai']"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import backButton from "@/components/Button/back.vue";
// import audioRecorder from "@/components/Input/audioRecorder.vue";
import EmojiRating from "@/components/Label/EmojiRating.vue";
import { getFrDate } from "@/hooks/tools/date.ts";
import chiffreMoisClient from "@/components/commercial/chiffreMoisClient.vue";
import googleMap from "@/components/Input/googleMap.vue";
// import graphStats from "@/components/Input/graphique.vue";
// import { GMapMap, GMapMarker } from "@fawmi/vue-google-maps";

export default {
  data() {
    return {
      // audio: null,
      // isPlaying: false,
      // bars: 30,
      // currentTime: 0,
      // initialHeights: [],
      isPlaying: false,
      audio: null,
      currentAttachment: null,
      formattedTime: "0:00",

      creationTopic: "",
      creationDemand: "",
      creationAction: "",
      totalBDL: null,
      totalFacture: null,

      tabDetailAppointment: {},
      rating: null,

      address: "Chargement de l'adresse...",
      error: null,
      zoom: 15,

      isListening: false,
      selectedTopicIndex: null,
    };
  },
  components: {
    backButton,
    EmojiRating,
    chiffreMoisClient,
    googleMap,
    // graphStats,
    // audioRecorder,
  },
  computed: {
    ...mapGetters(["user", "idAppointment"]),
    // formattedTime() {
    //   const minutes = Math.floor(this.currentTime / 60);
    //   const seconds = Math.floor(this.currentTime % 60)
    //     .toString()
    //     .padStart(2, "0");
    //   return `${minutes}:${seconds}`;
    // },
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getReportsPageDetail() {
      axios
        .post(`${process.env.VUE_APP_API}/appointment/getByID`, {
          _id: this.idAppointment,
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          console.log("response appointment: ", response);
          this.tabDetailAppointment = response.data.data.tabAppointment;
        });
    },
    async getTotalBdls() {
      await axios
        .post(`${process.env.VUE_APP_API}/bdl/countByClientID`, {
          plateform: this.user.proprietaire,
          clientID: this.clientID,
        })
        .then((response) => {
          console.log(response);
          this.totalBDL = response.data;
        });
    },
    async getTotalFactures() {
      await axios
        .post(`${process.env.VUE_APP_API}/facture/countByClientID`, {
          plateform: this.user.proprietaire,
          clientID: this.clientID,
        })
        .then((response) => {
          console.log(response);
          this.totalFacture = response.data;
        });
    },
    async save() {
      this.tabDetailAppointment.updatedBy = this.user.username;

      const updatedAppointment = {
        ...this.tabDetailAppointment,
        topics: this.tabDetailAppointment.topics.map((topic) => ({
          topic: topic.topic,
          notes: topic.notes,
          done: topic.done,
        })),
      };

      await axios
        .put(`${process.env.VUE_APP_API}/appointment/update`, [
          updatedAppointment,
        ])
        .then((response) => {
          console.log("response update : ", response);
        });
    },
    addNewTopic() {
      if (this.creationTopic.trim() !== "") {
        this.tabDetailAppointment.topics.push({
          topic: this.creationTopic,
          notes: "",
          done: false,
        });
        this.creationTopic = "";
      }
    },
    addNewDemand() {
      if (this.creationDemand.trim() !== "") {
        this.tabDetailAppointment.demands.unshift({
          demand: this.creationDemand,
          done: false,
        });
        this.creationDemand = "";
      }
    },
    addNewAction() {
      if (this.creationAction.trim() !== "") {
        this.tabDetailAppointment.actions.unshift({
          action: this.creationAction,
          done: false,
        });
        this.creationAction = "";
      }
    },
    suppDemand(index) {
      this.tabDetailAppointment.demands.splice(index, 1);
    },
    suppAction(index) {
      this.tabDetailAppointment.actions.splice(index, 1);
    },
  },
  async mounted() {
    this.getReportsPageDetail();
    await this.getTotalBdls();
    await this.getTotalFactures();

    if (
      this.tabDetailAppointment.topics &&
      this.tabDetailAppointment.topics.length > 0
    ) {
      this.selectedTopicIndex = 0;
    }
  },
  beforeUnmount() {
    if (this.audio) {
      this.audio.pause();
      this.audio.removeEventListener("timeupdate", this.updateTime);
      this.audio.removeEventListener("ended", this.resetAudio);
    }
  },
};
</script>

<style scoped>
@keyframes pulse {
  0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
}
.animate-pulse {
  animation: pulse 0.5s infinite;
}
</style>
