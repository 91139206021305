<template>
  <div class="flex justify-between">
    <div id="retour" class="flex justify-start">
      <backButton :route="'/articleHub'" />
    </div>
    <div><titrePages :title="'Mouvement de stocks'" /></div>
    <div
      v-if="typePage !== 'reserved' && typePage !== 'backorder'"
      class="ml-2"
    >
      <button
        v-if="!loadingExport"
        class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
        @click="exportExcel()"
      >
        <span class="material-icons-outlined text-xl mr-2"> download </span>
        <span>Exporter en Excel</span>
      </button>
      <button
        v-else
        class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
      >
        <PulseLoader color="#ffff" class="mr-2" />
        <span>Export en cours</span>
      </button>
    </div>
  </div>

  <div class="flex">
    <searchInput
      placeholder="Rechercher dans mouvements de stock"
      @searchWord="search(1, 10, $event)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
      class="mt-1"
    />
    <div class="flex items-center space-x-2 ml-auto">
      <!-- Aligns buttons to the right -->
      <button
        class="w-32 p-1 py-2 text-white text-sm font-medium rounded-md shadow-lg"
        :class="typePage == 'stock' ? 'bg-orangevk' : 'bg-bluevk'"
        @click="(typePage = 'stock'), getCurrentPage(1, 10)"
      >
        Stock
      </button>
      <button
        class="w-32 bg-blue-600 p-1 text-white text-sm py-2 font-medium rounded-md shadow-lg"
        :class="typePage == 'reserved' ? 'bg-orangevk' : 'bg-bluevk'"
        @click="(typePage = 'reserved'), getCurrentPage(1, 10)"
      >
        Réservé
      </button>
      <button
        class="w-34 bg-blue-600 p-1 text-white py-2 px-3 h-fit m-auto text-sm font-medium rounded-md shadow-lg"
        :class="typePage == 'backorder' ? 'bg-orangevk' : 'bg-bluevk'"
        @click="(typePage = 'backorder'), getCurrentPage(1, 10)"
      >
        Commande Fournisseur
      </button>
    </div>
  </div>
  <div>
    <!-- Breadcrumb -->
    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  v-if="typePage == 'stock'"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  EAN / Réf.
                </th>
                <th
                  v-if="typePage == 'stock'"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Marque
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Client / Créé par
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Date
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  {{
                    typePage == "reserved" || typePage == "backorder"
                      ? "Statut"
                      : "Lieu"
                  }}
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  N° doc
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Mouvement
                </th>
                <th
                  v-if="typePage == 'stock'"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Min/Max
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(u, index) in mvtStock" :key="index">
                <td
                  v-if="typePage == 'stock'"
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-col">
                    <p
                      class="inline-flex w-fit px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >
                      {{ u.Code_EAN }}
                    </p>
                    <p class="font-bold px-2">{{ u.Ref_fournisseur }}</p>
                  </div>
                </td>

                <td
                  v-if="typePage == 'stock'"
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <p v-if="u.Code_marque == 'Non défini'">
                    {{ u.Code_marque }}
                  </p>
                  <span v-else>{{ u.Code_marque }}</span>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-col">
                    <p
                      class="inline-flex w-fit px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >
                      {{ u.client }}
                    </p>
                    <p
                      class="inline-flex w-fit px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                    >
                      {{ u.created_by }}
                    </p>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div
                        v-if="u.createdAt"
                        class="text-sm font-medium leading-5 text-gray-900"
                      >
                        {{ getFRDate(u.createdAt) }}
                      </div>
                      <div
                        v-else-if="u.Date"
                        class="text-sm font-medium leading-5 text-gray-900"
                      >
                        {{ getFRDate(u.Date) }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    v-if="typePage == 'reserved' || typePage == 'backorder'"
                  >
                    {{ u.archived ? "Archivé" : "Actif" }}
                  </span>
                  <span v-else>
                    {{ u.movement_type }}
                  </span>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <p v-if="u.doc_number">{{ u.doc_number }}</p>
                  <p v-else-if="u.order_number">{{ u.order_number }}</p>
                  <p v-else-if="u.bc_number">{{ u.bc_number }}</p>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <p v-if="u.Description">{{ u.Description }}</p>
                  <p v-else-if="u.order_number">
                    Commande fournisseur n°{{ u.order_number }}
                  </p>
                  <p v-else-if="u.bc_number">BC n°{{ u.bc_number }}</p>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <div
                    v-if="
                      u.old_stock ||
                      u.old_stock == 0 ||
                      u.old_stock <= 0 ||
                      u.old_stock >= 0
                    "
                  >
                    <span
                      class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                      >{{ u.old_stock }}</span
                    >
                    =>
                    <span
                      v-if="u.old_stock < u.new_stock"
                      class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                      >{{ u.new_stock }}</span
                    >
                    <span
                      v-else
                      class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-red-100 rounded-full"
                      >{{ u.new_stock }}</span
                    >
                  </div>

                  <p v-else-if="u.quantity">
                    {{ u.quantity }}
                  </p>

                  <p v-else-if="u.bc_number">
                    {{ u.quantity }}
                  </p>
                </td>

                <td
                  v-if="typePage == 'stock'"
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.min }} / {{ u.max }}
                </td>
              </tr>
            </tbody>
          </table>

          <pageNumber
            v-if="!searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="mouvementsLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(mouvementsLength / 10)"
            :clickFunction="getCurrentPage"
            :currentPage="currentPage"
            :typeList="'mouvements'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
          <pageNumber
            v-if="searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
            :currentPage="currentPage"
            :typeList="'mouvements'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import backButton from "@/components/Button/back.vue";
import { getFrDate } from "@/hooks/tools/date.ts";
import * as XLSX from "xlsx";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useToast } from "vue-toastification";
import { getAllReserved, getAllBackorder } from "@/hooks/stock/stock";
import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      open: false,
      mvtStock: [],
      allMvtStock: [],

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      mouvementsLength: 0,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,

      loadingExport: false,

      typePage: "stock",
    };
  },
  components: {
    pageNumber,
    searchInput,
    backButton,
    PulseLoader,
    titrePages,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    async getAllMvtStock() {
      const allMvt = await axios.post(
        `${process.env.VUE_APP_API}/mvStock/getall`,
        {
          plateform: this.user.proprietaire,
        }
      );
      if (allMvt && allMvt.data.length > 0) {
        this.allMvtStock = allMvt.data;
      }
    },
    async getMvtStock(skipValue, limitValue) {
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/mvStock/mvtPerPage`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((res) => {
          this.mvtStock = res.data.page;
          this.mouvementsLength = res.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getAllReserve(skipValue, limitValue) {
      this.currentPage = skipValue;
      const res = await getAllReserved(
        this.user._id,
        this.user.plateform,
        (skipValue - 1) * 10,
        limitValue,
        "_id",
        -1
      );
      this.mvtStock = res.data;
      this.mouvementsLength = res.count;
    },
    async getAllBackOrder(skipValue, limitValue) {
      this.currentPage = skipValue;
      const res = await getAllBackorder(
        this.user._id,
        this.user.plateform,
        (skipValue - 1) * 10,
        limitValue,
        "_id",
        -1
      );
      this.mvtStock = res.data;
      this.mouvementsLength = res.count;
    },
    async getCurrentPage(skip, limit) {
      if (this.typePage == "stock") {
        await this.getMvtStock(skip, limit);
      } else if (this.typePage == "reserved") {
        await this.getAllReserve(skip, limit);
      } else {
        await this.getAllBackOrder(skip, limit);
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getMvtStock(skipValue, limitValue);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/mvStock/searchInPlateform`, {
            plateform: this.user.proprietaire,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.mvtStock = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    async exportExcel() {
      this.loadingExport = true;
      await this.getAllMvtStock();

      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(this.allMvtStock);
      XLSX.utils.book_append_sheet(wb, ws, "Mvt Stock");

      XLSX.writeFile(wb, "Mvt_stock_" + this.user.proprietaire + ".xlsx");
      this.loadingExport = false;

      this.success("Le fichier a été téléchargé !");
    },
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getMvtStock(1, 10);
    this.pageSwitch();
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
