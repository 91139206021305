<template>
  <!-- Back Button -->
  <div class="flex justify-between">
    <div id="retour" class="flex justify-start mb-3">
      <backButton :route="'/Clients'" />
    </div>
  </div>
  <!-- Fin Back Button -->
  <div id="body" class="bg-white p-2 rounded-md">
    <div class="flex justify-between">
      <div class="text-2xl font-medium ml-2">
        <h2>Récapitulatif du client</h2>
      </div>
      <router-link to="/Clients">
        <div class="flex justify-end">
          <span
            style="font-size: 30px"
            class="material-icons-outlined cursor-pointer rounded-full text-red-700 hover:text-red-600"
            >close</span
          >
        </div>
      </router-link>
    </div>
    <div class="flex justify-center mt-8" id="someid">
      <div class="flex flex-col">
        <div class="flex justify-end items-center space-x-14">
          <div class="flex" v-if="this.user.accountType == 'Administrateur'">
            <select
              v-model.trim="this.clientChoosedTab.agenceName"
              @change="changeAgence(this.clientChoosedTab.agenceName)"
            >
              <option
                v-for="(agence, indexAgence) in Agences.Agences"
                :value="agence"
                :key="indexAgence"
              >
                {{ agence }}
              </option>
            </select>
          </div>
          <div
            class="flex"
            v-if="
              this.user.accountType == 'Administrateur' &&
              this.clientChoosedTab.archived == false
            "
          >
            <button
              class="flex w-fit px-2 py-1 h-fit text-blue-600 rounded-md hover:bg-gray-100 focus:outline-none items-center"
              @click="showArchive = !showArchive"
            >
              <span class="material-icons-outlined m-auto">archive</span>
              <span class="m-auto">Archiver</span>
            </button>
            <div
              v-if="showArchive"
              @click="showArchive = false"
              class="absolute w-screen h-screen z-10 top-0 left-0"
            ></div>
            <div
              v-if="showArchive"
              class="absolute mt-6 bg-gray-200 p-2 rounded shadow-lg z-10 overflow-hidden"
            >
              <div class="flex flex-col justify-center space-x-4">
                <span>Êtes-vous sûr de vouloir archiver ?</span>
                <div class="flex space-x-4 justify-center">
                  <button
                    class="w-fit px-2 py-1 h-fit text-blue-600 rounded-md bg-gray-100 hover:text-xl hover:bg-white"
                    @click="archiveClient()"
                  >
                    Oui
                  </button>
                  <button
                    class="w-fit px-2 py-1 h-fit rounded-md hover:text-xl hover:bg-white"
                    @click="showArchive = false"
                  >
                    Non
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex"
            v-if="
              this.user.accountType == 'Administrateur' &&
              this.clientChoosedTab.archived == true
            "
          >
            <button
              class="flex w-fit px-2 py-1 h-fit text-blue-600 rounded-md hover:bg-gray-100 focus:outline-none items-center"
              @click="showArchive = !showArchive"
            >
              <span class="material-icons-outlined m-auto">unarchive</span>
              <span class="m-auto">Désarchiver</span>
            </button>
            <div
              v-if="showArchive"
              @click="showArchive = false"
              class="absolute w-screen h-screen z-10 top-0 left-0"
            ></div>
            <div
              v-if="showArchive"
              class="absolute mt-6 bg-gray-200 p-2 rounded shadow-lg z-10 overflow-hidden"
            >
              <div class="flex flex-col justify-center space-x-4">
                <span>Êtes-vous sûr de vouloir désarchiver ?</span>
                <div class="flex space-x-4 justify-center">
                  <button
                    class="w-fit px-2 py-1 h-fit text-blue-600 rounded-md bg-gray-100 hover:text-xl hover:bg-white"
                    @click="unarchiveClient()"
                  >
                    Oui
                  </button>
                  <button
                    class="w-fit px-2 py-1 h-fit rounded-md hover:text-xl hover:bg-white"
                    @click="showArchive = false"
                  >
                    Non
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="flex" v-if="this.user.accountType == 'Administrateur'">
            <button
              class="flex w-fit px-2 py-1 h-fit text-red-600 rounded-md hover:bg-gray-100 focus:outline-none items-center"
              @click="showDelete = !showDelete"
            >
              <span class="material-icons-outlined m-auto">delete</span>
              <span class="m-auto">Supprimer le client</span>
            </button>
            <div
              v-if="showDelete"
              @click="showDelete = false"
              class="absolute w-screen h-screen z-10 top-0 left-0"
            ></div>
            <div
              v-if="showDelete"
              class="absolute mt-6 bg-gray-200 p-2 rounded shadow-lg z-10 overflow-hidden"
            >
              <div class="flex flex-col justify-center space-x-4">
                <span>Êtes-vous sûr de vouloir supprimer ?</span>
                <div class="flex space-x-4 justify-center">
                  <button
                    class="w-fit px-2 py-1 h-fit text-red-600 rounded-md bg-gray-100 hover:text-xl hover:bg-white"
                    @click="deleteClient()"
                  >
                    Oui
                  </button>
                  <button
                    class="w-fit px-2 py-1 h-fit rounded-md hover:text-xl hover:bg-white"
                    @click="showDelete = false"
                  >
                    Non
                  </button>
                </div>
              </div>
            </div>
          </div>

          <menuDetailClient />
        </div>
        <div id="creerClientPro" v-if="clientChoosedTab.professionnel == true">
          <ul class="flex flex-col align-center space-y-20">
            <!-- INFORMATIONS GENERALES -->
            <li
              style="width: 80rem"
              class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="
                  (showInformationsGenerales = !showInformationsGenerales),
                    (showParametreAvance = false),
                    (showTransport = false),
                    (showFinance = false),
                    (showPersonnel = false),
                    (showConfigEmail = false)
                "
              >
                <a class="text-xl"> Informations générales </a>
                <span
                  v-show="!showInformationsGenerales"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showInformationsGenerales"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showInformationsGenerales"
                  class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                  id="informationsgenerales"
                >
                  <div class="flex justify-between">
                    <div
                      class="w-56 px-5 py-2 text-gray-700 bg-gray-200 border-b"
                    >
                      <label class="text-xs">Groupe de client</label>

                      <div
                        v-if="clientChoosedTab.Group"
                        class="relative mt-2 rounded-md shadow-sm"
                      >
                        <select
                          v-model.trim="clientChoosedTab.Group.Name"
                          name="Group"
                          ref="Group"
                          @keydown.enter="this.$refs.NumeroClient.focus()"
                          @change="saveChange()"
                          class="w-full focus:outline-2 outline-sky-300 text-black p-2 border-transparent rounded-md"
                        >
                          <option
                            :value="cond.Name"
                            v-for="(cond, indexC) in configurationConditionList"
                            :key="indexC"
                          >
                            {{ cond.Name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Numéro</label>

                      <div class="w-24 relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.NumeroClient"
                          type="text"
                          ref="NumeroClient"
                          @keydown.enter="this.$refs.statutjuridique.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Statut juridique</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.statutjuridique"
                          type="text"
                          ref="statutjuridique"
                          @keydown.enter="this.$refs.Name.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                    <div
                      class="w-full px-5 py-2 text-gray-700 bg-gray-200 border-b"
                    >
                      <label class="text-xs">Nom</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.Name"
                          type="text"
                          ref="Name"
                          @keydown.enter="this.$refs.Adresse.focus()"
                          @change="saveChange()"
                          class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Adresse</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model.trim="clientChoosedTab.Adresse"
                        ref="Adresse"
                        @keydown.enter="this.$refs.Complement.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Complément d'adresse</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model.trim="clientChoosedTab.Complement"
                        ref="Complement"
                        @keydown.enter="this.$refs.PostalCode.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Code Postal</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="textfield"
                          v-model.trim="clientChoosedTab.PostalCode"
                          ref="PostalCode"
                          @input="autocompletePostalCode()"
                          @keydown.enter="this.$refs.City.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                      <div
                        class="z-10 absolute w-96 bg-gray-50 rounded-b shadow-md max-h-28 border border-sky-600 overflow-y-auto"
                        v-if="postal_code_proposal.length > 0"
                      >
                        <div
                          v-for="(adr, indexAdr) in postal_code_proposal"
                          :key="indexAdr"
                          class="cursor-pointer hover:bg-blue-200"
                          @click="selectPostalCode(adr)"
                        >
                          {{ adr.properties.label }}
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Ville</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="textfield"
                          v-model.trim="clientChoosedTab.City"
                          ref="City"
                          @input="autocompleteCity()"
                          @keydown.enter="this.$refs.Country.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                      <div
                        class="z-10 absolute w-96 bg-gray-50 rounded-b shadow-md max-h-28 border border-sky-600 overflow-y-auto"
                        v-if="city_proposal.length > 0"
                      >
                        <div
                          v-for="(adr, indexAdr) in city_proposal"
                          :key="indexAdr"
                          class="cursor-pointer hover:bg-blue-200"
                          @click="selectCity(adr)"
                        >
                          {{ adr.properties.label }}
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label for="Country" class="text-xs">Pays</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          name="Country"
                          v-model.trim="clientChoosedTab.Country"
                          ref="Country"
                          @keydown.enter="this.$refs.telephone.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                        >
                          <option value="ALLEMAGNE">ALLEMAGNE</option>
                          <option value="AUTRICHE">AUTRICHE</option>
                          <option value="BELGIQUE">BELGIQUE</option>
                          <option value="ESPAGNE">ESPAGNE</option>
                          <option selected value="FRANCE">FRANCE</option>
                          <option value="ITALIE">ITALIE</option>
                          <option value="LUXEMBOURG">LUXEMBOURG</option>
                          <option value="MAROC">MAROC</option>
                          <option value="PAYS-BAS">PAYS-BAS</option>
                          <option value="SUISSE">SUISSE</option>
                          <option value="TURQUIE">TURQUIE</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Tél n°1</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="tel"
                          v-model.trim="clientChoosedTab.telephone"
                          ref="telephone"
                          @keydown.enter="this.$refs.telephone2.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Tél n°2</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.telephone2"
                          type="text"
                          ref="telephone2"
                          @keydown.enter="this.$refs.fax.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Fax</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.fax"
                          type="text"
                          ref="fax"
                          @keydown.enter="this.$refs.email.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Email</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="email"
                        v-model.trim="clientChoosedTab.email"
                        ref="email"
                        @keydown.enter="this.$refs.siret.focus()"
                        @change="
                          saveChange(),
                            addEmailClient(
                              clientChoosedTab.email,
                              clientChoosedTab.Emails
                            )
                        "
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Siret</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.siret"
                          type="text"
                          ref="siret"
                          @keydown.enter="this.$refs.nafape.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">NAF-APE</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.nafape"
                          type="text"
                          ref="nafape"
                          @keydown.enter="this.$refs.numerotva.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Numéro T.V.A.</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.numerotva"
                          type="text"
                          ref="numerotva"
                          @keydown.enter="this.$refs.rcsrm.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-2">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">RCS-RM</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.rcsrm"
                          type="text"
                          ref="rcsrm"
                          @keydown.enter="this.$refs.adresselivraison.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Adresse de livraison</label>

                      <div class="flex relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="adresselivraison"
                          ref="adresselivraison"
                          @keydown.enter="this.$refs.nomcommercial.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                          v-model.trim="clientChoosedTab.adresselivraison"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    class="grid grid-cols-3 justify-between"
                    style="margin-top: -10px"
                  >
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Nom/Prénom du commercial</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="nomcommercial"
                          ref="nomcommercial"
                          @keydown.enter="this.$refs.telcommercial.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                          v-model="clientChoosedTab.nomcommercial"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Tél. commercial</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="telcommercial"
                          ref="telcommercial"
                          @keydown.enter="this.$refs.emailcommercial.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                          v-model="clientChoosedTab.telcommercial"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Email commercial</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="emailcommercial"
                          ref="emailcommercial"
                          @keydown.enter="
                            this.$refs.nomcommercialplateform.focus()
                          "
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                          v-model="clientChoosedTab.emailcommercial"
                        />
                      </div>
                    </div>
                  </div>

                  <h5 class="px-5 py-2 text-gray-700 bg-gray-200 underline">
                    Commercial attitré
                  </h5>
                  <div
                    class="grid grid-cols-3 justify-between"
                    style="margin-top: -10px"
                  >
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Nom/Prénom du commercial</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model.trim="clientChoosedTab.Commercial.nom"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          ref="nomcommercialplateform"
                          @keydown.enter="
                            this.$refs.telcommercialplateform.focus()
                          "
                          @change="assignCommercial()"
                        >
                          <option
                            v-for="(select, indexSelect) in commerciaux"
                            :key="indexSelect"
                            :value="select.prenom + ' ' + select.nom"
                          >
                            {{ select.prenom }} {{ select.nom }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Tél. commercial</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model.trim="clientChoosedTab.Commercial.tel"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          ref="telcommercialplateform"
                          @keydown.enter="
                            this.$refs.emailcommercialplateform.focus()
                          "
                          @change="saveChange()"
                        >
                          <option
                            v-for="(select, indexSelect) in commerciaux"
                            :key="indexSelect"
                            :value="select.telephone"
                          >
                            {{ select.telephone }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Email commercial</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model.trim="clientChoosedTab.Commercial.email"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          ref="emailcommercialplateform"
                          @change="saveChange()"
                        >
                          <option
                            v-for="(select, indexSelect) in commerciaux"
                            :key="indexSelect"
                            :value="select.email"
                          >
                            {{ select.email }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>

            <!-- PARAMETRES AVANCEES -->
            <li
              style="width: 80rem"
              class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="
                  (showParametreAvance = !showParametreAvance),
                    (showInformationsGenerales = false),
                    (showTransport = false),
                    (showFinance = false),
                    (showPersonnel = false),
                    (showConfigEmail = false)
                "
              >
                <a class="text-xl"> Paramètres avancées </a>
                <span
                  v-show="!showParametreAvance"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showParametreAvance"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showParametreAvance"
                  class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                  id="informationsgenerales"
                >
                  <h1 class="px-5 pt-2 text-gray-800 text-center font-medium">
                    Siège
                  </h1>
                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Siège ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="checkbox"
                            v-model="clientChoosedTab.siege"
                            id="siege"
                            name="siege"
                            :input="isSiege()"
                            @change="saveChange()"
                          />
                          <label for="siege" class="pl-2">{{
                            clientChoosedTab.siege ? "Oui" : "Non"
                          }}</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Facture au siège ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="checkbox"
                            v-model="clientChoosedTab.factureSiege"
                            id="factureSiege"
                            name="factureSiege"
                            :disabled="clientChoosedTab.siege"
                            @change="saveChange()"
                          />
                          <label for="factureSiege" class="pl-2">{{
                            clientChoosedTab.factureSiege ? "Oui" : "Non"
                          }}</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label
                        for="parentCompanyName"
                        class="flex space-x-2 text-xs"
                        ><p>Client siège</p>
                        <p class="text-red-600">*</p></label
                      >

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          name="parentCompanyName"
                          v-model="clientChoosedTab.parentCompanyName"
                          ref="parentCompanyName"
                          @change="saveChange()"
                          @input="
                            searchClient(clientChoosedTab.parentCompanyName)
                          "
                          :disabled="clientChoosedTab.siege"
                          class="focus:outline-2 outline-sky-300 w-full p-2 rounded-md"
                        />
                        <p class="text-xs">
                          {{ clientChoosedTab.parentCompanyID }}
                        </p>
                        <div
                          v-if="Clients.length > 0"
                          class="absolute z-20 shadow-lg bg-white border border-gray-300 rounded-md overflow-y-auto max-h-32 w-full"
                        >
                          <ul class="space-y-1">
                            <li
                              v-for="(client, indexclient) in Clients"
                              :key="indexclient"
                              class="cursor-pointer px-4 py-2 hover:bg-gray-200"
                              @click="selectClient(client)"
                            >
                              {{ client.Name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />

                  <!-- COMPTOIR -->
                  <h1 class="px-5 pt-2 text-gray-800 text-center font-medium">
                    Comptoir
                  </h1>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Autoriser à commander ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.commander"
                            id="commander"
                            name="commander"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.commander"
                            id="commander"
                            name="commander"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs"
                        >Afficher la remise au comptoir client ?</label
                      >

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.affichageRemise"
                            id="affichageRemise"
                            name="affichageRemise"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.affichageRemise"
                            id="affichageRemise"
                            name="affichageRemise"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs"
                        >Editer la remise au comptoir distrib ?</label
                      >

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.editionRemise"
                            id="editionRemise"
                            name="editionRemise"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.editionRemise"
                            id="editionRemise"
                            name="editionRemise"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Encours Max. autorisé</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.encoursmaxautorise"
                          name="encoursmaxautorise"
                          ref="encoursmaxautorise"
                          @keydown.enter="this.$refs.min_marge.focus()"
                          @change="saveChange()"
                          type="text"
                          class="w-full focus:outline-2 outline-sky-300 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Marge minimum (en %)</label>
                      <input
                        v-model="clientChoosedTab.min_marge"
                        type="Number"
                        ref="min_marge"
                        @keydown.enter="this.$refs.min_coef.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Coef minimum (en Decimal)</label>
                      <input
                        v-model="clientChoosedTab.min_coef"
                        type="Float"
                        ref="min_coef"
                        @keydown.enter="this.$refs.statutjuridique.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs"
                        >Afficher le TTC au comptoir distrib</label
                      >

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="checkbox"
                            v-model.trim="clientChoosedTab.stateTTC"
                            id="stateTTC"
                            name="stateTTC"
                            value="true"
                            @change="saveChange()"
                          />
                          <label for="stateTTC" class="pl-2">{{
                            clientChoosedTab.stateTTC ? "Oui" : "Non"
                          }}</label>
                        </div>
                      </div>
                    </div>

                    <div
                      class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
                    ></div>

                    <div
                      class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
                    ></div>
                  </div>

                  <br />

                  <!-- DOCUMENT AU COMPTOIR -->
                  <h1 class="px-5 pt-2 text-gray-800 text-center font-medium">
                    Document au comptoir
                  </h1>
                  <div v-if="clientChoosedTab.counter" class="grid grid-cols-6">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Devis </label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.counter.devis"
                            id="devisRadio"
                            name="devisRadio"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.counter.devis"
                            id="devisRadio"
                            name="devisRadio"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Bon de commande</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="
                              clientChoosedTab.counter.bondecommande
                            "
                            id="commandeRadio"
                            name="commandeRadio"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="
                              clientChoosedTab.counter.bondecommande
                            "
                            id="commandeRadio"
                            name="commandeRadio"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Bon de livraison</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="
                              clientChoosedTab.counter.bondelivraison
                            "
                            id="livraisonRadio"
                            name="livraisonRadio"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="
                              clientChoosedTab.counter.bondelivraison
                            "
                            id="livraisonRadio"
                            name="livraisonRadio"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Bon de livraison avoir</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="
                              clientChoosedTab.counter.bondelivraisonavoir
                            "
                            id="livraisonAvoirRadio"
                            name="livraisonAvoirRadio"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="
                              clientChoosedTab.counter.bondelivraisonavoir
                            "
                            id="livraisonAvoirRadio"
                            name="livraisonAvoirRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Facture</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.counter.facture"
                            id="factureRadio"
                            name="factureRadio"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.counter.facture"
                            id="factureRadio"
                            name="factureRadio"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Retour</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.counter.retour"
                            id="retourRadio"
                            name="retourRadio"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.counter.retour"
                            id="retourRadio"
                            name="retourRadio"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />

                  <!-- FACTURATION -->
                  <h1 class="px-5 pt-2 text-gray-800 text-center font-medium">
                    Facturation
                  </h1>
                  <div class="grid grid-cols-3">
                    <div
                      class="hidden px-5 py-2 text-gray-700 bg-gray-200 border-b"
                    >
                      <label class="text-xs">Professionnel ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            disabled
                            type="radio"
                            v-model.trim="clientChoosedTab.professionnel"
                            value="true"
                            data-val="true"
                            data-val-required="Ce champ est obligatoire."
                            id="professionnel"
                            name="professionnel"
                            data-enable="True"
                            data-for-field="professionnel"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            disabled
                            type="radio"
                            v-model.trim="clientChoosedTab.professionnel"
                            value="false"
                            id="professionnel"
                            name="professionnel"
                            data-enable="false"
                            data-for-field="professionnel"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Exonéré de la T.V.A. ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.tva"
                            id="tva"
                            name="tva"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.tva"
                            id="tva"
                            name="tva"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Facturation fin de mois</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.facturationFDM"
                            id="facturationFDM"
                            name="facturationFDM"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.facturationFDM"
                            id="facturationFDM"
                            name="facturationFDM"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Créer facture après BL ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.billAfterBL"
                            id="billAfterBL"
                            name="billAfterBL"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.billAfterBL"
                            id="billAfterBL"
                            name="billAfterBL"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Facturer les consignes</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.facturation_consigne"
                            id="facturation_consigne"
                            name="facturation_consigne"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.facturation_consigne"
                            id="facturation_consigne"
                            name="facturation_consigne"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />

                  <!-- SUITE PARAMETRE -->
                  <h1 class="px-5 pt-2 text-gray-800 text-center font-medium">
                    Plus de paramètre
                  </h1>
                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">N° de compte DA SILVA</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.numcomptedasilva"
                          name="numcomptedasilva"
                          ref="numcomptedasilva"
                          @keydown.enter="this.$refs.mgid.focus()"
                          @change="saveChange()"
                          type="text"
                          class="w-full focus:outline-2 outline-sky-300 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">mgid (CarCat)</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.mgid"
                          type="text"
                          name="mgid"
                          ref="mgid"
                          @change="saveChange()"
                          class="w-full focus:outline-2 outline-sky-300 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs"
                        >Connexion catalogue CarCat ?</label
                      >

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.catalogue_carcat"
                            id="catalogue_carcat"
                            name="catalogue_carcat"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.catalogue_carcat"
                            id="catalogue_carcat"
                            name="catalogue_carcat"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Tableau des remises ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.TableauRemiseState"
                            id="TableauRemiseState"
                            name="TableauRemiseState"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.TableauRemiseState"
                            id="TableauRemiseState"
                            name="TableauRemiseState"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>

            <!-- TRANSPORT -->
            <li
              style="width: 80rem"
              class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="
                  (showTransport = !showTransport),
                    (showParametreAvance = false),
                    (showFinance = false),
                    (showPersonnel = false),
                    (showInformationsGenerales = false),
                    (showConfigEmail = false)
                "
              >
                <a class="text-xl"> Transport </a>
                <span
                  v-show="!showTransport"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showTransport"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showTransport"
                  class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                  id="transport"
                >
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Livré par transporteur ?</label>

                    <div
                      class="flex relative mt-2 rounded-md shadow-sm bg-white"
                    >
                      <div
                        class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      >
                        <input
                          type="radio"
                          v-model.trim="clientChoosedTab.livrepartransporteur"
                          id="livrepartransporteur"
                          name="livrepartransporteur"
                          value="true"
                          @change="saveChange()"
                        />
                        <label class="pl-2 checkbox">Oui</label>
                      </div>

                      <div
                        class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      >
                        <input
                          type="radio"
                          v-model.trim="clientChoosedTab.livrepartransporteur"
                          id="livrepartransporteur"
                          name="livrepartransporteur"
                          value="false"
                          @change="saveChange()"
                        />
                        <label class="pl-2 checkbox">Non</label>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Frais de port</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.fraisdeport"
                          type="text"
                          ref="fraisdeport"
                          @keydown.enter="this.$refs.francodeport.focus()"
                          @change="saveChange()"
                          class="w-full focus:outline-2 outline-sky-300 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="grid grid-cols-2">
                      <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                        <label class="text-xs">Franco de port journalier</label>

                        <div class="relative mt-2 rounded-md shadow-sm">
                          <input
                            v-model.trim="clientChoosedTab.francodeport"
                            name="francodeport"
                            ref="francodeport"
                            type="text"
                            @keydown.enter="this.$refs.francodeportMois.focus()"
                            @change="saveChange()"
                            class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          />
                        </div>
                      </div>

                      <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                        <label class="text-xs">Franco de port mensuel</label>
                        <div class="relative mt-2 rounded-md shadow-sm">
                          <input
                            v-model.trim="clientChoosedTab.francodeportMois"
                            name="francodeportMois"
                            ref="francodeportMois"
                            type="text"
                            @change="saveChange()"
                            class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="bg-gray-200 border-t border-gray-500">
                      <button
                        style="
                          width: 188px;
                          height: 33px;
                          flex-shrink: 0;
                          border-radius: 6px;
                          background: #3b62b4;
                          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                        "
                        class="text-white my-2 ml-6"
                        title="Ajouter un transporteur"
                        @click="
                          clientChoosedTab.tabCutOff.push({
                            transporteur: '',
                            schematransport: '',
                            cutoff: '',
                          })
                        "
                      >
                        Ajouter un transporteur
                      </button>
                      <div
                        v-for="(line, index) in clientChoosedTab.tabCutOff"
                        :key="index"
                        class="flex"
                      >
                        <button
                          class="material-icons-outlined text-red-600 my-auto"
                          @click="
                            clientChoosedTab.tabCutOff.splice(index, 1),
                              saveChange()
                          "
                        >
                          delete
                        </button>
                        <div class="w-full grid grid-cols-3">
                          <div class="px-5 py-2 text-gray-700 bg-gray-200">
                            <label class="text-xs">Transporteur</label>

                            <div class="relative mt-2 rounded-md shadow-sm">
                              <select
                                v-model.trim="
                                  clientChoosedTab.tabCutOff[index].transporteur
                                "
                                class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                ref="transporteur"
                                @keydown.enter="
                                  this.$refs.schematransport.focus()
                                "
                                @change="
                                  saveChange(),
                                    getSchemasTransporteur(
                                      index,
                                      clientChoosedTab?.tabCutOff[index]
                                        .transporteur
                                    ),
                                    getCutOffsTransporteur(
                                      index,
                                      clientChoosedTab.tabCutOff[index]
                                        .transporteur
                                    )
                                "
                              >
                                <option
                                  v-for="(select, indexSelect) in transporteurs"
                                  :key="indexSelect"
                                  :value="select._id"
                                >
                                  {{ select.numero }} -
                                  {{ select.transporteur }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
                          >
                            <label class="text-xs">Schéma de transport</label>

                            <div class="relative mt-2 rounded-md shadow-sm">
                              <select
                                v-model.trim="
                                  clientChoosedTab.tabCutOff[index]
                                    .schematransport
                                "
                                class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                ref="schematransport"
                                @keydown.enter="this.$refs.cutoff.focus()"
                                @change="saveChange()"
                              >
                                <option
                                  v-for="(
                                    select, indexSelect
                                  ) in schematransports[index]"
                                  :key="indexSelect"
                                  :value="select.reference"
                                >
                                  {{ select.reference }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
                          >
                            <label class="text-xs">Cut-Off</label>

                            <div class="relative mt-2 rounded-md shadow-sm">
                              <select
                                v-model.trim="
                                  clientChoosedTab.tabCutOff[index].cutoff
                                "
                                class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                ref="cutoff"
                                @keydown.enter="this.$refs.iban.focus()"
                                @change="saveChange()"
                              >
                                <option
                                  v-for="(select, indexSelect) in cutoffs[
                                    index
                                  ]"
                                  :key="indexSelect"
                                  :value="select.hour"
                                  type="time"
                                >
                                  {{ select.hour }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>

            <!-- FINANCE -->
            <li
              style="width: 80rem"
              class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="
                  (showFinance = !showFinance),
                    (showPersonnel = false),
                    (showParametreAvance = false),
                    (showInformationsGenerales = false),
                    (showTransport = false),
                    (showConfigEmail = false)
                "
              >
                <a class="text-xl"> Finance </a>
                <span
                  v-show="!showFinance"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showFinance"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showFinance"
                  class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                  id="finance"
                >
                  <div class="container">
                    <div class="grid grid-cols-2">
                      <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                        <label class="text-xs">IBAN</label>

                        <div class="relative mt-2 rounded-md shadow-sm">
                          <input
                            v-model.trim="clientChoosedTab.iban"
                            type="text"
                            ref="iban"
                            @keydown.enter="this.$refs.swift.focus()"
                            @change="saveChange()"
                            class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          />
                        </div>
                      </div>

                      <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                        <label class="text-xs">SWIFT/BIC</label>

                        <div class="relative mt-2 rounded-md shadow-sm">
                          <input
                            v-model.trim="clientChoosedTab.swift"
                            type="text"
                            ref="swift"
                            @keydown.enter="this.$refs.moyendepaiement.focus()"
                            @change="saveChange()"
                            class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="grid grid-cols-2">
                      <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                        <label class="text-xs">Moyen de paiement</label>

                        <div class="relative mt-2 rounded-md shadow-sm">
                          <select
                            v-model.trim="clientChoosedTab.moyendepaiement"
                            ref="moyendepaiement"
                            @keydown.enter="this.$refs.delaidepaiement.focus()"
                            @change="saveChange()"
                            class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                          >
                            <option value="LCR">LCR</option>
                            <option value="Virement">Virement</option>
                            <option value="Chèque">Chèque</option>
                            <option value="CB">CB</option>
                            <option value="Espèce">Espèce</option>
                          </select>
                        </div>
                      </div>

                      <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                        <label class="text-xs">Délai de paiement</label>

                        <div class="relative mt-2 rounded-md shadow-sm">
                          <select
                            v-model="clientChoosedTab.delaidepaiement"
                            ref="delaidepaiement"
                            @keydown.enter="this.$refs.nomdelabanque.focus()"
                            @change="saveChange()"
                            class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                          >
                            <option value="0">IMMEDIAT</option>
                            <option value="30">30 JOURS FIN DE MOIS</option>
                            <option value="45">45 JOURS FIN DE MOIS</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Nom de la banque</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.nomdelabanque"
                          type="text"
                          ref="nomdelabanque"
                          @keydown.enter="this.$refs.ncomptecomptable.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">N° compte comptable</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="clientChoosedTab.ncomptecomptable"
                          type="text"
                          ref="ncomptecomptable"
                          @keydown.enter="this.$refs.personnelposte.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>

            <!-- PERSONNEL -->
            <li
              style="width: 80rem"
              class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="
                  (showPersonnel = !showPersonnel),
                    (showFinance = false),
                    (showParametreAvance = false),
                    (showInformationsGenerales = false),
                    (showTransport = false),
                    (showConfigEmail = false)
                "
              >
                <a class="text-xl"> Personnel </a>
                <span
                  v-show="!showPersonnel"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showPersonnel"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showPersonnel"
                  class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                  id="personnel"
                >
                  <div class="flex flex-col mt-8">
                    <div
                      class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
                    >
                      <div
                        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
                      >
                        <table class="min-w-full">
                          <thead>
                            <tr>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                <button class="">
                                  <span
                                    class="w-6 h-6 material-icons-round rounded-full"
                                    style="color: orange"
                                  >
                                    add_circle_outline
                                  </span>
                                </button>
                              </th>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                Poste/service
                              </th>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                Prénom
                              </th>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                Nom
                              </th>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                Tél n°1
                              </th>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                Tél n°2
                              </th>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                Email
                              </th>
                            </tr>
                          </thead>

                          <tbody class="bg-white">
                            <tr>
                              <td
                                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                              ></td>

                              <td
                                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                              >
                                <input
                                  v-model.trim="clientChoosedTab.personnelposte"
                                  ref="personnelposte"
                                  @keydown.enter="
                                    this.$refs.personnelprenom.focus()
                                  "
                                  @change="saveChange()"
                                  class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                                />
                              </td>

                              <td
                                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                              >
                                <input
                                  v-model.trim="
                                    clientChoosedTab.personnelprenom
                                  "
                                  ref="personnelprenom"
                                  @keydown.enter="
                                    this.$refs.personnelnom.focus()
                                  "
                                  @change="saveChange()"
                                  class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                                />
                              </td>

                              <td
                                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                              >
                                <input
                                  v-model.trim="clientChoosedTab.personnelnom"
                                  ref="personnelnom"
                                  @keydown.enter="
                                    this.$refs.personneltel1.focus()
                                  "
                                  @change="saveChange()"
                                  class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                                />
                              </td>

                              <td
                                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                              >
                                <input
                                  v-model.trim="clientChoosedTab.personneltel1"
                                  ref="personneltel1"
                                  @keydown.enter="
                                    this.$refs.personneltel2.focus()
                                  "
                                  @change="saveChange()"
                                  class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                                />
                              </td>

                              <td
                                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                              >
                                <input
                                  v-model.trim="clientChoosedTab.personneltel2"
                                  ref="personneltel2"
                                  @keydown.enter="
                                    this.$refs.personnelemail.focus()
                                  "
                                  @change="saveChange()"
                                  class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                                />
                              </td>

                              <td
                                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                              >
                                <input
                                  v-model.trim="clientChoosedTab.personnelemail"
                                  ref="personnelemail"
                                  @keydown.enter="
                                    this.$refs.email.address.focus()
                                  "
                                  class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                                  @change="saveChange()"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>

            <!-- CONFIG EMAIL -->
            <li
              style="width: 80rem"
              class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="
                  (showConfigEmail = !showConfigEmail),
                    (showFinance = false),
                    (showPersonnel = false),
                    (showParametreAvance = false),
                    (showInformationsGenerales = false),
                    (showTransport = false)
                "
              >
                <a class="text-xl"> Configuration e-mail </a>
                <span
                  v-show="!showConfigEmail"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showConfigEmail"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showConfigEmail"
                  class="bloc-couleur couleur-remplissage"
                  id="configemail"
                >
                  <div class="container">
                    <div class="flex flex-col space-y-2">
                      <div
                        v-if="clientChoosedTab.Emails"
                        class="flex flex-col space-y-2 px-5 py-2 text-gray-700 bg-gray-200 border-b"
                      >
                        <p v-if="errors.length">
                          <span
                            v-for="(error, index) in errors"
                            :key="index"
                            class="text-red-600"
                            >{{ error }}</span
                          >
                        </p>
                        <div
                          v-for="(email, index) in clientChoosedTab.Emails"
                          :key="index"
                          @change="checkForm(email.address)"
                          class="flex space-x-2"
                        >
                          <div class="flex space-x-1 m-auto">
                            <span
                              class="material-icons-outlined text-red-600 m-auto"
                              @click="
                                removeEmail(index, clientChoosedTab.Emails)
                              "
                            >
                              delete
                            </span>

                            <div style="width: 30rem">
                              <label class="text-xs">E-mail</label>
                              <div class="relative mt-2 rounded-md shadow-sm">
                                <input
                                  type="email"
                                  v-model.trim="email.address"
                                  ref="emailaddress"
                                  @change="saveChange(), errors == []"
                                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                />
                              </div>
                            </div>
                          </div>

                          <!-- devis -->
                          <div style="width: 8rem">
                            <label class="text-xs">Devis ?</label>

                            <div
                              class="flex relative mt-2 rounded-md shadow-sm bg-white"
                            >
                              <div
                                class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                              >
                                <input
                                  type="checkbox"
                                  v-model.trim="email.devis"
                                  id="devis"
                                  name="devis"
                                  :value="true"
                                  @change="saveChange()"
                                />
                                <label
                                  v-if="email.devis == true"
                                  class="pl-2 checkbox"
                                  >Oui</label
                                >
                                <label
                                  v-if="email.devis == false"
                                  class="pl-2 checkbox"
                                  >Non</label
                                >
                              </div>
                            </div>
                          </div>

                          <!-- bondecommande -->
                          <div style="width: 8rem">
                            <label class="text-xs">Bon de commande ?</label>

                            <div
                              class="flex relative mt-2 rounded-md shadow-sm bg-white"
                            >
                              <div
                                class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                              >
                                <input
                                  type="checkbox"
                                  v-model.trim="email.bondecommande"
                                  id="bondecommande"
                                  name="bondecommande"
                                  :value="true"
                                  @change="saveChange()"
                                />
                                <label
                                  v-if="email.bondecommande == true"
                                  class="pl-2 checkbox"
                                  >Oui</label
                                >
                                <label
                                  v-if="email.bondecommande == false"
                                  class="pl-2 checkbox"
                                  >Non</label
                                >
                              </div>
                            </div>
                          </div>

                          <!-- bondetransport -->
                          <div style="width: 8rem">
                            <label class="text-xs">Bon de transport ?</label>

                            <div
                              class="flex relative mt-2 rounded-md shadow-sm bg-white"
                            >
                              <div
                                class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                              >
                                <input
                                  type="checkbox"
                                  v-model.trim="email.bondetransport"
                                  id="bondetransport"
                                  name="bondetransport"
                                  :value="true"
                                  @change="saveChange()"
                                />
                                <label
                                  v-if="email.bondetransport == true"
                                  class="pl-2 checkbox"
                                  >Oui</label
                                >
                                <label
                                  v-if="email.bondetransport == false"
                                  class="pl-2 checkbox"
                                  >Non</label
                                >
                              </div>
                            </div>
                          </div>

                          <!-- bondelivraison -->
                          <div style="width: 8rem">
                            <label class="text-xs">Bon de livraison ?</label>

                            <div
                              class="flex relative mt-2 rounded-md shadow-sm bg-white"
                            >
                              <div
                                class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                              >
                                <input
                                  type="checkbox"
                                  v-model.trim="email.bondelivraison"
                                  id="bondelivraison"
                                  name="bondelivraison"
                                  :value="true"
                                  @change="saveChange()"
                                />
                                <label
                                  v-if="email.bondelivraison == true"
                                  class="pl-2 checkbox"
                                  >Oui</label
                                >
                                <label
                                  v-if="email.bondelivraison == false"
                                  class="pl-2 checkbox"
                                  >Non</label
                                >
                              </div>
                            </div>
                          </div>

                          <!-- facture -->
                          <div style="width: 8rem">
                            <label class="text-xs">Facture ?</label>

                            <div
                              class="flex relative mt-2 rounded-md shadow-sm bg-white"
                            >
                              <div
                                class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                              >
                                <input
                                  type="checkbox"
                                  v-model.trim="email.facture"
                                  id="facture"
                                  name="facture"
                                  :value="true"
                                  @change="saveChange()"
                                />
                                <label
                                  v-if="email.facture == true"
                                  class="pl-2 checkbox"
                                  >Oui</label
                                >
                                <label
                                  v-if="email.facture == false"
                                  class="pl-2 checkbox"
                                  >Non</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex justify-start mt-2">
                        <button
                          @click="addEmail(clientChoosedTab.Emails)"
                          class="px-2 py-2 font-medium tracking-wide text-white bg-cyan-600 rounded-md hover:bg-cyan-500 focus:outline-none"
                        >
                          Ajouter un e-mail
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>
          </ul>
        </div>
        <!-- fin créer client pro -->

        <div id="creerClientPar" v-if="clientChoosedTab.professionnel == false">
          <ul class="flex flex-col align-center space-y-20">
            <!-- INFORMATIONS GENERALES -->
            <li
              style="width: 80rem"
              class="bg-gray-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:shadow-2xl"
            >
              <div>
                <div
                  class="bloc-couleur couleur-remplissage"
                  id="informationsgenerales"
                >
                  <div class="flex justify-between">
                    <div class="w-84 px-5 py-2 text-gray-800 bg-gray-50">
                      <label class="text-xs">Professionnel ?</label>

                      <div
                        class="flex w-full relative border border-sky-300 mt-2 rounded-md shadow-sm"
                      >
                        <div
                          class="flex pl-4 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="temporaryValue"
                            @change="confirmChange"
                            value="true"
                            data-val="true"
                            data-val-required="Ce champ est obligatoire."
                            id="professionnelOui"
                            name="professionnelParticulier"
                            data-enable="True"
                            data-for-field="professionnelParticulier"
                          />
                          <label class="pl-2 checkbox" for="professionnelOui"
                            >Oui</label
                          >
                        </div>

                        <div
                          class="flex ml-9 mr-4 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="temporaryValue"
                            @change="confirmChange"
                            checked
                            value="false"
                            id="professionnelNon"
                            name="professionnelParticulier"
                            data-enable="false"
                            data-for-field="professionnelParticulier"
                          />
                          <label class="pl-2 checkbox" for="professionnelNon"
                            >Non</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="w-full px-5 py-2 text-gray-800">
                      <label class="text-xs">Groupe de client</label>

                      <div class="w-full relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model.trim="clientChoosedTab.Group.Name"
                          @keydown.enter="
                            this.$refs.NumeroClientParticulier.focus()
                          "
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600 cursor-pointer"
                        >
                          <option
                            :value="cond.Name"
                            v-for="(cond, indexC) in configurationConditionList"
                            :key="indexC"
                          >
                            {{ cond.Name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="w-full px-5 py-2 text-gray-800">
                      <label class="text-xs">Numéro</label>

                      <div
                        class="w-full relative border border-sky-300 mt-2 rounded-md shadow-sm"
                      >
                        <input
                          v-model.trim="clientChoosedTab.NumeroClient"
                          type="text"
                          ref="NumeroClientParticulier"
                          @keydown.enter="this.$refs.nomParticulier.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600"
                        />
                      </div>
                    </div>
                    <!-- Modal -->
                    <div
                      v-if="isModalVisible"
                      class="z-50 fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
                      @click.self="cancelChange()"
                    >
                      <div class="bg-white p-5 rounded-lg shadow-lg relative">
                        <!-- Close Button -->
                        <button
                          @click="cancelChange()"
                          class="absolute top-2 right-2 text-gray-600 hover:text-gray-400"
                        >
                          <span class="material-icons-outlined text-[18px]"
                            >close</span
                          >
                        </button>
                        <p class="mt-6">Êtes-vous sûr de vouloir continuer ?</p>
                        <div class="flex justify-end mt-8 space-x-2">
                          <button
                            @click="cancelChange()"
                            class="bg-gray-300 hover:bg-red-500 hover:text-white text-bluevk px-3 py-1 rounded shadow-lg"
                          >
                            Non
                          </button>
                          <button
                            @click="applyChange()"
                            class="mr-2 bg-blue-600 hover:bg-blue-400 text-white px-3 py-1 rounded shadow-lg"
                          >
                            Oui
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="w-full px-5 py-2 text-gray-800">
                    <label class="text-xs">Nom</label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="clientChoosedTab.Name"
                        type="text"
                        ref="nomParticulier"
                        @keydown.enter="this.$refs.adresseParticulier.focus()"
                        @change="saveChange()"
                        class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600 cursor-pointer"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-3">
                    <div class="relative px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Code Postal</label>
                      <div class="mt-2 rounded-md shadow-sm">
                        <input
                          type="textfield"
                          v-model.trim="clientChoosedTab.PostalCode"
                          ref="codepostalParticulier"
                          @input="autocompletePostalCode()"
                          @keydown.enter="this.$refs.villeParticulier.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600"
                        />
                      </div>
                      <div
                        class="z-10 absolute w-96 bg-gray-50 rounded-b shadow-md max-h-28 border border-sky-600 overflow-y-auto"
                        v-if="postal_code_proposal.length > 0"
                      >
                        <div
                          v-for="(adr, indexAdr) in postal_code_proposal"
                          :key="indexAdr"
                          class="cursor-pointer hover:bg-blue-200"
                          @click="selectPostalCode(adr)"
                        >
                          {{ adr.properties.label }}
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Ville</label>
                      <div class="mt-2 rounded-md shadow-sm">
                        <input
                          type="textfield"
                          v-model.trim="clientChoosedTab.City"
                          ref="villeParticulier"
                          @input="autocompleteCity()"
                          @keydown.enter="this.$refs.paysParticulier.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600"
                        />
                      </div>
                      <div
                        class="z-10 absolute w-96 bg-gray-50 rounded-b shadow-md max-h-28 border border-sky-600 overflow-y-auto"
                        v-if="city_proposal.length > 0"
                      >
                        <div
                          v-for="(adr, indexAdr) in city_proposal"
                          :key="indexAdr"
                          class="cursor-pointer hover:bg-blue-200"
                          @click="selectCity(adr)"
                        >
                          {{ adr.properties.label }}
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label for="paysParticulier" class="text-xs">Pays</label>
                      <div class="mt-2 rounded-md shadow-sm">
                        <select
                          name="paysParticulier"
                          v-model.trim="clientChoosedTab.Country"
                          ref="paysParticulier"
                          @keydown.enter="
                            this.$refs.telephoneParticulier.focus()
                          "
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md"
                        >
                          <option value="ALLEMAGNE">ALLEMAGNE</option>
                          <option value="AUTRICHE">AUTRICHE</option>
                          <option value="BELGIQUE">BELGIQUE</option>
                          <option value="ESPAGNE">ESPAGNE</option>
                          <option selected value="FRANCE">FRANCE</option>
                          <option value="ITALIE">ITALIE</option>
                          <option value="LUXEMBOURG">LUXEMBOURG</option>
                          <option value="MAROC">MAROC</option>
                          <option value="PAYS-BAS">PAYS-BAS</option>
                          <option value="SUISSE">SUISSE</option>
                          <option value="TURQUIE">TURQUIE</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700">
                    <label class="text-xs">Adresse</label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model.trim="clientChoosedTab.Adresse"
                        ref="adresseParticulier"
                        @keydown.enter="
                          this.$refs.complementParticulier.focus()
                        "
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-t-md appearance-none focus:border-blue-600"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-50">
                    <label class="text-xs">Complément d'adresse</label>

                    <div class="mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model.trim="clientChoosedTab.Complement"
                        ref="complementParticulier"
                        @keydown.enter="
                          this.$refs.codepostalParticulier.focus()
                        "
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600"
                      />
                    </div>
                  </div>

                  <div class="grid grid-cols-2">
                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Téléphone</label>
                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="tel"
                          v-model.trim="clientChoosedTab.telephone"
                          ref="telephoneParticulier"
                          @keydown.enter="this.$refs.emailParticulier.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Email</label>
                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="emailParticulier"
                          v-model.trim="clientChoosedTab.email"
                          ref="emailParticulier"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="flex flex-col align-center space-y-20 mt-4">
            <!-- INFORMATIONS GENERALES -->
            <li
              class="bg-gray-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="showInformationsGenerales = !showInformationsGenerales"
              >
                <a class="font-medium">Plus de paramètres</a>
                <span
                  v-show="!showInformationsGenerales"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showInformationsGenerales"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showInformationsGenerales"
                  class="bloc-couleur couleur-remplissage"
                  id="informationsgenerales"
                >
                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Devis </label>

                      <div
                        class="flex border border-sky-300 relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-2 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.counter.devis"
                            id="devisRadio"
                            name="devisRadio"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.counter.devis"
                            id="devisRadio"
                            name="devisRadio"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Bon de commande</label>

                      <div
                        class="flex border border-sky-300 relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="
                              clientChoosedTab.counter.bondecommande
                            "
                            id="commandeRadio"
                            name="commandeRadio"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="
                              clientChoosedTab.counter.bondecommande
                            "
                            id="commandeRadio"
                            name="commandeRadio"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Bon de livraison</label>

                      <div
                        class="flex border border-sky-300 relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="
                              clientChoosedTab.counter.bondelivraison
                            "
                            id="livraisonRadio"
                            name="livraisonRadio"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="
                              clientChoosedTab.counter.bondelivraison
                            "
                            id="livraisonRadio"
                            name="livraisonRadio"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Bon de livraison avoir</label>

                      <div
                        class="flex border border-sky-300 relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="
                              clientChoosedTab.counter.bondelivraisonavoir
                            "
                            id="livraisonAvoirRadio"
                            name="livraisonAvoirRadio"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="
                              clientChoosedTab.counter.bondelivraisonavoir
                            "
                            id="livraisonAvoirRadio"
                            name="livraisonAvoirRadio"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Facture</label>

                      <div
                        class="flex relative border border-sky-300 mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.counter.facture"
                            id="factureRadio"
                            name="factureRadio"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.counter.facture"
                            id="factureRadio"
                            name="factureRadio"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Retour</label>

                      <div
                        class="flex border border-sky-300 relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.counter.retour"
                            id="retourRadio"
                            name="retourRadio"
                            value="true"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model.trim="clientChoosedTab.counter.retour"
                            id="retourRadio"
                            name="retourRadio"
                            value="false"
                            @change="saveChange()"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>
          </ul>
        </div>
        <!-- fin créer client par -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import menuDetailClient from "../components/Client/menuDetailClient.vue";
import { useToast } from "vue-toastification";
import {
  getTransporteurs,
  getAllTarifs,
  getTransporteurById,
} from "@/hooks/transport/transporteur";
import { getCommerciaux } from "@/hooks/commercial/commercial";
import { getAgences } from "@/hooks/groupement/groupement.ts";
import { changeAgence } from "@/hooks/clients/clients.ts";
import backButton from "@/components/Button/back.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showParametreAvance: true,
      showInformationsGenerales: true,
      showTransport: true,
      showFinance: true,
      showPersonnel: true,
      showConfigEmail: true,

      showDelete: false,
      showArchive: false,
      errors: [],

      transporteurs: [],
      schematransports: [],
      cutoffs: [],
      commerciaux: [],

      Agences: [],

      configurationConditionList: [],

      Clients: [],

      temporaryValue: false,
      isModalVisible: false,

      address_proposal: [],
      postal_code_proposal: [],
      city_proposal: [],
      address_found: false,
      postal_code_found: false,
      city_found: false,
    };
  },
  computed: {
    ...mapGetters(["user", "clientChoosedTab", "plateform"]),
  },
  components: {
    menuDetailClient,
    backButton,
  },
  methods: {
    ...mapActions(["getclientchoosedtab"]),
    saveChange() {
      this.clientChoosedTab.fraisdeportsave = this.clientChoosedTab.fraisdeport;
      this.clientChoosedTab.updated_by = this.user.username;
      this.clientChoosedTab.Name = this.clientChoosedTab.Name.toUpperCase();

      axios.put(
        `${process.env.VUE_APP_API}/client/modifyClient/${this.clientChoosedTab._id}`,
        {
          data: this.clientChoosedTab,
        }
      );
      this.getclientchoosedtab(this.clientChoosedTab);
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm(emailEnvoi) {
      this.errors = [];
      if (!emailEnvoi) {
        this.errors = [];
        this.errors.push("E-mail obligatoire.");
      } else if (!this.validEmail(emailEnvoi)) {
        this.errors = [];
        this.errors.push("E-mail incorrect.");
      } else {
        this.errors = [];
      }
    },
    addEmailClient(email, emails) {
      emails.push({
        address: email,
        devis: false,
        bondecommande: false,
        bondetransport: false,
        bondelivraison: false,
        facture: false,
      });
      console.log(emails);
      this.addemail = true;
    },
    addEmail(emails) {
      emails.push({
        address: "",
        devis: false,
        bondecommande: false,
        bondetransport: false,
        bondelivraison: false,
        facture: false,
      });
      console.log(emails);
    },
    removeEmail(index, emails) {
      emails.splice(index, 1);
      this.saveChange();
    },
    deleteClient() {
      axios
        .put(
          `${process.env.VUE_APP_API}/client/removeClient/${this.clientChoosedTab._id}`,
          this.clientChoosedTab
        )
        .then(() => {
          this.toast.success("Client supprimé !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.$router.push("/Clients");
        });
    },
    async getTransporteurs() {
      if (this.clientChoosedTab.professionnel) {
        this.transporteurs = await getTransporteurs(this.user.proprietaire);
        console.log(this.transporteurs);
      }
    },
    async getSchemasTransporteur(index, tr) {
      if (this.clientChoosedTab.professionnel) {
        const schema = await getAllTarifs(this.user.proprietaire, tr);
        this.schematransports[index] = schema;
      }
    },
    async getCutOffsTransporteur(index, tr) {
      if (this.clientChoosedTab.professionnel) {
        if (tr) {
          const transporteur = await getTransporteurById(
            this.user.proprietaire,
            tr
          );
          this.cutoffs[index] = transporteur[0]?.cutoffs;
        }
      }
    },
    async getSchemaCutOff() {
      if (this.clientChoosedTab.professionnel) {
        for (const [index, tr] in this.transporteurs) {
          await this.getSchemasTransporteur(
            index,
            this.clientChoosedTab.tabCutOff[index]?.transporteur
          );
          this.getCutOffsTransporteur(
            index,
            this.clientChoosedTab.tabCutOff[index]?.transporteur
          );
        }
      }
    },
    async getCommercial() {
      if (this.clientChoosedTab.professionnel) {
        this.commerciaux = await getCommerciaux(this.user.proprietaire);
      }
    },
    // setInfoCommercial() {
    //   this.clientChoosedTab.telcommercial = this.clientChoosedTab
    // },
    archiveClient() {
      axios
        .put(`${process.env.VUE_APP_API}/client/${this.clientChoosedTab._id}`, {
          archived: true,
        })
        .then(() => {
          this.toast.success("Client archivé !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.$router.push("/Clients");
        });
    },
    unarchiveClient() {
      axios
        .put(`${process.env.VUE_APP_API}/client/${this.clientChoosedTab._id}`, {
          archived: false,
        })
        .then(() => {
          this.toast.success("Client désarchivé !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.$router.push("/Clients");
        });
    },
    async changeAgence(agence) {
      await changeAgence(this.clientChoosedTab._id, agence);
    },
    async assignCommercial() {
      this.saveChange();
    },
    async getConfigurationCondition() {
      const condition = await axios.get(
        `${process.env.VUE_APP_API}/configurationCondition/${this.user.proprietaire}`
      );
      this.configurationConditionList = condition.data;
    },
    searchClient(client) {
      this.clientChoosedTab.parentCompanyID = null;
      if (this.clientChoosedTab.parentCompanyName != "") {
        axios
          .post(`${process.env.VUE_APP_API}/client/searchClient`, {
            plateform: this.user.proprietaire,
            searchTerm: client,
            pro: true,
            archived: false,
          })
          .then((res) => {
            this.Clients = res.data;
          });
      }
    },
    selectClient(clientChoosed) {
      if (clientChoosed?.siege) {
        this.clientChoosedTab.parentCompanyName = clientChoosed.Name;
        this.clientChoosedTab.parentCompanyID = clientChoosed._id;
        this.Clients = [];
      } else {
        this.error("Ce n'est pas un siège !");
      }
    },
    isSiege() {
      if (this.clientChoosedTab.siege == true) {
        this.clientChoosedTab.parentCompanyName = "";
        this.clientChoosedTab.parentCompanyID = null;
        this.clientChoosedTab.factureSiege = true;
        this.Clients = [];
      }
    },
    error(message) {
      this.toast.warning(message, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    confirmChange() {
      this.isModalVisible = true;
    },
    applyChange() {
      this.clientChoosedTab.professionnel = this.temporaryValue;
      this.saveChange();
      this.$router.push("./Clients");
      this.isModalVisible = false;
    },
    cancelChange() {
      this.temporaryValue = this.clientChoosedTab.professionnel;
      this.isModalVisible = false;
    },
    async autocompleteAddress() {
      if (this.clientChoosed.Adresse.length > 3 && !this.address_found) {
        let search_string = this.clientChoosed.Adresse;
        if (
          this.clientChoosed.PostalCode.length > 3 &&
          this.clientChoosed.City.length > 3
        ) {
          search_string =
            this.clientChoosed.Adresse +
            " " +
            this.clientChoosed.PostalCode +
            " " +
            this.clientChoosed.City;
        }

        let get_address = await axios.get(
          `${process.env.VUE_APP_API}/client/autocompleteaddress/${search_string}`
        );

        this.address_proposal = get_address.data.features;
      } else {
        if (this.clientChoosed.Adresse == "") {
          this.address_found = false;
        } else {
          return;
        }
      }
    },
    async autocompletePostalCode() {
      if (this.clientChoosed.PostalCode.length > 3 && !this.postal_code_found) {
        let get_address = await axios.get(
          `${process.env.VUE_APP_API}/client/autocompleteaddress/${this.clientChoosed.PostalCode}`
        );

        this.postal_code_proposal = get_address.data.features;
        //filter and keep only type == 'municipality'
        this.postal_code_proposal = this.postal_code_proposal.filter(
          (adr) => adr.properties.type == "municipality"
        );
      } else {
        if (this.clientChoosed.PostalCode == "") {
          this.postal_code_found = false;
        } else {
          return;
        }
      }
    },
    async autocompleteCity() {
      if (this.clientChoosed.City.length > 3 && !this.city_found) {
        let get_address = await axios.get(
          `${process.env.VUE_APP_API}/client/autocompleteaddress/${this.clientChoosed.City}`
        );
        this.city_proposal = get_address.data.features;
        this.city_proposal = this.city_proposal.filter(
          (adr) => adr.properties.type == "municipality"
        );
      } else {
        if (this.clientChoosed.City == "") {
          this.city_found = false;
        } else {
          return;
        }
      }
    },
    selectAddress(adr) {
      this.clientChoosed.PostalCode = adr.properties.postcode;
      this.clientChoosed.City = adr.properties.city;

      this.clientChoosed.Adresse = adr.properties.name;

      this.address_found = true;

      this.address_proposal = [];
    },
    selectPostalCode(adr) {
      this.clientChoosed.PostalCode = adr.properties.postcode;
      this.clientChoosed.City = adr.properties.city;

      this.postal_code_found = true;

      this.postal_code_proposal = [];
    },
    selectCity(adr) {
      this.clientChoosed.PostalCode = adr.properties.postcode;
      this.clientChoosed.City = adr.properties.city;

      this.city_found = true;

      this.city_proposal = [];
    },
  },
  async mounted() {
    this.getConfigurationCondition();
    this.Agences = await getAgences(this.user.proprietaire);
    await this.getTransporteurs();
    this.getCommercial();
    await this.getSchemaCutOff();
  },
};
</script>

<style></style>
