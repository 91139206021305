<template>
  <!-- modal ajout client -->
  <div
    v-if="showEditClientPar"
    :class="`modal ${
      !showEditClientPar && 'opacity-0 pointer-events-none'
    } z-50 fixed w-screen h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="$emit('close')"
      class="absolute w-screen h-screen bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 55rem; max-height: 75vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Modifier un client</p>
          <div class="z-50 cursor-pointer modal-close" @click="$emit('close')">
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div
          v-if="clientSelectedToModify && clientChoosed"
          class="bloc-couleur couleur-remplissage"
          id="ajouterparticulier"
        >
          <ul class="flex flex-col align-center space-y-20">
            <!-- INFORMATIONS GENERALES -->
            <li
              class="bg-gray-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:shadow-2xl"
            >
              <div>
                <div
                  class="bloc-couleur couleur-remplissage"
                  id="ajouterparticulier"
                >
                  <div class="flex justify-between">
                    <div class="w-full px-5 py-2 text-gray-800">
                      <label class="text-xs">Groupe de client</label>

                      <div v-if="clientChoosed.Group" class="w-full relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model="clientChoosed.Group.Name"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600 cursor-pointer"
                        >
                          <option
                            :value="cond.Name"
                            v-for="(cond, indexC) in configurationConditionList"
                            :key="indexC"
                          >
                            {{ cond.Name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="w-full px-5 py-2 text-gray-800">
                      <label class="text-xs">Numéro</label>

                      <div
                        class="w-full relative border border-sky-300 mt-2 rounded-md shadow-sm"
                      >
                        <input
                          v-model.trim="clientChoosed.NumeroClient"
                          type="text"
                          ref="NumeroClientParticulier"
                          @keydown.enter="this.$refs.nomParticulier.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="w-full px-5 py-2 text-gray-800">
                    <label class="text-xs">Nom</label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="clientChoosed.Name"
                        type="text"
                        ref="nomParticulier"
                        @keydown.enter="this.$refs.adresseParticulier.focus()"
                        class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600 cursor-pointer"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-3">
                    <div class="relative px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Code Postal</label>
                      <div class="mt-2 rounded-md shadow-sm">
                        <input
                          type="textfield"
                          v-model.trim="clientChoosed.PostalCode"
                          ref="codepostalParticulier"
                          @input="autocompletePostalCode()"
                          @keydown.enter="this.$refs.villeParticulier.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600"
                        />
                      </div>
                      <div
                        class="z-10 absolute w-96 bg-gray-50 rounded-b shadow-md max-h-28 border border-sky-600 overflow-y-auto"
                        v-if="postal_code_proposal.length > 0"
                      >
                        <div
                          v-for="(adr, indexAdr) in postal_code_proposal"
                          :key="indexAdr"
                          class="cursor-pointer hover:bg-blue-200"
                          @click="selectPostalCode(adr)"
                        >
                          {{ adr.properties.label }}
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Ville</label>
                      <div class="mt-2 rounded-md shadow-sm">
                        <input
                          type="textfield"
                          v-model.trim="clientChoosed.City"
                          ref="villeParticulier"
                          @input="autocompleteCity()"
                          @keydown.enter="this.$refs.paysParticulier.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600"
                        />
                      </div>
                      <div
                        class="z-10 absolute w-96 bg-gray-50 rounded-b shadow-md max-h-28 border border-sky-600 overflow-y-auto"
                        v-if="city_proposal.length > 0"
                      >
                        <div
                          v-for="(adr, indexAdr) in city_proposal"
                          :key="indexAdr"
                          class="cursor-pointer hover:bg-blue-200"
                          @click="selectCity(adr)"
                        >
                          {{ adr.properties.label }}
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label for="paysParticulier" class="text-xs">Pays</label>
                      <div class="mt-2 rounded-md shadow-sm">
                        <select
                          name="paysParticulier"
                          v-model.trim="clientChoosed.Country"
                          ref="paysParticulier"
                          @keydown.enter="
                            this.$refs.telephoneParticulier.focus()
                          "
                          class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md"
                        >
                          <option value="ALLEMAGNE">ALLEMAGNE</option>
                          <option value="AUTRICHE">AUTRICHE</option>
                          <option value="BELGIQUE">BELGIQUE</option>
                          <option value="ESPAGNE">ESPAGNE</option>
                          <option selected value="FRANCE">FRANCE</option>
                          <option value="ITALIE">ITALIE</option>
                          <option value="LUXEMBOURG">LUXEMBOURG</option>
                          <option value="MAROC">MAROC</option>
                          <option value="PAYS-BAS">PAYS-BAS</option>
                          <option value="SUISSE">SUISSE</option>
                          <option value="TURQUIE">TURQUIE</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700">
                    <label class="text-xs">Adresse</label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model.trim="clientChoosed.Adresse"
                        ref="adresseParticulier"
                        @keydown.enter="
                          this.$refs.complementParticulier.focus()
                        "
                        @input="autocompleteAddress()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-t-md appearance-none focus:border-blue-600"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-50">
                    <label class="text-xs">Complément d'adresse</label>

                    <div class="mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model.trim="clientChoosed.Complement"
                        ref="complementParticulier"
                        @keydown.enter="
                          this.$refs.codepostalParticulier.focus()
                        "
                        class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600"
                      />
                    </div>
                  </div>

                  <div class="grid grid-cols-2">
                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Téléphone</label>
                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="tel"
                          v-model.trim="clientChoosed.telephone"
                          ref="telephoneParticulier"
                          @keydown.enter="this.$refs.emailParticulier.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Email</label>
                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="emailParticulier"
                          v-model.trim="clientChoosed.email"
                          ref="emailParticulier"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border border-sky-300 rounded-md appearance-none focus:border-blue-600"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="flex flex-col align-center space-y-20 mt-4">
            <!-- INFORMATIONS GENERALES -->
            <li
              class="bg-gray-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="showInformationsGenerales = !showInformationsGenerales"
              >
                <a class="font-medium">Plus de paramètres</a>
                <span
                  v-show="!showInformationsGenerales"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showInformationsGenerales"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                v-if="clientChoosed.counter"
                  v-show="showInformationsGenerales"
                  class="bloc-couleur couleur-remplissage"
                  id="informationsgenerales"
                >
                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Devis </label>

                      <div
                        class="flex border border-sky-300 relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-2 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model="clientChoosed.counter.devis"
                            id="devisRadio"
                            name="devisRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model="clientChoosed.counter.devis"
                            id="devisRadio"
                            name="devisRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Bon de commande</label>

                      <div
                        class="flex border border-sky-300 relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model="clientChoosed.counter.bondecommande"
                            id="commandeRadio"
                            name="commandeRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model="clientChoosed.counter.bondecommande"
                            id="commandeRadio"
                            name="commandeRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Bon de livraison</label>

                      <div
                        class="flex border border-sky-300 relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model="clientChoosed.counter.bondelivraison"
                            id="livraisonRadio"
                            name="livraisonRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model="clientChoosed.counter.bondelivraison"
                            id="livraisonRadio"
                            name="livraisonRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Bon de livraison avoir</label>

                      <div
                        class="flex border border-sky-300 relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model="clientChoosed.counter.bondelivraisonavoir"
                            id="livraisonAvoirRadio"
                            name="livraisonAvoirRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model="clientChoosed.counter.bondelivraisonavoir"
                            id="livraisonAvoirRadio"
                            name="livraisonAvoirRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Facture</label>

                      <div
                        class="flex relative border border-sky-300 mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model="clientChoosed.counter.facture"
                            id="factureRadio"
                            name="factureRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model="clientChoosed.counter.facture"
                            id="factureRadio"
                            name="factureRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                    <div class="px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Retour</label>

                      <div
                        class="flex border border-sky-300 relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model="clientChoosed.counter.retour"
                            id="retourRadio"
                            name="retourRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 rounded-md appearance-none focus:border-blue-600"
                        >
                          <input
                            type="radio"
                            v-model="clientChoosed.counter.retour"
                            id="retourRadio"
                            name="retourRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex">
                    <div class="w-full px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Marge minimum (en %)</label>
                      <input
                        v-model.trim="clientChoosed.min_marge"
                        type="Number"
                        ref="min_marge"
                        @keydown.enter="this.$refs.min_coef.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-2 border-sky-200 rounded-md appearance-none focus:border-blue-600"
                      />
                    </div>

                    <div class="w-full px-5 py-2 text-gray-700 bg-gray-50">
                      <label class="text-xs">Coef minimum (en Decimal)</label>
                      <input
                        v-model.trim="clientChoosed.min_coef"
                        type="Float"
                        ref="min_coef"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-2 border-sky-200 rounded-md appearance-none focus:border-blue-600"
                      />
                    </div>
                  </div>
                </div>
              </transition>
            </li>
          </ul>
        </div>

        <!--Footer-->
        <div class="flex justify-end mt-8">
          <button
            @click="$emit('close')"
            class="p-3 px-5 py-2 mr-2 bg-gray-200 shadow-lg text-blue-500 rounded-lg hover:bg-red-500 hover:text-white hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            @click="saveChangeClient()"
            class="px-5 py-2 font-medium tracking-wide shadow-lg text-white bg-blue-600 rounded-md hover:bg-blue-400 focus:outline-none"
          >
            Modifier
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal ajout client -->
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showEditClientPar: true,
      showInformationsGenerales: false,
      clientChoosed: {},

      configurationConditionList: [],

      address_proposal: [],
      postal_code_proposal: [],
      city_proposal: [],
      address_found: false,
      postal_code_found: false,
      city_found: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  props: ["clientSelectedToModify"],
  methods: {
    saveChangeClient() {
      this.clientChoosed.updated_by = this.user.username;
      (this.clientChoosed.Name = this.clientChoosed.Name.toUpperCase()),
        axios
          .put(
            `${process.env.VUE_APP_API}/client/modifyClient/${this.clientChoosed._id}`,
            {
              data: this.clientChoosed,
            }
          )
          .then(() => {
            this.toast.success("Client modifié !", {
              position: "bottom-right",
              timeout: 1000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.$emit("close");
          });
    },
    async getConfigurationCondition() {
      const condition = await axios.get(
        `${process.env.VUE_APP_API}/configurationCondition/${this.user.proprietaire}`
      );
      this.configurationConditionList = condition.data;
    },
    async autocompleteAddress() {
      if (this.clientChoosed.Adresse.length > 3 && !this.address_found) {
        let search_string = this.clientChoosed.Adresse;
        if (
          this.clientChoosed.PostalCode.length > 3 &&
          this.clientChoosed.City.length > 3
        ) {
          search_string =
            this.clientChoosed.Adresse +
            " " +
            this.clientChoosed.PostalCode +
            " " +
            this.clientChoosed.City;
        }

        let get_address = await axios.get(
          `${process.env.VUE_APP_API}/client/autocompleteaddress/${search_string}`
        );

        this.address_proposal = get_address.data.features;
      } else {
        if (this.clientChoosed.Adresse == "") {
          this.address_found = false;
        } else {
          return;
        }
      }
    },
    async autocompletePostalCode() {
      if (this.clientChoosed.PostalCode.length > 3 && !this.postal_code_found) {
        let get_address = await axios.get(
          `${process.env.VUE_APP_API}/client/autocompleteaddress/${this.clientChoosed.PostalCode}`
        );

        this.postal_code_proposal = get_address.data.features;
        //filter and keep only type == 'municipality'
        this.postal_code_proposal = this.postal_code_proposal.filter(
          (adr) => adr.properties.type == "municipality"
        );
      } else {
        if (this.clientChoosed.PostalCode == "") {
          this.postal_code_found = false;
        } else {
          return;
        }
      }
    },
    async autocompleteCity() {
      if (this.clientChoosed.City.length > 3 && !this.city_found) {
        let get_address = await axios.get(
          `${process.env.VUE_APP_API}/client/autocompleteaddress/${this.clientChoosed.City}`
        );
        this.city_proposal = get_address.data.features;
        this.city_proposal = this.city_proposal.filter(
          (adr) => adr.properties.type == "municipality"
        );
      } else {
        if (this.clientChoosed.City == "") {
          this.city_found = false;
        } else {
          return;
        }
      }
    },
    selectAddress(adr) {
      this.clientChoosed.PostalCode = adr.properties.postcode;
      this.clientChoosed.City = adr.properties.city;

      this.clientChoosed.Adresse = adr.properties.name;

      this.address_found = true;

      this.address_proposal = [];
    },
    selectPostalCode(adr) {
      this.clientChoosed.PostalCode = adr.properties.postcode;
      this.clientChoosed.City = adr.properties.city;

      this.postal_code_found = true;

      this.postal_code_proposal = [];
    },
    selectCity(adr) {
      this.clientChoosed.PostalCode = adr.properties.postcode;
      this.clientChoosed.City = adr.properties.city;

      this.city_found = true;

      this.city_proposal = [];
    },
  },
  mounted() {
    this.clientChoosed = this.clientSelectedToModify;
    this.getConfigurationCondition();
  },
};
</script>
