import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getFrDate } from "@/hooks/tools/date.ts";

export function createRecapListFDM(platform, cardsData, docType) {
  var date = new Date();
  var dateFormatted = date.toLocaleDateString();
  var hourFormatted = date.toLocaleTimeString();

  var doc = [];
  let footerData = { totalHT: 0.0, totalTVA: 0.0, totalTTC: 0.0 };

  // **Entête du tableau**
  doc.push([
    { text: "Date", style: "tableHeader" },
    { text: "Type doc", style: "tableHeader" },
    { text: "Nom client", style: "tableHeader" },
    { text: "Nb BDLS", style: "tableHeader" },
    { text: "Total BL / Panier", style: "tableHeader" },
    { text: "Total BL / Avoir", style: "tableHeader" },
    { text: "Total Transport", style: "tableHeader" },
    { text: "Montant HT", style: "tableHeader" },
    { text: "Montant TVA", style: "tableHeader" },
    { text: "Montant TTC", style: "tableHeader" },
  ]);

  // **Transformation des données**
  for (const card of cardsData) {
    let clientName =
      card.client?.Name +
        "\n" +
        card.client?.Adresse +
        "\n" +
        card.client?.PostalCode +
        "\n" +
        card.client?.City +
        "\n" +
        card.client?.numerotva  || "N/A";
    let totalHT = card.totalPrice || 0;
    let totalTVA = (totalHT * 0.2).toFixed(2);
    let totalTTC = (totalHT * 1.2).toFixed(2);
    let totalBL = card.totalBL || 0;
    let totalAvoir = card.totalAvoir || 0;
    let totalShippingCosts = card.totalShippingCosts || 0;
    let nbBDLS = card.bdlCount || 0;

    doc.push([
      { text: dateFormatted, style: "subheader" },
      { text: docType, style: "subheader" },
      { text: clientName, style: "subheader" },
      { text: nbBDLS, style: "subheader" },
      { text: totalBL.toFixed(2), style: "subheader" },
      { text: totalAvoir.toFixed(2), style: "subheader" },
      { text: totalShippingCosts, style: "subheader" },
      { text: totalHT.toFixed(2), style: "subheader" },
      { text: totalTVA, style: "subheader" },
      { text: totalTTC, style: "subheader" },
    ]);

    // **Ajout au footer**
    footerData.totalHT += totalHT;
    footerData.totalTVA += Number(totalTVA);
    footerData.totalTTC += Number(totalTTC);
  }

  // **Ajout du total final**
  doc.push([
    { text: "", style: "subheader", border: [false, true, false, false] },
    { text: "", style: "subheader", border: [false, true, false, false] },
    { text: "Total", style: "subheader", bold: true },
    { text: "", style: "subheader" },
    { text: "", style: "subheader" },
    { text: "", style: "subheader" },
    { text: "", style: "subheader" },
    {
      text: footerData.totalHT.toFixed(2) + " €",
      style: "subheader",
    },
    {
      text: footerData.totalTVA.toFixed(2) + " €",
      style: "subheader",
    },
    {
      text: footerData.totalTTC.toFixed(2) + " €",
      style: "subheader",
    },
  ]);

  // **Génération du PDF**
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  var dd = {
    pageSize: "A4",
    widths: ["*"],
    content: [
      {
        columns: [
          {
            text: "Liste des " + docType + "s clients",
            alignment: "left",
            style: "header",
          },
          [
            {
              text: platform,
              alignment: "left",
              style: "header",
            },
            {
              text: "",
              alignment: "left",
              style: "header",
            },
          ],
          [
            {
              text: "Édité le " + dateFormatted,
              alignment: "right",
              style: "header",
            },
            {
              text: "Heure : " + hourFormatted,
              alignment: "right",
              style: "header",
            },
          ],
        ],
      },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          body: doc,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length ? "black" : "gray";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "black" : "gray";
          },
        },
      },
    ],
    footer: function (currentPage, pageCount) {
      return [
        {
          columns: [
            {
              text: `Page ${currentPage} / ${pageCount}`,
              style: "documentFooterCenter1",
            },
          ],
        },
      ];
    },
    styles: {
      header: {
        fontSize: 9,
        color: "gray",
      },
      subheader: {
        fontSize: 7,
      },
      tableExample: {
        margin: [0, 5, 0, 5],
      },
      tableHeader: {
        fontSize: 7,
        color: "gray",
      },
      footer: {
        fontSize: 10,
        alignment: "right",
      },
      documentFooterCenter1: {
        fontSize: 9,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
    },
    defaultStyle: {
      color: "black",
    },
  };
  pdfMake.createPdf(dd).open();
}

export function createDocListByClient(platform, contentData, docType) {
  var date = new Date();
  var dateFormatted = date.toLocaleDateString();
  var hourFormatted = date.toLocaleTimeString();

  var doc = [];
  let footerData = { totalHT: 0.0, totalTVA: 0.0, totalTTC: 0.0 };

  doc.push([
    { text: "Date", style: "tableHeader" },
    { text: "Type doc", style: "tableHeader" },
    { text: "N° doc", style: "tableHeader" },
    { text: "Client", style: "tableHeader" },
    { text: "Nom client", style: "tableHeader" },
    { text: "N° TVA", style: "tableHeader" },
    { text: "Moyen paiement", style: "tableHeader" },
    { text: "Montant HT", style: "tableHeader" },
    { text: "Montant TVA", style: "tableHeader" },
    { text: "Montant TTC", style: "tableHeader" },
  ]);
  for (const line of contentData) {
    let totalHT = line.TotalPrice.toFixed(2);
    let totalTVA = (totalHT * 0.2).toFixed(2);
    let totalTTC = line.TotalPriceTTC.toFixed(2);

    doc.push([
      { text: getFrDate(line.Date), style: "subheader" },
      { text: docType, style: "subheader" },
      { text: line.Numero, style: "subheader" },
      { text: line.clientInfo[0]?.NumeroClient, style: "subheader" },
      { text: line.Client, style: "subheader" },
      { text: line.clientInfo[0]?.numerotva, style: "subheader" },
      { text: line.clientInfo[0]?.moyendepaiement, style: "subheader" },
      {
        text: totalHT,
        style: "subheader",
      },
      {
        text: totalTVA,
        style: "subheader",
      },
      {
        text: totalTTC,
        style: "subheader",
      },
    ]);

    footerData.totalHT += Number(totalHT);
    footerData.totalTVA += Number(totalTVA);
    footerData.totalTTC += Number(totalTTC);
  }

  doc.push([
    { text: "", style: "subheader", border: [false, true, false, false] },
    { text: "", style: "subheader", border: [false, true, false, false] },
    { text: "", style: "subheader", border: [false, true, false, false] },
    { text: "", style: "subheader", border: [false, true, false, false] },
    { text: "", style: "subheader", border: [false, true, false, false] },
    { text: "", style: "subheader", border: [false, true, false, false] },
    { text: "", style: "subheader", border: [false, true, false, false] },
    {
      text: "Total HT : " + footerData.totalHT.toFixed(2) + " €",
      style: "subheader",
    },
    {
      text: "Total TVA : " + footerData.totalTVA.toFixed(2) + " €",
      style: "subheader",
    },
    {
      text: "Total TTC : " + footerData.totalTTC.toFixed(2) + " €",
      style: "subheader",
    },
  ]);

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  var dd = {
    pageSize: "A4",

    // by default we use portrait, you can change it to landscape if you wish
    // pageOrientation: "landscape",
    widths: ["*"],
    content: [
      {
        columns: [
          {
            text: "Liste des " + docType + "s clients",
            alignment: "left",
            style: "header",
          },
          [
            {
              text: platform,
              alignment: "left",
              style: "header",
            },
            {
              text: "",
              alignment: "left",
              style: "header",
            },
          ],
          [
            {
              text: "Édité le " + dateFormatted,
              alignment: "right",
              style: "header",
            },
            {
              text: "Heure : " + hourFormatted,
              alignment: "right",
              style: "header",
            },
          ],
        ],
      },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          body: doc,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length ? "black" : "gray";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "black" : "gray";
          },
        },
      },
      //   {
      //     text: "TEST FOOTER",
      //     style: "footer",
      //   },
    ],
    footer: function (currentPage, pageCount) {
      return [
        {
          columns: [
            {
              text: `Page ${currentPage} / ${pageCount}`,
              style: "documentFooterCenter1",
            },
          ],
        },
      ];
    },
    styles: {
      header: {
        fontSize: 9,
        color: "gray",
      },
      subheader: {
        fontSize: 7,
      },
      tableExample: {
        margin: [0, 5, 0, 5],
      },
      tableHeader: {
        fontSize: 7,
        color: "gray",
      },
      footer: {
        fontSize: 10,
        alignment: "right",
      },
      documentFooterCenter1: {
        fontSize: 9,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
    },
    defaultStyle: {
      color: "black",
    },
  };
  pdfMake.createPdf(dd).open();
}
