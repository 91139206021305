<template>
  <div>
    <div class="flex justify-between">
      <searchInput
        placeholder="Rechercher dans transporteurs"
        @searchWord="search(1, 10, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      />
      <titrePages :title="'Transporteurs'" />

      <div class="flex justify-end my-2">
        <router-link to="/creerTransporteur">
          <button
            class="flex px-6 py-2 font-medium tracking-wide text-white bg-bluevk rounded-md hover:bg-blue-500 focus:outline-none"
          >
            <span class="material-icons-round">add_circle_outline</span>
            <h5>Créer</h5>
          </button>
        </router-link>
      </div>
    </div>

    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <div class="flex">
                    <span>Numéro</span>
                    <span
                      class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                      >filter_alt</span
                    >
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <div class="flex">
                    <span>Nom du client</span>
                    <span
                      class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                      >filter_alt</span
                    >
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <div class="flex">
                    <span>Adresse</span>
                    <span
                      class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                      >filter_alt</span
                    >
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <div class="flex">
                    <span>Code postal</span>
                    <span
                      class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                      >filter_alt</span
                    >
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <div class="flex">
                    <span>Ville</span>
                    <span
                      class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                      >filter_alt</span
                    >
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Modifier
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(u, index) in transporteurs" :key="index">
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <!-- <div class="flex-shrink-0 w-10 h-10">
                      <img
                        class="w-10 h-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt
                      />
                    </div>-->

                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ u.numero }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  id="tabName"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.transporteur }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.adresse }}
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.codePostal }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.ville }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <a
                    href="./detailTransporteur"
                    @click="getTransporteurId(u._id)"
                  >
                    <button>
                      <span
                        class="material-icons-outlined m-2 p-0.5 w-8 h-8 shadow hover:bg-green-500 hover:bg-opacity-25 border border-green-500 border-opacity-25 rounded-full text-green-500"
                      >
                        drive_file_rename_outline
                      </span>
                    </button>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <pageNumber
            v-if="!searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="transporteursLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(transporteursLength / 10)"
            :clickFunction="getTransporteurs"
            :currentPage="currentPage"
            :typeList="'transporteurs'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
          <pageNumber
            v-else
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
            :currentPage="currentPage"
            :typeList="'transporteurs'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  name: "MyTransporteur",
  data() {
    return {
      transporteurs: [],

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      transporteursLength: 0,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,
    };
  },
  components: {
    pageNumber,
    searchInput,
    titrePages,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["getTransporteurId"]),
    getTransporteurs(skipValue, limitValue) {
      this.searchActive = false;
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/transporteur/getByOwner`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          this.transporteurs = response.data.results;
          this.transporteursLength = response.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      if (searchTerm == "") {
        this.searchActive = false;
        this.getTransporteurs(skipValue, limitValue);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/transporteur/searchInPlateform`, {
            plateform: this.user.proprietaire,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.transporteurs = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
          });
      }
    },
  },
  mounted() {
    this.getTransporteurs(1, 10);
    this.pageSwitch();
  },
};
</script>
