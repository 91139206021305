<template>
  <div
    v-if="openMenu"
    :class="`modal ${
      !openMenu && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex`"
  >
    <div
      @click="(openMenu = false), $emit('closeMenu')"
      class="absolute top-0 w-full bg-gray-900 h-full bg-opacity-60 modal-overlay"
    ></div>

    <div
      style="margin-top: 13px; margin-left: 6.5rem"
      class="absolute flex space-x-4 z-50 overflow-y-auto modal-container text-white font-medium"
    >
      <!-- Body -->
      <div class="flex flex-col space-y-2">
        <div
          @click="(openMenu = false), $emit('closeMenu')"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 mb-4 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">close</span>
        </div>
        <div
          @click="getComptoir()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">calculate</span>
        </div>
        <div
          @click="getBdls()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">description</span>
        </div>
        <div
          @click="getFacture()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">payments</span>
        </div>
        <div
          @click="getClientFav()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">hotel_class</span>
        </div>
        <div
          @click="getCmdFournisseurs()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">archive</span>
        </div>
        <div
          @click="getComptoirSession()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">grid_view</span>
        </div>
      </div>

      <transition
        enter-active-class="transition duration-250 ease-out transform"
        enter-from-class="scale-95 opacity-0"
        enter-to-class="scale-100 opacity-100"
        leave-active-class="transition duration-250 ease-in transform"
        leave-from-class="scale-100 opacity-100"
        leave-to-class="scale-95 opacity-0"
      >
        <div class="flex flex-col space-y-4 text-xs mt-4">
          <div
            class="py-2 h-fit mb-4"
            @click="(openMenu = false), $emit('closeMenu')"
          ></div>
          <div
            @click="getComptoir()"
            class="py-2 h-fit cursor-pointer text-left"
          >
            Comptoir
          </div>
          <div @click="getBdls()" class="py-2 h-fit cursor-pointer text-left">
            Bdl
          </div>
          <div
            @click="getFacture()"
            class="py-2 h-fit cursor-pointer text-left"
          >
            Facture
          </div>
          <div
            @click="getClientFav()"
            class="py-2 h-fit cursor-pointer text-left"
          >
            Clients Favoris
          </div>
          <div
            @click="getCmdFournisseurs()"
            class="py-2 h-fit cursor-pointer text-left"
          >
            Commander fournisseur
          </div>
          <div
            @click="getComptoirSession()"
            class="py-2 h-fit cursor-pointer text-left"
          >
            Session
          </div>
        </div>
      </transition>
    </div>
  </div>
  <!-- Fin menu -->
</template>

<script>
export default {
  name: "MySidenav",
  data() {
    return {
      openMenu: true,
    };
  },
  methods: {
    getComptoir() {
      this.$router.push("./actionclient"),
        (this.openMenu = false),
        this.$emit("closeMenu");
    },
    getBdls() {
      this.$router.push("./bdl"),
        (this.openMenu = false),
        this.$emit("closeMenu");
    },
    getClientFav() {
      this.$router.push("./favoriClient"),
        (this.openMenu = false),
        this.$emit("closeMenu");
    },
    getCmdFournisseurs() {
      this.$router.push("./commandeInstFournisseur"),
        (this.openMenu = false),
        this.$emit("closeMenu");
    },
    getComptoirSession() {
      this.$router.push("./comptoirSession"),
        (this.openMenu = false),
        this.$emit("closeMenu");
    },
    getFacture() {
      this.$router.push("./facture"),
        (this.openMenu = false),
        this.$emit("closeMenu");
    },
  },
};
</script>

<style></style>
