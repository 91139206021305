<template>
  <div class="flex" style="font-size: 15px">
    <div
      id="separator-sidebar"
      :class="[
        'top-0 left-0 z-30 h-screen border-r border-gray-200 shadow-md transition-transform sm:translate-x-0 overflow-y-auto scrollbar-hide left-0',
        { 'w-60': isOpen, 'w-[5rem]': !isOpen },
      ]"
      aria-label="Sidebar"
      :style="
        plateform?.style
          ? 'background-color: #' + plateform.style.primary
          : 'background-color: #062b79'
      "
      @mouseenter="isOpen = true"
      @mouseleave="isOpen = false"
    >
      <router-link to="/dashboard">
        <div class="flex justify-center mt-6">
          <div class="flex flex-col">
            <div class="flex items-center m-auto">
              <div class="w-8 h-8 rounded">
                <img :src="plateform?.lien_logo" />
              </div>
            </div>
            <div v-if="plateform?.Titre1 == 'AUTO COMMERCE PIECES'">
              {{ plateform?.Ville }}
            </div>
          </div>
        </div>
      </router-link>

      <nav class="text-white">
        <p class="mt-8 pl-4 my-2 text-xs font-semibold mb-4 text-yellow-500">
          Général
        </p>

        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'Dashboard' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/dashboard"
        >
          <span class="material-icons-round" style="color: white">home</span>
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Accueil</span>
          </transition>
        </router-link>

        <p class="mt-6 pl-4 my-2 text-xs font-semibold mb-4 text-yellow-500">
          Affaires
        </p>

        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'ControlePreliminaireHub'
              ? activeClass
              : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/ControlePreliminaireHub"
        >
          <span class="material-icons-round" style="color: white"
            >directions_car</span
          >
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Atelier</span>
          </transition>
        </router-link>

        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'actionClient' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/actionclient"
        >
          <span class="material-icons-round" style="color: white"
            >shopping_basket</span
          >
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Comptoir</span>
          </transition>
        </router-link>

        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'commandePro' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/commandePro"
        >
          <span class="material-icons-round" style="color: white"
            >unarchive</span
          >
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span
              v-if="isOpen"
              class="mx-4 text-white"
              style="white-space: nowrap"
              >Commandes Pro</span
            >
          </transition>
        </router-link>
        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'Retour' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/Retour"
        >
          <span class="material-icons-round" style="color: white">archive</span>
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Retours </span>
          </transition>
        </router-link>

        <div
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white cursor-pointer"
          :class="[inactiveClass, { 'border-l-4 border-orange-500': isOpen }]"
          @click="dropdownClient = !dropdownClient"
        >
          <span class="material-icons-round" style="color: white">face</span>
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Clients</span>
          </transition>
        </div>
        <transition
          enter-active-class="transition duration-150 ease-out transform"
          enter-from-class="scale-95 opacity-0"
          enter-to-class="scale-100 opacity-100"
          leave-active-class="transition duration-150 ease-in transform"
          leave-from-class="scale-100 opacity-100"
          leave-to-class="scale-95 opacity-0"
        >
          <ul v-show="dropdownClient && isOpen" class="under_LI">
            <router-link
              class="flex items-center duration-200 border-l-4 border-orange-500 hover:border-white"
              :class="[$route.name === 'Clients' ? activeClass : inactiveClass]"
              to="/clients"
            >
              <li class="py-2" style="color: white">
                <span class="mx-4 text-white">Liste</span>
              </li>
            </router-link>
            <!-- <router-link
              class="flex items-center duration-200 border-l-4 border-orange-500 hover:border-white"
              :class="[
                $route.name === 'Regroupement' ? activeClass : inactiveClass,
              ]"
              to="/regroupement"
            >
              <li class="py-2" style="color: white">
                <span class="mx-4 text-white">Regroupement</span>
              </li>
            </router-link> -->
            <router-link
              class="flex items-center duration-200 border-l-4 border-orange-500 hover:border-white"
              :class="[$route.name === 'Devis' ? activeClass : inactiveClass]"
              to="/devis"
            >
              <li class="py-2" style="color: white">
                <span class="mx-4 text-white">Devis</span>
              </li>
            </router-link>
            <router-link
              class="flex items-center duration-200 border-l-4 border-orange-500 hover:border-white"
              :class="[
                $route.name === 'Commandes' ? activeClass : inactiveClass,
              ]"
              to="/commandes"
            >
              <li class="py-2" style="color: white">
                <span class="mx-4 text-white">Bons de commande</span>
              </li>
            </router-link>
            <router-link
              class="flex items-center duration-200 border-l-4 border-orange-500 hover:border-white"
              :class="[$route.name === 'Bdl' ? activeClass : inactiveClass]"
              to="/bdl"
            >
              <li class="py-2" style="color: white">
                <span class="mx-4 text-white">Bons de livraison</span>
              </li>
            </router-link>
            <!-- <router-link
              class="flex items-center duration-200 border-l-4 border-orange-500 hover:border-white"
              :class="[
                $route.name === 'groupedCmdPro' ? activeClass : inactiveClass,
              ]"
              to="/groupedCmdPro"
            >
              <li class="py-2" style="color: white">
                <span class="mx-4 text-white">Bons de transport clôturés</span>
              </li>
            </router-link> -->
            <router-link
              class="flex items-center duration-200 border-l-4 border-orange-500 hover:border-white"
              :class="[
                $route.name === 'Consigne' ? activeClass : inactiveClass,
              ]"
              to="/consigne"
              v-show="false"
            >
              <li class="py-2" style="color: white">
                <span class="mx-4 text-white">Consigne</span>
              </li>
            </router-link>
            <router-link
              class="flex items-center duration-200 border-l-4 border-orange-500 hover:border-white"
              :class="[$route.name === 'Retour' ? activeClass : inactiveClass]"
              to="/retour"
              v-show="false"
            >
              <li class="py-2" style="color: white">
                <span class="mx-4 text-white">Retour</span>
              </li>
            </router-link>
            <router-link
              class="flex items-center duration-200 border-l-4 border-orange-500 hover:border-white"
              :class="[$route.name === 'Avoir' ? activeClass : inactiveClass]"
              to="/avoir"
              v-show="false"
            >
              <li class="py-2" style="color: white">
                <span class="mx-4 text-white">Avoir</span>
              </li>
            </router-link>
            <router-link
              class="flex items-center duration-200 border-l-4 border-orange-500 hover:border-white"
              :class="[$route.name === 'Facture' ? activeClass : inactiveClass]"
              to="/facture"
            >
              <li class="py-2" style="color: white">
                <span class="mx-4 text-white">Factures</span>
              </li>
            </router-link>
            <router-link
              class="flex items-center duration-200 border-l-4 border-orange-500 hover:border-white"
              :class="[
                $route.name === 'Facturation' ? activeClass : inactiveClass,
              ]"
              to="/Facturation"
            >
              <li class="py-2" style="color: white">
                <span class="mx-4 text-white">Facturation FDM</span>
              </li>
            </router-link>
          </ul>
        </transition>

        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'ArticleHub' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/articleHub"
        >
          <span class="material-icons-round" style="color: white"
            >inventory_2</span
          >
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Articles</span>
          </transition>
        </router-link>

        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'Fournisseurs' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/fournisseurs"
          v-if="$store.state.user.accountType == 'Administrateur'"
        >
          <span class="material-icons-round" style="color: white">factory</span>
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Fournisseurs</span>
          </transition>
        </router-link>

        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'Transporteur' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/Transporteur"
          v-if="$store.state.user.accountType == 'Administrateur'"
        >
          <span class="material-icons-round" style="color: white"
            >local_shipping</span
          >
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Transporteurs</span>
          </transition>
        </router-link>

        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'Statistiques' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/statistics"
        >
          <span class="material-icons-round" style="color: white"
            >leaderboard</span
          >
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Statistiques</span>
          </transition>
        </router-link>

        <router-link
          class="2xl:hidden flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'Catalogue pneus' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/cataloguedepneus"
        >
          <img src="../assets/tire_icon.png" class="w-7 h-7" />
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Catalogue pneus</span>
          </transition>
        </router-link>

        <router-link
          class="2xl:hidden flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'Promotions' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/PromoMosaique"
        >
          <span class="material-icons-round" style="color: white">percent</span>
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Promotions</span>
          </transition>
        </router-link>

        <router-link
          v-if="$store.state.user.accountType == 'Utilisateur'"
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'Caisse' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/caisse"
        >
          <span class="material-icons-round" style="color: white"
            >point_of_sale</span
          >
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Caisse</span>
          </transition>
        </router-link>

        <p
          class="mt-6 pl-4 my-2 text-xs font-semibold mb-4 text-yellow-500"
          v-if="$store.state.user.accountType == 'Administrateur'"
        >
          Société
        </p>
        <router-link
          v-if="$store.state.user.accountType == 'Administrateur'"
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'Caisse' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/caisse"
        >
          <span class="material-icons-round" style="color: white"
            >point_of_sale</span
          >
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Comptabilité</span>
          </transition>
        </router-link>
        <router-link
          v-if="$store.state.user.accountType == 'Administrateur'"
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'Organisations' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/Organisations"
        >
          <span class="material-icons-outlined" style="color: white"
            >business</span
          >
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Organisation</span>
          </transition>
        </router-link>
        <router-link
          v-if="$store.state.user.accountType == 'Administrateur'"
          class="flex items-center px-6 py-2 mt-4 duration-200 hover:border-white"
          :class="[
            $route.name === 'ConfigurationHub' ? activeClass : inactiveClass,
            { 'border-l-4 border-orange-500': isOpen },
          ]"
          to="/configurationCatalogueHub"
        >
          <span class="material-icons-round" style="color: white">tune</span>
          <transition
            enter-active-class="transition-opacity duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="isOpen" class="mx-4 text-white">Configuration</span>
          </transition>
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MySidebar",
  data() {
    return {
      isOpen: false,
      activeClass: "bg-gray-600 bg-opacity-25 text-gray-100 border-gray-100",
      inactiveClass: "text-gray-500 hover:bg-gray-600 hover:bg-opacity-25",
      dropdownArticle: false,
      dropdownFournisseurs: false,
      dropdownEnlevement: false,
      dropdownClient: false,
      dropdownPrecommande: false,
      dropdownConfiguration: false,
      vehicule_set: false,
      dropdownTeam: false,
    };
  },
  computed: {
    ...mapGetters(["vehicule", "vehiculeSet", "user", "plateform"]),
  },
  methods: {
    ...mapActions(["clearvehicule"]),
  },
};
</script>
<style scoped>
.under_LI {
  margin-left: 22px;
}

#separator-sidebar {
  transition: width 0.2s ease;
}
</style>
